import React, { useState, useCallback, memo, useEffect, useMemo } from "react"
import { css } from "@emotion/core"
import { bpMaxSM } from "../utils/breakpoints"

import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import SyncIcon from "@material-ui/icons/Sync"

import TodoCard from "./todo/todo-card"
import { CatCards } from "./todo/cat-card"
import MainContent from "../components/Main"
import { formData2json } from "src/utils/utils"
import Empty from "src/components/empty"
import Loading from "src/components/loading"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { todoModel } from "src/models"

import PullToRefresh from '../utils/PullToRefresh'

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    margin: "10px 0",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const TodoPart = () => {
  const message = useMessage()
  const classes = useStyles()

  let [catLoading, listLoading, todoCats, todoList] = useNovus(
    models => {
      return [
        models.todoModel.state.catLoading,
        models.todoModel.state.listLoading,
        models.todoModel.state.todoCats,
        models.todoModel.state.todoList,
      ]
    },
    ["todoModel"]
  )

  useEffect(() => {
    todoModel.actions.refresh()
  }, [])

  const [currentNewCat, setCurrentNewCat] = useState("")

  useEffect(() => {
    if (todoCats.length > 0 && currentNewCat === "")
      setCurrentNewCat(todoCats[0].objectId)
  }, [todoCats])

  const checkNew = useCallback(async event => {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const json = formData2json(data)
    json.status = 0
    json.category = {
      __type: "Pointer",
      className: "todo_cat",
      objectId: json.category,
    }
    await todoModel.actions.createTodo(json)
    form.reset()
    message.success("添加成功")
  }, [])

  const [checkAll, setCheckAll] = React.useState(true)
  const [checkeds, setChecked] = React.useState([])

  const handleToggle = useCallback(
    (value: string, checked: boolean) => {
      if (checkAll && checkeds.length == todoCats.length) {
        setCheckAll(false)
      }

      const currentIndex = checkeds.indexOf(value)
      const newChecked = [...checkeds]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      if (!checkAll && newChecked.length == todoCats.length) {
        setCheckAll(true)
      }

      setChecked(newChecked)
    },
    [todoCats, checkAll, checkeds]
  )

  const toggleAll = useCallback(() => {
    if (checkAll) {
      setChecked([])
      setCheckAll(false)
    } else {
      setChecked(todoCats.map(c => c.objectId))
      setCheckAll(true)
    }
  }, [checkAll, todoCats])

  useEffect(() => {
    if (todoCats.length !== 0 && checkAll && checkeds.length == 0) {
      setChecked(todoCats.map(c => c.objectId))
    }
  }, [todoCats, checkAll, checkeds])

  const todos = useMemo(() => {
    return checkAll
      ? todoList
      : todoList.filter(todo => checkeds.indexOf(todo.category.objectId) > -1)
  }, [checkAll, todoList, checkeds])

  const inputBox = useMemo(() => {
    return (
      <Paper
        elevation={2}
        component="form"
        className={classes.root}
        onSubmit={checkNew}
        // @ts-ignore
        autoComplete="off"
        css={css`
          padding: 2px 0px;
          margin: 10px;
          display: flex;
          alignitems: center;
        `}
      >
        <div className={classes.iconButton}>
          {/* <PlaylistAddIcon /> */}
          <Select
            value={currentNewCat}
            displayEmpty
            name="category"
            onChange={e => {
              // @ts-ignore
              setCurrentNewCat(e.target.value)
            }}
            disableUnderline
          >
            {todoCats.map(cat => (
              <MenuItem value={cat.objectId} key={cat.objectId}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Divider className={classes.divider} orientation="vertical" />
        <InputBase
          autoFocus
          name="name"
          className={classes.input}
          placeholder={
            todoCats.length == 0 || currentNewCat === ""
              ? `添加新任务`
              : `添加新任务到【${todoCats.find(c => c.objectId === currentNewCat).name
              }】？`
          }
          inputProps={{}}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type="submit"
          color="primary"
          className={classes.iconButton}
        >
          <SubdirectoryArrowLeftIcon />
        </IconButton>
      </Paper>
    )
  }, [checkNew, currentNewCat, todoCats])

  return (
    <MainContent
      title="最近待办"
      titleRights={[
        <IconButton key="progress" disabled={true}>
          <CircularProgress
            variant={catLoading || listLoading ? "indeterminate" : "static"}
            value={
              (todos.filter(todo => todo.status === 1).length * 100) /
              todos.length
            }
            size={20}
            thickness={20}
          />
        </IconButton>,
        <IconButton
          key="refresh"
          onClick={() => {
            todoModel.actions.refresh()
          }}
          disabled={catLoading || listLoading}
        >
          <SyncIcon />
        </IconButton>,
      ]}
      sideLoading={catLoading}
      mainLoading={false}
      sideWidth={180}
      side={
        <CatCards
          cats={todoCats}
          checkeds={checkeds}
          checkAll={checkAll}
          handleToggle={handleToggle}
          toggleAll={toggleAll}
        />
      }
      onRefresh={todoModel.actions.refresh}
    >
      {listLoading ?
        <Loading size={30} title="加载中..." /> : 
        <>
          {inputBox}

          {todos.length === 0 && todoList.length !== 0 && (
            <Empty title="当前分类下没有内容~" />
          )}
          <div
            css={css`
            margin: 0 10px 10px;
            display: flex;
            flex: 1;
            ${bpMaxSM} {
              flex-direction: column;
            }
          `}
          >
            <TodoCard checkeds={checkeds} />
          </div>
        </>
      }
    </MainContent>
  )
}

export default memo(TodoPart)
