import React, { useRef, useState, useCallback, useEffect } from "react"
import { css } from "@emotion/core"

import IconButton from "@material-ui/core/IconButton"
import SyncIcon from "@material-ui/icons/Sync"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

import useMessage from "src/utils/message"
import Empty from "src/components/empty"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import { useNovus } from "src/lib/novus"
import { noteModel } from "src/models"
import { ConfirmInput } from "src/components/ConfirmInput"
import Loading from "src/components/loading"

export const NotesList = React.memo(() => {
  const message = useMessage()

  let [notes, currentNoteId] = useNovus(
    models => {
      return [
        models.noteModel.state.notes,
        models.noteModel.state.currentNoteId,
      ]
    },
    ["noteModel"]
  )

  const [newVisible, setNewShow] = useState(false)
  const handleNew = useCallback(async (newVal: string) => {
    const disposer = message.success("创建中...")
    const newNote = await noteModel.actions.createNote(newVal)
    disposer()
    message.success("创建成功")
    noteModel.actions.updateCurrentNodeId(newNote.objectId)
  }, [])

  const notesRender = notes.filter(n => !n.deleted)

  return (
    <>
      <List
        subheader={
          <ListSubheader
            disableSticky
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div>笔记列表</div>
            <div>
              <IconButton
                key="create"
                onClick={() => {
                  setNewShow(true)
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              <IconButton key="refresh" onClick={noteModel.actions.refresh}>
                <SyncIcon />
              </IconButton>
            </div>
          </ListSubheader>
        }
      >
        {notesRender.length === 0 ? (
          <Empty title="没有笔记，右上角创建~" />
        ) : (
          notesRender.map(note => (
            <ListItem
              key={note.objectId}
              button
              selected={currentNoteId === note.objectId}
              onClick={event => {
                if (currentNoteId !== note.objectId) {
                  noteModel.actions.updateCurrentNodeId(note.objectId)
                }
              }}
            >
              <ListItemText primary={note.title} />
            </ListItem>
          ))
        )}
      </List>
      <ConfirmInput
        visible={newVisible}
        setVisible={setNewShow}
        onConfirm={handleNew}
        title="输入笔记标题"
        defaultValue="一个深思熟虑的标题"
        withDelete={null}
      />
    </>
  )
})
