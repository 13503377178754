import { NovusBaseModel } from "../lib/novus"
import AV from "leancloud-storage"
import { RecentReadingType } from "src/lib/LCTypes"
import {
  updateAVinList,
  deleteAVinList,
  createAVinList,
} from "src/lib/av-models"

export interface IReadingState {
  loading: boolean;
  page: number;
  pageSize: number;
  totalCount: number;
  readings: RecentReadingType[]
}

// 使用继承类的形式限制属性和方法
export class ReadingModel extends NovusBaseModel<IReadingState> {
  namespace = "readingModel"
  constructor() {
    super()
    this.state = {
      loading: false,
      page: 1,
      pageSize: 20,
      totalCount: 1,
      readings: [],
    }
  }
  actions = {
    refresh: async () => {
      this.setState({ page: 1 })
      await this.actions.fetchReadings()
    },
    fetchReadings: async () => {
      this.setState({ loading: true })
      const query = new AV.Query<AV.Object>("recent_reading")
      query.descending("createdAt")

      const cu = AV.User.current();

      // 由于改成了多用户规则，读取阅读记录的时候需要排除全部可读的我的数据
      if (cu && cu.id !== "5f01fd98c4d9660006adc8fc") {
        query.notEqualTo("ACL.*.read", true)
      }

      query.skip((this.state.page - 1) * this.state.pageSize)
      query.limit(this.state.pageSize)

      const result = await query.find()
      const count = await query.count()

      this.setState({
        readings: [...result.map(r => r.toJSON())],
        loading: false,
        totalCount: count,
      })
    },
    setPage: async (page: number) => {
      this.setState({ page })
      this.actions.fetchReadings()
    },
    deleteReading: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList(
        "recent_reading",
        plain,
        this.state.readings
      )
      this.setState({
        readings: newList,
        totalCount: this.state.totalCount - 1,
      })
    },
    updateReading: async (
      plain: RecentReadingType,
      attrs: Partial<RecentReadingType>
    ) => {
      const newList = await updateAVinList(
        "recent_reading",
        plain,
        attrs,
        this.state.readings
      )
      this.setState({ readings: newList })
      return this.state.readings.find(v => v.objectId === plain.objectId)
    },
    createReading: async (
      attrs: Partial<RecentReadingType>
    ): Promise<RecentReadingType> => {
      const newList = await createAVinList(
        "recent_reading",
        attrs,
        this.state.readings
      )
      this.setState({
        readings: newList,
        totalCount: this.state.totalCount + 1,
      })
      return this.state.readings[0]
    },
  }
}
