import React, { useRef, useState, useCallback } from "react"
import { css } from "@emotion/core"
import List from "@material-ui/core/List"
import { BookMarkListType } from "src/lib/LCTypes"

import TodoItem from "./bookmark-item"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { bookMarkModel } from "src/models"

type BookMarkCardProps = {
  editBookMark: (bookmark: BookMarkListType) => void
}

export default React.memo(({ editBookMark }: BookMarkCardProps) => {
  const message = useMessage()

  const [list] = useNovus(
    models => {
      return [models.bookMarkModel.state.list]
    },
    ["bookMarkModel"]
  )

  const currentDelRef = useRef<BookMarkListType>()
  const [deleteVisible, setDeleteShow] = useState(false)
  const showDeleteDialog = useCallback(entry => {
    currentDelRef.current = entry
    setDeleteShow(true)
  }, [currentDelRef])
  const handleDel = useCallback(async () => {
    await bookMarkModel.actions.deleteBookMark(currentDelRef.current)
    currentDelRef.current = null
    message.success("删除成功")
  }, [currentDelRef])

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentDelRef.current ? currentDelRef.current.title : ""}
        onConfirm={handleDel}
      />

      <List
        css={css`
          padding: 0;
          li {
            margin: 0;
          }
          .MuiListItemIcon-root {
            min-width: 28px;
          }
        `}
      >
        {list.map(bookmark => {
          return (
            <TodoItem
              key={bookmark.objectId}
              bookmark={bookmark}
              editBookMark={editBookMark}
              showDeleteDialog={showDeleteDialog}
            />
          )
        })}
      </List>
    </div>
  )
})
