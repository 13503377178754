import { NovusBaseModel, novus } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"

export type AuthPanelMode = "LOGIN" | "SIGN_UP" | "TRY" | "RESET_PASS"

export interface IAuthState {
  mode: AuthPanelMode
  checkAuthing: boolean
  authenticated: boolean
  currentUser: AV.User
  email: string
}

// 使用继承类的形式限制属性和方法
export class AuthModel extends NovusBaseModel<IAuthState> {
  namespace = "authModel"
  constructor() {
    super()
    this.state = {
      mode: "LOGIN",
      checkAuthing: true,
      authenticated: false,
      currentUser: null,
      email: "",
    }
  }
  message: MessageType
  // actions 集合，可以调用异步函数，约定为修改状态的逻辑，提供数据操作方法
  actions = {
    init: async (message: MessageType) => {
      this.message = message
      const currentUser = AV.User.current()
      this.setState({
        checkAuthing: false,
        authenticated: !!currentUser,
        currentUser: currentUser,
        email: localStorage.getItem("_uflow_email_") || "",
      })
    },
    setMode: (mode: AuthPanelMode) => {
      this.setState({ mode })
      window.location.reload()
    },
    tryout: () => {
      this.setState({ mode: "TRY" })
      setTimeout(() => {
        this.actions.login("test@test.com", "123456")
      }, 1200)
    },
    update: async () => {
      const currentUser = AV.User.current()
      this.setState({
        currentUser: currentUser,
      })
    },
    setEmail: (e: string) => {
      this.setState({ email: e })
    },
    login: async (email: string, password: string) => {
      this.setState({
        checkAuthing: true,
      })
      localStorage.setItem("_uflow_email_", email)
      await AV.User.loginWithEmail(email, password).then(
        user => {
          this.setState({
            checkAuthing: false,
            authenticated: true,
            currentUser: user,
          })
          // 登录成功
          console.log(`登录成功。objectId：${user.id}`)
          this.message.success("登录成功")
          window.location.reload()
        },
        error => {
          this.setState({
            checkAuthing: false,
          })
          // 登录失败（通常是因为密码错误）
          console.error(error)
          // for (const key in error) {
          //   const element = error[key]
          //   console.log(key, element)
          // }
          this.message.error(error.rawMessage)
        }
      )
    },
    quit: async () => {
      await AV.User.logOut()
      this.setState({
        checkAuthing: false,
        authenticated: false,
        currentUser: null,
      })
    },
  }
}
