import React, { memo, useState, useCallback } from "react"
import { css } from "@emotion/core"

import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import { formData2json } from "src/utils/utils"
import useMessage from "src/utils/message"
import AV from "leancloud-storage"
import { novus } from "src/lib/novus"
import Alert from '@material-ui/lab/Alert';

export const ResetPassPanel = memo(() => {
  const [checkAuthing, setCheckAuthing] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false)
  const message = useMessage()

  const [emailError, setEmailError] = useState("")

  const submitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      // @ts-ignore
      const oData = new FormData(event.target)
      const json = formData2json(oData)
      const email = json["email"]

      if (
        // 中英文点下划线@英文.
        !/^[A-Za-z0-9\u4e00-\u9fa5.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          email
        )
      ) {
        setEmailError("邮箱地址格式不正确")
        return
      }

      novus.models.authModel.actions.setEmail(email)

      setCheckAuthing(true)

      await AV.User.requestPasswordReset(email)

      setCheckAuthing(false)
      setResetSuccess(true)

      message.success("我们已经向您的邮箱发送了一封重置邮件！")

    },
    []
  )

  return (
    <Paper
      elevation={3}
      css={css`
        padding: 20px 20px;
      `}
    >
      <form autoComplete="current-password" onSubmit={submitHandler}>
        <div>
          <div
            css={css`
              margin: 10px 0;
              .MuiFormControl-root {
                margin: 10px 0;
              }
            `}
          >
            {resetSuccess ? <Alert severity="success">我们已经向您的邮箱发送了一封重置邮件</Alert>
              : <TextField
                required
                name="email"
                label="邮箱"
                fullWidth
                variant="outlined"
                error={emailError !== ""}
                helperText={emailError}
                onChange={() => setEmailError("")}
              />}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          {checkAuthing ? (
            <IconButton color="primary">
              <CircularProgress size={20} />
            </IconButton>
          ) : (
              resetSuccess ? <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={(e) => {
                  novus.models.authModel.actions.setMode('LOGIN')
                }}
              >
                去登录
              </Button> : <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={checkAuthing}
                >
                  发送重置邮件
              </Button>
            )}
          <Button
            color="primary"
            size="small"
            variant="text"
            disabled={checkAuthing}
          >
            试用
          </Button>
        </div>
        <hr
          css={css`
            margin: 10px 0;
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>想起密码了？</div>
          <div>
            <Button
              color="primary"
              size="small"
              variant="text"
              disabled={checkAuthing}
              onClick={(e) => {
                novus.models.authModel.actions.setMode('LOGIN')
              }}
            >
              登录
            </Button>
          </div>
        </div>
      </form>
    </Paper>
  )
})
