import React, { useMemo } from "react"
import { css } from "@emotion/core"

import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { useTheme } from "@material-ui/core/styles"
import { Card } from "@material-ui/core"

import Loading from "./loading"
import PullToRefresh from "../utils/PullToRefresh"
import { bpMaxSM } from "../utils/breakpoints"

type MainContentprops = {
  onRefresh?: (value?: void) => PromiseLike<void>;
  title: React.ReactNode;
  children: React.ReactNode;
  titleRights: React.ReactNode;
  sideWidth?: number;
  side?: React.ReactNode;
  sideLoading?: boolean;
  hideToolbar?: boolean;
  mainLoading?: boolean;
  noMaxWidth?: boolean;
  onReachBottom?: Function;
  showDrawSideAtMain?: boolean;
}

function MainContent({
  title,
  titleRights,
  children,
  side,
  sideWidth = 360,
  hideToolbar = false,
  sideLoading = false,
  mainLoading = false,
  noMaxWidth = false,
  showDrawSideAtMain = false,
  onReachBottom = null,
  onRefresh,
}: MainContentprops) {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const theme = useTheme()

  const sideC = useMemo(() => {
    return (
      <Card
        elevation={1}
        css={css`
          height: 100%;
          overflow: auto;
          margin: 0;
          top: 0;
          padding: 20px 0px 20px;
          overflow: auto;
          background-color: #383838;
          backdrop-filter: saturate(180%) blur(20px);
          ${theme.breakpoints.up("sm")} {
            position: absolute;
            padding: 60px 0px 20px;
          }
        `}
        style={{ width: `${sideWidth}px` }}
      >
        {sideLoading ? <Loading /> : side}
      </Card>
    )
  }, [sideWidth, sideLoading, side])

  const mainC = useMemo(() => {
    return (
      <div
        css={css`
          width: 100%;
          margin: 0px auto;
        `}
        style={{
          ...(noMaxWidth ? {} : { maxWidth: 800 }),
        }}
      >
        {mainLoading ? <Loading /> : children}
      </div>
    )
  }, [mainLoading, children])

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
        position: relative;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #efefef1a;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 55px;
          z-index: 2;

          background-color: rgba(0, 0, 0, 0);
          backdrop-filter: saturate(200%) blur(20px);
          ${bpMaxSM} {
            display: ${hideToolbar ? "none" : "flex"};
          }
        `}
      >
        <Toolbar
          variant="dense"
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          {side && (
            <Hidden smUp>
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          <Typography
            variant="h6"
            color="inherit"
            css={css`
              flex-grow: 1;
            `}
          >
            {title}
          </Typography>
          <div>{titleRights}</div>
        </Toolbar>
      </div>
      <div
        css={css`
          height: 100vh;
          overflow: auto;
          background: #2f2f2f;
        `}
      >
        <div
          css={css`
            display: flex;
            height: 100%;
          `}
        >
          {side && <Hidden xsDown>{sideC}</Hidden>}
          {side && (
            <Hidden smUp>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                css={css`
                  & > .MuiDrawer-paper {
                    background: transparent !important;
                  }
                  .MuiPaper-root {
                    background: rgba(255, 255, 255, 0.3);
                  }
                `}
              >
                {sideC}
              </Drawer>
            </Hidden>
          )}
          <Hidden smUp>
            <PullToRefresh
              css={css`
                flex: 1;
                width: 100%;
                overflow: auto;
                padding-top: 55px;
                scroll-behavior: smooth;
                ${bpMaxSM} {
                  padding-top: ${hideToolbar ? "0" : "55px"};
                  padding-bottom: 55px;
                }
              `}
              onScroll={
                onReachBottom !== null
                  ? (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
                      if (
                        event.currentTarget.scrollHeight -
                          event.currentTarget.scrollTop -
                          event.currentTarget.offsetHeight <
                        100
                      ) {
                        onReachBottom()
                      }
                    }
                  : null
              }
              onRefresh={onRefresh}
            >
              {showDrawSideAtMain && side}
              {mainC}
            </PullToRefresh>
          </Hidden>
          <Hidden xsDown>
            <div
              css={css`
                flex: 1;
                width: 100%;
                overflow: auto;
                padding: 60px 0 10px;
                scroll-behavior: smooth;
              `}
              style={{ marginLeft: `${side ? sideWidth : 0}px` }}
              onScroll={
                onReachBottom !== null
                  ? (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
                      if (
                        event.currentTarget.scrollHeight -
                          event.currentTarget.scrollTop -
                          event.currentTarget.offsetHeight <
                        100
                      ) {
                        onReachBottom()
                      }
                    }
                  : null
              }
            >
              {mainC}
            </div>
          </Hidden>
        </div>
      </div>
    </div>
  )
}

export default MainContent
