import React, { memo, useState, useEffect } from "react"
import { css } from "@emotion/core"

import Logo from "./logo"
import { LoginPanel } from "./login"
import { SignUpPanel } from "./signup"
import LinearProgress from "@material-ui/core/LinearProgress"
import { authModel } from "../../models"
import { useNovus } from "src/lib/novus"
import { AuthPanelMode } from "src/models/authModel"
import { ResetPassPanel } from "./reset"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

export const AuthInputer = memo(() => {

  let [mode] = useNovus<[AuthPanelMode]>(
    models => {
      return [models.authModel.state.mode]
    },
    ["authModel"]
  )

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #111319;
        color: #ffffff;
        position: fixed;
        z-index: 99999;
        width: 100%;
      `}
    >
      <div
        css={css`
          max-width: 400px;
          width: 100%;
          margin: -20vh 25px 0;
        `}
      >
        <div>
          <Logo plain color="#FFFFFF" />
          <p
            css={css`
              text-align: center;
              margin-top: 5px;
            `}
          >
            个人工作流
          </p>
        </div>
        {mode === "LOGIN" && <LoginPanel />}
        {mode === "SIGN_UP" && <SignUpPanel />}
        {mode === "RESET_PASS" && <ResetPassPanel />}
        {mode === "TRY" && (
          <p
            css={css`
              text-align: center;
              margin-top: 5px;
              color: #ffffff;
            `}
          >
            准备使用测试账号登录...
          </p>
        )}
      </div>
    </div>
  )
})
