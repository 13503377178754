import React from "react";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from "styled-components";

type Props = {
  src?: string,
  border?: boolean,
  title?: string,
  icon?: React.ReactNode,
  canonicalUrl?: string,
  isSelected?: boolean,
  width?: string,
  height?: string,
  ref?: React.Ref<HTMLIFrameElement>,
};


export default class Frame extends React.Component<Props> {
  mounted: boolean;
  state = {
    isLoaded: false,
  }

  componentDidMount() {
    this.mounted = true;
    window.setTimeout(this.loadIframe, 0);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadIframe = () => {
    if (!this.mounted) return;
    this.setState({ isLoaded: true })
  };

  render() {
    const {
      border,
      width = "100%",
      height = "400px",
      ref,
      icon,
      title,
      canonicalUrl,
      isSelected,
      src,
    } = this.props;
    const Component = border ? StyledIframe : "iframe";
    const withBar = !!(icon || canonicalUrl);

    return (
      <Rounded
        width={width}
        height={height}
        withBar={withBar}
        className={isSelected ? "ProseMirror-selectednode" : ""}
      >
        {this.state.isLoaded && (
          <Component
            ref={ref}
            withBar={withBar}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            width={width}
            height={height}
            // type="text/html"
            frameBorder="0"
            title="embed"
            loading="lazy"
            src={src}
            allowFullScreen
          />
        )}
        {withBar && (
          <Bar>
            {/* align="center" */}
            {icon} <Title>{title}</Title>
            {canonicalUrl && (
              <Open
                href={canonicalUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenInNewIcon /> Open
              </Open>
            )}
          </Bar>
        )}
      </Rounded>
    );
  }
}

type RoundedProps = {
  withBar: boolean;
  width: number | string;
  height: number | string;
}
const Rounded = styled.div<RoundedProps>`
  border-radius: ${(props) => (props.withBar ? "3px 3px 0 0" : "3px")};
  overflow: hidden;
  width: ${(props) => props.width};
  height: ${(props) => (props.withBar ? parseInt(props.height.toString()) + 28 : props.height)};
`;

const Open = styled.a`
  color: ${(props) => props.theme.textSecondary} !important;
  font-weight: 500;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  padding: 0 8px;
`;

const Title = styled.span`
  font-weight: 500;
  padding-left: 4px;
`;

const Bar = styled.div`
  background: ${(props) => props.theme.secondaryBackground};
  color: ${(props) => props.theme.textSecondary};
  padding: 0 8px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  user-select: none;
`;

type StyledIframeProps = {
  withBar: boolean;
}
const StyledIframe = styled.iframe<StyledIframeProps>`
  border: 1px solid;
  border-color: ${(props) => props.theme.embedBorder};
  border-radius: ${(props) => (props.withBar ? "3px 3px 0 0" : "3px")};
  display: block;
`;
