import React, { useState, useCallback } from "react"
import { css } from "@emotion/core"
import timeAgoFormat from "../../utils/timeago"
import { oxHexToRgb } from "../../utils/colors"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import { TodoType, TodoCatType } from "src/lib/LCTypes"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionActions from "@material-ui/core/AccordionActions"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import useMessage from "src/utils/message"
import { todoModel } from "src/models"

type TodoItemProps = {
  cat: TodoCatType
  todo: TodoType
  // updater: (plain: TodoType, attrs: Partial<TodoType>) => Promise<TodoType>
  showDeleteDialog: (todo: TodoType) => void
  showEditingDialog: (todo: TodoType) => void
}

export default React.memo(
  ({
    cat,
    todo,
    // updater,
    showEditingDialog,
    showDeleteDialog,
  }: TodoItemProps) => {
    const message = useMessage()

    const [changing, setChanging] = useState(false)

    const checkChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        event.stopPropagation()
        setChanging(true)
        todoModel.actions
          .updateTodo(todo, { status: checked ? 1 : 0 })
          .then(() => {
            message.success(checked ? "恭喜完成任务！" : "还需加把努力~")
            setChanging(false)
          })
      },
      [todo]
    )

    return (
      <Accordion
        key={todo.objectId}
        css={css`
          border-radius: 0 !important;
        `}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          css={css`
            .MuiAccordionSummary-content {
              margin: 0;
              position: relative;
            }
            .MuiListItemText-root {
              display: flex;
              align-items: center;
            }
          `}
        >
          <ListItemIcon
            css={css`
              min-width: 24px;
            `}
          >
            {changing ? (
              <div
                css={css`
                  padding: 6px 0;
                  margin: 0 13px 0 1px;
                `}
              >
                <CircularProgress size={20} />
              </div>
            ) : (
                <Checkbox
                  edge="start"
                  checked={todo.status === 1}
                  color={todo.status === 1 ? "default" : "secondary"}
                  tabIndex={-1}
                  disableRipple
                  onClick={e => e.stopPropagation()}
                  onChange={checkChange}
                />
              )}
          </ListItemIcon>
          <ListItemText
            primary={
              <span
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                {cat && (
                  <span
                    css={css`
                      padding: 2px 4px;
                      border-radius: 3px;
                      color: #fff;
                      margin-right: 5px;
                      white-space: nowrap;
                    `}
                    style={{
                      background: oxHexToRgb(cat.color),
                    }}
                  >
                    {cat.name}
                  </span>
                )}
                <span
                  css={css`
                    word-break: break-all;
                  `}
                  style={
                    todo.status === 1
                      ? {
                        opacity: ".6",
                        textDecoration: "line-through",
                      }
                      : {}
                  }
                >
                  {todo.name}
                </span>
                <span
                  css={css`
                    margin-left: 10px;
                    color: #797979;
                    line-height: 20px;
                  `}
                >
                  {timeAgoFormat(new Date(todo.updatedAt))}
                </span>
              </span>
            }
          />
        </AccordionSummary>
        {todo.content && (
          <AccordionDetails>
            <Typography color="textSecondary" variant="body2">
              {todo.content}
            </Typography>
          </AccordionDetails>
        )}
        <AccordionActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              showEditingDialog(todo)
            }}
          >
            编辑
          </Button>
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              showDeleteDialog(todo)
            }}
          >
            删除
          </Button>
        </AccordionActions>
        <Divider />
      </Accordion>
    )
  }
)
