import React from "react"
import { css } from "@emotion/core"

import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const ITEM_HEIGHT = 48

type CommonCardProps = {
  title: React.ReactNode
  titleBadge?: string
  titleBadgeBg?: string
  titleBadgeColor?: string
  desc: React.ReactNode
  subheader: React.ReactNode
  options: { id: string; title: string }[]
  onOptionChoose: (id: string) => void
}

export default React.memo(
  ({
    title,
    titleBadge,
    titleBadgeBg,
    titleBadgeColor,
    desc,
    subheader,
    options = [],
    onOptionChoose,
  }: CommonCardProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    return (
      <Card
        elevation={0}
        css={css`
          margin: 0 5px 10px;
        `}
      >
        <CardHeader
          action={
            <IconButton
              onClick={event => {
                setAnchorEl(event.currentTarget)
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          }
          title={title}
          subheader={
            <div css={css`
              display: flex;
              align-items: center;
            `}>
              {titleBadge && (
                <span
                  css={css`
                    margin: 0 4px 0 0;
                    background: #4049dc;
                    color: #fff;
                    padding: 0px 3px;
                    font-size: .8em;
                    border-radius: 4px;
                    vertical-align: middle;
                  `}
                  style={{
                    background: titleBadgeBg,
                    color: titleBadgeColor,
                  }}
                >
                  {titleBadge}
                </span>
              )}
              {subheader}
            </div>
          }
          css={css`
            padding: 13px 16px 0px !important;
            .MuiCardHeader-title {
              color: #efefef;
              font-weight: bold;
            }
            .MuiCardHeader-title a {
              color: #efefef;
              text-decoration: none;
              &:hover {
                color: #99a8ff;
              }
            }
            .MuiCardHeader-content {
              flex: 1 1 auto;
              width: 100%;
            }
          `}
        />
        <CardContent
          css={css`
            padding: 6px 16px 17px !important;
          `}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            css={css`
            `}
          >
            {desc}
          </Typography>
        </CardContent>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={(event: {}) => {
            // @ts-ignore
            event.stopPropagation()
            setAnchorEl(null)
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.id}
              onClick={event => {
                event.stopPropagation()
                setAnchorEl(null)
                onOptionChoose(option.id)
              }}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Card>
    )
  }
)
