import React, { useMemo, useCallback, useContext } from "react"
import { css } from "@emotion/core"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { BookMarkListType } from "src/lib/LCTypes"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { bookMarkModel } from "src/models"
import openPage from "src/utils/openPage"

type BookMarkItemProps = {
  bookmark: BookMarkListType
  showDeleteDialog: (bookmark: BookMarkListType) => void
  editBookMark: (bookmark: BookMarkListType) => void
}

export default React.memo(
  ({ bookmark, editBookMark, showDeleteDialog }: BookMarkItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const onOptionChoose = (option: string) => {
      setAnchorEl(null)
      if (option == "delete") {
        showDeleteDialog(bookmark)
      }
      if (option == "edit") {
        editBookMark(bookmark)
      }
    }

    const onLinkClick = useCallback(
      e => {
        openPage(bookmark.link, e)
        bookMarkModel.actions.updateBookMark(bookmark, {
          clicks: bookmark.clicks + 1,
        })
      },
      [bookmark]
    )

    const menu = useMemo(() => {
      return (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={event => {
            // @ts-ignore
            event.stopPropagation()
            setAnchorEl(null)
          }}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          {[
            { id: "edit", title: "编辑" },
            { id: "delete", title: "删除" },
          ].map(option => (
            <MenuItem
              key={option.id}
              onClick={e => {
                e.stopPropagation()
                onOptionChoose(option.id)
              }}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      )
    }, [anchorEl])

    const chooseMenu = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
      },
      [setAnchorEl]
    )

    return (
      <ListItem
        key={bookmark.objectId}
        button
        onClick={onLinkClick}
        component="a"
        href={bookmark.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>
          <img
            src={bookmark.icon}
            css={css`
              width: 20px;
              height: 20px;
            `}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <span
                css={css`
                `}
              >
                {bookmark.title}
              </span>
              <span
                css={css`
                  margin-left: 10px;
                  color: #797979;
                  height: 20px;
                  line-height: 20px;
                `}
              >
                {bookmark.clicks}
                {/* {timeAgoFormat(new Date(bookmark.updatedAt))} */}
              </span>
            </span>
          }
          secondary={
            <span
              css={css`
              `}
            >
              {bookmark.desc}
            </span>
          }
        />
        {menu}
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={chooseMenu}>
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
)
