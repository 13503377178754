import React, { memo, useState, useCallback } from "react"
import { css } from "@emotion/core"

import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import { formData2json } from "src/utils/utils"
import { authModel } from "../../models/"
import { useNovus, novus } from "src/lib/novus"

export const LoginPanel = memo(() => {
  const [showPass, setShowPass] = useState(false)
  const [emailError, setEmailError] = useState("")

  let [checkAuthing, email] = useNovus<[boolean, string]>(
    models => {
      return [models.authModel.state.checkAuthing, models.authModel.state.email]
    },
    ["authModel"]
  )

  const submitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      // @ts-ignore
      const oData = new FormData(event.target)
      const json = formData2json(oData)
      const email = json["email"]

      if (
        // 中英文点下划线@英文.
        !/^[A-Za-z0-9\u4e00-\u9fa5.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          email
        )
      ) {
        setEmailError("邮箱地址格式不正确")
        return
      }

      await authModel.actions.login(email, json["password"])
    },
    []
  )

  return (
    <Paper
      elevation={3}
      css={css`
        padding: 20px 20px;
      `}
    >
      <form autoComplete="current-password" onSubmit={submitHandler}>
        <div>
          <div
            css={css`
              margin: 10px 0;
              .MuiFormControl-root {
                margin: 10px 0;
              }
            `}
          >
            <TextField
              required
              name="email"
              label="邮箱"
              fullWidth
              variant="outlined"
              error={emailError !== ""}
              helperText={emailError}
              value={email}
              onChange={e => {
                authModel.actions.setEmail(e.target.value)
                setEmailError("")
              }}
              css={css`
                margin-bottom: 10px;
              `}
            />
            <TextField
              required
              name="password"
              label="密码"
              fullWidth
              variant="outlined"
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPass(!showPass)
                      }}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault()
                      }}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          {checkAuthing ? (
            <IconButton color="primary">
              <CircularProgress size={20} />
            </IconButton>
          ) : (
            <Button
              color="primary"
              size="small"
              variant="contained"
              type="submit"
              disabled={checkAuthing}
            >
              登录
            </Button>
          )}
          <Button
            color="primary"
            size="small"
            variant="text"
            disabled={checkAuthing}
            onClick={e => {
              novus.models.authModel.actions.setMode("RESET_PASS")
            }}
          >
            忘记密码？
          </Button>
        </div>
        <hr
          css={css`
            margin: 10px 0;
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>还没有账号？</div>
          <div>
            <Button
              color="primary"
              size="small"
              variant="text"
              disabled={checkAuthing}
              onClick={e => {
                novus.models.authModel.actions.setMode("SIGN_UP")
              }}
            >
              注册
            </Button>
            <span
              css={css`
                margin: 0 10px;
              `}
            >
              /
            </span>
            <Button
              color="primary"
              size="small"
              variant="text"
              disabled={checkAuthing}
              onClick={e => {
                novus.models.authModel.actions.tryout()
              }}
            >
              试用
            </Button>
          </div>
        </div>
      </form>
    </Paper>
  )
})
