import React from "react"
import { css } from "@emotion/core"
import MainContent from "src/components/Main"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useMessage from "src/utils/message"
import IconButton from "@material-ui/core/IconButton"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import AssignmentIcon from "@material-ui/icons/Assignment"
import { AboutAndFeedbackSetting } from "./setting/aboutAndFeedback"
import { AppSetting } from "./setting/appSetting"
import { ProfileSetting } from "./setting/profileSetting"

export const Setting = React.memo(() => {
  const message = useMessage()

  const inApp: boolean =
    window.UFLOW_APP_JS_CHANNEL && !!window.UFLOW_APP_JS_CHANNEL.openPop

  return (
    <MainContent mainLoading={false} title="设置" titleRights={[]}>
      <ProfileSetting />

      {inApp && <AppSetting />}

      <AboutAndFeedbackSetting />

      <Paper
        elevation={2}
        css={css`
          margin: 10px;
          li {
            margin: 0;
          }
        `}
      >
        <List dense>
          <ListItem button component="a" target="_blank" href="/help">
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="帮助" />
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </ListItem>
          <ListItem button component="a" target="_blank" href="/agreement">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="用户协议" />
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </ListItem>
          <ListItem button component="a" target="_blank" href="/privacy">
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText primary="隐私条例" />
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </ListItem>
        </List>
      </Paper>
    </MainContent>
  )
})
