import "../lib/av-models"
import { novus, NovusBaseModel } from "src/lib/novus"

import { AuthModel } from "./authModel"
import { TodoModel } from "./todoModel"
import { RssModel } from "./rssModel"
import { NoteModel } from "./noteModel"
import { ReadingModel } from "./readingModel"
import { TimeMachineModel } from "./timeMachineModel"
import { BookMarkModel } from "./bookmarkModel"
import { GlobalModel } from "./globalModel"

export interface TModelsPool {
  [ind: string]: NovusBaseModel<any, any>
  globalModel?: GlobalModel
  authModel?: AuthModel
  todoModel?: TodoModel
  rssModel?: RssModel
  noteModel?: NoteModel
  readingModel?: ReadingModel
  timeMachineModel?: TimeMachineModel
  bookMarkModel?: BookMarkModel
}

export const globalModel = new GlobalModel()
novus.bindModel(globalModel)

export const authModel = new AuthModel()
novus.bindModel(authModel)

export const todoModel = new TodoModel()
novus.bindModel(todoModel)

export const rssModel = new RssModel()
novus.bindModel(rssModel)

export const noteModel = new NoteModel()
novus.bindModel(noteModel)

export const readingModel = new ReadingModel()
novus.bindModel(readingModel)

export const timeMachineModel = new TimeMachineModel()
novus.bindModel(timeMachineModel)

export const bookMarkModel = new BookMarkModel()
novus.bindModel(bookMarkModel)
