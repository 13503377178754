import React, { useState, useCallback, useEffect, useMemo } from "react"
import { css } from "@emotion/core"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import RestorePageIcon from "@material-ui/icons/RestorePage"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import HttpIcon from "@material-ui/icons/Http"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import AutorenewIcon from "@material-ui/icons/Autorenew"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import Switch from "@material-ui/core/Switch"
import ListSubheader from "@material-ui/core/ListSubheader"

export const AppSetting = React.memo(() => {
  const clearCache = useCallback(() => {
    if (
      window.UFLOW_APP_JS_CHANNEL &&
      window.UFLOW_APP_JS_CHANNEL.cleanHTTPCache
    ) {
      window.UFLOW_APP_JS_CHANNEL.cleanHTTPCache()
    }
  }, [])

  const refreshPage = useCallback(() => {
    if (
      window.UFLOW_APP_JS_CHANNEL &&
      window.UFLOW_APP_JS_CHANNEL.refreshPage
    ) {
      window.UFLOW_APP_JS_CHANNEL.refreshPage()
    }
  }, [])

  return (
    <Paper
      elevation={2}
      css={css`
        margin: 10px;
        li {
          margin: 0;
        }
      `}
    >
      <List subheader={<ListSubheader>App 设置</ListSubheader>}>
        <ListItem>
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText primary="监听手机剪贴板" secondary="快速收集阅读" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              // onChange={}
              // checked={}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <ListItemIcon>
            <HttpIcon />
          </ListItemIcon>
          <ListItemText primary="清除 APP 缓存" secondary="需要重启 APP" />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={clearCache}>
              <DeleteForeverIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <ListItemIcon>
            <RestorePageIcon />
          </ListItemIcon>
          <ListItemText primary="刷新 APP 页面" secondary="重新加载页面" />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={refreshPage}>
              <AutorenewIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  )
})
