import React, { useEffect, useState, useCallback, useMemo } from "react"
import { css } from "@emotion/core"
import Editor from "rich-markdown-editor"

import useMessage from "src/utils/message"

import RichMarkdownEditor from "rich-markdown-editor"
import { noteModel } from "src/models"
import { Scroll2CursorExtension } from "./extensions/scroll2cursor"

import "./md-style.css"
import { theme, dictionary } from "./editor-config"
import { useNovus } from "src/lib/novus"
import embeds from './embeds'

const s2ce = new Scroll2CursorExtension()

type NoteEditorProps = {
  ref: React.Ref<RichMarkdownEditor>
  defaultValue: string
  onSave: (a: { done: boolean }) => void
  onSearchLink: (
    title: string
  ) => Promise<
    {
      title: string
      url: string
    }[]
  >
  uploadImage: (file: File) => Promise<string>
}

export const NoteEditor = React.forwardRef(
  (props: NoteEditorProps, ref: React.MutableRefObject<RichMarkdownEditor>) => {
    const message = useMessage()

    let [mdMode] = useNovus(
      models => {
        return [
          models.noteModel.state.mdMode,
        ]
      },
      ["noteModel"]
    )

    useEffect(() => {
      if (ref && ref.current) ref.current.focusAtEnd()
    }, [])

    return mdMode ? (
      <Editor
        className="ubug-md"
        // theme={theme}
        dark={true}
        dictionary={dictionary}
        ref={ref}
        placeholder="一篇深刻思考的文章..."
        // 应该为 value，但是好像无效，目前使用 defaultValue 配合 fetchingVersions
        defaultValue={props.defaultValue}
        // onChange={() => console.log("onChange triggered")}
        onChange={(getValue: () => string) => {
          noteModel.actions.setContentValue(getValue())
        }}
        onSave={props.onSave}
        onCancel={() => console.log("Cancel triggered")}
        onClickLink={href => console.log("Clicked link: ", href)}
        onClickHashtag={tag => console.log("Clicked hashtag: ", tag)}
        onShowToast={msg => message.info(msg)}
        onSearchLink={props.onSearchLink}
        uploadImage={props.uploadImage}
        extensions={[s2ce]}
        // getLinkComponent={href => {
        //   if (href.match(/google/)) {
        //     return GoogleEmbed
        //   }
        // }}
        // @ts-ignore
        autoFocus
        onKeyDown={() => { }}
        embeds={embeds}
      />
    ) : <textarea
      className="ubug-textarea"
      value={noteModel.state.currentEditContentValue}
      onChange={(e) => {
        noteModel.actions.setContentValue(e.target.value)
      }}
    ></textarea>
  }
)
