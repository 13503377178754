import React from "react"
import { css } from "@emotion/core"
import { useRef } from "react"
import { bpDesktopOnly, bpMaxSM } from "../../utils/breakpoints"
import timeAgoFormat from "../../utils/timeago"

import InputBase from "@material-ui/core/InputBase"
import { searchLink } from "../../utils/linkSearch"
import useMessage from "src/utils/message"
import { uplodaNoteFile } from "src/lib/av-models"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Hidden from "@material-ui/core/Hidden"
import Toolbar from "@material-ui/core/Toolbar"
import RichMarkdownEditor from "rich-markdown-editor"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import Loading from "src/components/loading"
import { useNovus } from "src/lib/novus"
import { noteModel } from "src/models"

import { NoteEditor } from "./Editor"

import { NoteToolbar } from "./NoteToolbar"

/**
 * 多重保险的保存操作
 *
 * 1. 文章每次变动会缓存到内存、同时保存到 localStorage 中，每 1 分钟创建版本，如果失败提示用户
 * 2. 每次保存成功之后 2s 后检查保存状态，如果异常提示用户
 * 3. 每次保存操作，fetchOnSave 取回线上值二次确认是否和当前编辑值一致，否则提示用户
 * 4. 删除功能默认为废纸篓
 * 5. 如果拉取的内容和上次本地存储不同，提示用户是否恢复备份
 * 6. 内容有改动的状态，关闭网页会自动阻止
 */

export const NoteEditorArea = React.memo(() => {
  const message = useMessage()

  const [
    isTitleChanged,
    isContentChanged,
    currentEditTitleValue,
    currentEditContentValue,
    currentNote,
    rebuildNoteEditorFlag,
    diffStyle,
    mdMode,
  ] = useNovus(
    models => {
      return [
        models.noteModel.state.isTitleChanged,
        models.noteModel.state.isContentChanged,
        models.noteModel.state.currentEditTitleValue,
        models.noteModel.state.currentEditContentValue,
        models.noteModel.state.currentNote,
        models.noteModel.state.rebuildNoteEditorFlag,
        models.noteModel.state.diffStyle,
        models.noteModel.state.mdMode,
      ]
    },
    ["noteModel"]
  )

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
        position: relative;
        ${bpDesktopOnly} {
          max-width: 800px;
          margin: 0 auto;
        }
      `}
      className={mdMode ? 'ubug-textarea-wrap' : 'ubug-md-wrap'}
    >
      <Hidden smUp>
        <>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #292929;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 55px;
              z-index: 2;

              // background-color: rgba(255, 255, 255, 0.75);
              backdrop-filter: saturate(180%) blur(20px);
            `}
          >
            <Toolbar
              variant="dense"
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <IconButton
                color="inherit"
                edge="start"
                onClick={() => noteModel.actions.updateCurrentNodeId("")}
              >
                <ChevronLeft />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                css={css`
                  flex-grow: 1;
                `}
              >
                编辑笔记
              </Typography>
              <div>
                <NoteToolbar />
              </div>
            </Toolbar>
          </div>
        </>
      </Hidden>

      <div
        css={css`
          flex: 1;
          padding: 10px 20px 20px 35px;
          overflow: auto;
          margin: 0 auto;
          height: 100%;
          ${bpMaxSM} {
            padding: 55px 27px 20px;
          }
          ${bpDesktopOnly} {
          }
        `}
      >
        <InputBase
          style={{ fontSize: "3rem", fontWeight: "bold" }}
          fullWidth
          value={currentEditTitleValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            noteModel.actions.setTitleValue(e.target.value)
          }
          // onInput={(e: React.FormEvent<HTMLInputElement>) =>
          //   noteModel.actions.setTitleValue(e.target.value)
          // }
          placeholder="一个深思熟虑的标题"
        />
        <p
          css={css`
            margin: 10px 0 20px;
            display: flex;
            justify-content: space-between;
          `}
        >
          <span>
            {currentNote ? timeAgoFormat(new Date(currentNote.updatedAt)) : ""}{" "}
            更新
          </span>
          <strong>
            {[isTitleChanged ? "标题" : "", isContentChanged ? "内容" : ""]
              .filter(v => v !== "")
              .join(", ")}
            {isTitleChanged || isContentChanged ? "已修改 *" : ""}
          </strong>
        </p>

        {rebuildNoteEditorFlag ? (
          <Loading />
        ) : (
            <NoteEditor
              defaultValue={currentEditContentValue}
              onSave={noteModel.actions.save}
              onSearchLink={searchLink}
              uploadImage={async (file: File) => {
                console.log("File upload triggered: ", file)
                const url = await uplodaNoteFile(currentNote, file, message)
                return url
              }}
            />
          )}
      </div>
      <style>{diffStyle}</style>
    </div>
  )
})
