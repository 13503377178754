import { NovusBaseModel } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"
import { NoteType } from "src/lib/LCTypes"
import {
  updateAVinList,
  deleteAVinList,
  createAVinList,
} from "src/lib/av-models"

// @ts-ignore
import { computeDirtyDiff, generateDecorations } from "../routes/notes/diff"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"

const sleep = async (time: number) => {
  return new Promise((r) => {
    setTimeout(r, time);
  });
}

type DiffChange = {
  originalStartLineNumber: number;
  originalEndLineNumber: number;
  modifiedStartLineNumber: number;
  modifiedEndLineNumber: number;
}
type DiffDecoration = {
  options: {
    getStyles: (selector: string) => string
  };
  range: {
    endColumn: number;
    endLineNumber: number;
    startColumn: number;
    startLineNumber: number;
  }
}
type LocalSaveNoteType = { title: string; content: string; time: number }

export interface INoteState {
  diffStyle: string;
  mdMode: boolean;
  sharing: boolean;
  noteLoading: boolean;
  notes: NoteType[];
  rebuildNoteEditorFlag: boolean;
  currentNote: NoteType;
  currentNoteId: string;
  isContentChanged: boolean;
  isTitleChanged: boolean;
  currentEditTitleValue: string;
  currentEditContentValue: string;
}

let timer: number

const breakLines = (content: string) => content.split(/[\n\\]\n/)

const minuteStr = (d: Date) =>
  `${d.getFullYear()}_${d.getMonth()}_${d.getDate()}_${d
    .getHours()
    .toString()
    .padStart(2, "0")}_${d.getMinutes().toString().padStart(2, "0")}`

// 使用继承类的形式限制属性和方法
export class NoteModel extends NovusBaseModel<INoteState> {
  namespace = "noteModel"
  constructor() {
    super()
    this.state = {
      diffStyle: "",
      mdMode: true,
      sharing: false,
      noteLoading: false,
      notes: [],
      rebuildNoteEditorFlag: false,
      currentNote: null,
      currentNoteId: "",
      isContentChanged: false,
      isTitleChanged: false,
      currentEditTitleValue: "",
      currentEditContentValue: "",
    }
  }
  message: MessageType
  actions = {
    rebuildNoteEditor: async () => {
      setTimeout(() => {
        this.setState({
          rebuildNoteEditorFlag: true,
        })
        const detect = () => {
          if (document.querySelector('.ubug-md')) {
            requestAnimationFrame(detect)
          } else {
            this.setState({
              rebuildNoteEditorFlag: false,
            })
          }
        }
        detect()
      }, 0)
    },
    calcIsChange: () => {
      if (this.state.currentNote) {
        const isContentChanged =
          this.state.currentEditContentValue !== this.state.currentNote.content
        const isTitleChanged =
          this.state.currentEditTitleValue !== this.state.currentNote.title
        this.setState({ isContentChanged, isTitleChanged })
        // this.actions.calcDiff()
      }
    },
    calcDiff: () => {
      /** TODO: 实现还是有点问题
       * 1. list 的问题，ol>li>p
       * 2. css 选择器的问题，现在的行为单位，渲染出的有 p h1-6 div ol 之类的，但是 css 没办法按照序号选择目标
       **/

      if (this.state.currentNote) {
        if (this.state.isContentChanged) {
          const { currentNote, currentEditContentValue } = this.state

          const changes: DiffChange[] = computeDirtyDiff(
            breakLines(currentNote.content),
            breakLines(currentEditContentValue)
          )
          const decorations: DiffDecoration[] = generateDecorations(changes)

          let styles = ""
          decorations.forEach(decoration => {
            for (
              let i = decoration.range.startLineNumber;
              i <= decoration.range.endLineNumber;
              i++
            ) {
              console.log(
                document.querySelectorAll(
                  ".ProseMirror p:not(.ProseMirror-widget), .ProseMirror .code-block, .ProseMirror h2, .ProseMirror h3, .ProseMirror h4, .ProseMirror h5, .ProseMirror h6, .ProseMirror h1"
                )[i]
              )
              styles +=
                decoration.options.getStyles(
                  `.ProseMirror>p:nth-of-type(${i})`
                ) + "\n"
            }
          })
          this.setState({ diffStyle: styles })
        } else {
          this.setState({ diffStyle: "" })
        }
      }
    },
    setContentValue: (content: string) => {
      if (this.state.currentNote) {
        this.setState({ currentEditContentValue: content })
        this.actions.trySetItem()
        this.actions.calcIsChange()
      }
    },
    setTitleValue: (title: string) => {
      if (this.state.currentNote) {
        this.setState({ currentEditTitleValue: title })
        this.actions.trySetItem()
        this.actions.calcIsChange()
      }
    },
    switchMdMode: () => {
      this.setState((s) => ({
        mdMode: !s.mdMode
      }))
      this.actions.rebuildNoteEditor()
    },
    shareCurrentNode: async (checked: boolean) => {
      this.setState({ sharing: true })

      const note = this.state.notes.find(n => n.objectId === this.state.currentNoteId)
      await this.actions.updateNote(note, {
        shared: checked,
      })
      this.message.success(checked ? '分享成功' : '取消分享成功')
      this.setState({ sharing: false })
    },
    refresh: async () => {
      await this.actions.fetchNotes()
    },
    fetchNotes: async () => {
      const query = new AV.Query<AV.Object>("notes")
      query.descending("createdAt")
      query.equalTo("deleted", false)
      query.equalTo('user', AV.User.current())
      // query.select(['title', 'attachments']); // 避免获取 content 造成查询过大
      this.setState({ noteLoading: true })
      const result = await query.find()
      this.setState({
        notes: [...result.map(r => r.toJSON())],
        noteLoading: false,
      })
    },
    createNote: async (title: string): Promise<NoteType> => {
      const newList = await createAVinList<NoteType>(
        "notes",
        {
          user: AV.User.current(),
          title: title || "一个深思熟虑的标题",
          content: "一篇深刻思考的文章...",
        },
        this.state.notes
      )
      this.setState({ notes: newList })
      return this.state.notes[0]
    },
    deleteNote: async (plain: { objectId: string }) => {
      const note = this.state.notes.find(n => n.objectId === plain.objectId)
      await this.actions.updateNote(note, {
        deleted: true,
        shared: true,
      })
      if (this.state.currentNoteId === plain.objectId) {
        this.setState({ currentNoteId: "", currentNote: null })
      }
    },
    updateCurrentNodeId: async (objectId: string) => {
      if (
        this.state.currentNoteId !== "" &&
        (this.state.isContentChanged || this.state.isTitleChanged)
      ) {
        this.message.warning(
          "您当前的内容有变动暂存在本地未保存，返回笔记时记得恢复版本！！"
        )
      }

      const currentNote = this.state.notes.find(n => n.objectId === objectId)

      // 本地有历史记录的时候，提示是否恢复内容
      const localSave = this.actions.tryFindLocalSaveNewestNote(objectId)
      // 本地最新暂存时间大于服务器保存时间
      if (localSave && localSave.time > currentNote.savedAt) {
        if (
          localSave.content !== currentNote.content ||
          localSave.title !== currentNote.title
        ) {
          this.message.warning("本地暂存有更新的内容，是否恢复本地版本？", {
            autoHideDuration: 5000,
            action: key => (
              <Fragment>
                <Button
                  onClick={() => {
                    this.message.closeSnackbar(key)
                    this.setState({
                      currentEditContentValue: localSave.content,
                      currentEditTitleValue: localSave.title,
                    })
                    this.actions.trySetItem()
                    this.actions.calcIsChange()
                    this.actions.rebuildNoteEditor()
                  }}
                >
                  恢复
                </Button>
                <Button
                  onClick={() => {
                    this.message.closeSnackbar(key)
                  }}
                >
                  忽略
                </Button>
              </Fragment>
            ),
          })
        }
      }
      this.setState({
        currentNoteId: objectId,
        currentNote,
        diffStyle: "",
      })
      this.actions.setContentValue(currentNote ? currentNote.content : "")
      this.actions.setTitleValue(currentNote ? currentNote.title : "")
      this.actions.rebuildNoteEditor()
    },
    updateNote: async (plain: NoteType, attrs: Partial<NoteType>) => {
      const newList = await updateAVinList(
        "notes",
        plain,
        attrs,
        this.state.notes
      )
      const currentNote = newList.find(n => n.objectId === plain.objectId)
      this.setState({ currentNote: currentNote, notes: newList })
      return currentNote
    },
    save: async () => {
      console.log("[save] start")
      this.message.success("内容保存中...")
      const attrs = {
        title: this.state.currentEditTitleValue,
        content: this.state.currentEditContentValue,
        savedAt: new Date().getTime(),
      }

      console.log("[save] post")
      const afterSave = await this.actions.updateNote(
        this.state.currentNote,
        attrs
      )

      console.log("[save] recalcDiffStyles")
      this.actions.calcIsChange()

      if (
        afterSave.title === attrs.title &&
        afterSave.content === attrs.content
      ) {
        console.log("保存无误")
        this.message.success("更新成功，核准无误")
      } else {
        console.warn("服务端数据与本地有差别")
        this.message.success("更新成功，保存有差，请再保存")
      }
    },
    trySetItem: () => {
      try {
        const keyName = `note-${AV.User.current().id}-${this.state.currentNoteId
          }`
        const localSaves = this.actions.tryFindLocalSaveNotes(
          this.state.currentNoteId
        )
        const time = new Date()
        localSaves[minuteStr(time)] = {
          title: this.state.currentEditTitleValue,
          content: this.state.currentEditContentValue,
          time: time.getTime(),
        }

        // 去除后10个
        const times = Object.keys(localSaves)
        if (times.length > 10) {
          times
            .sort()
            .reverse()
            .slice(10)
            .forEach(t => delete localSaves[t])
        }

        localStorage.setItem(keyName, JSON.stringify(localSaves))

        console.log("本地缓存成功")
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          this.actions.detectLocalSave()
        }, 2000)
      } catch (error) {
        if (error == DOMException.QUOTA_EXCEEDED_ERR) {
          this.message.warning("本地暂存失败，请知悉！")
        }
      }
    },
    tryFindLocalSaveNewestNote: (noteId: string): LocalSaveNoteType | null => {
      const localSaves = this.actions.tryFindLocalSaveNotes(noteId)
      const times = Object.keys(localSaves)
      if (times.length > 0) return localSaves[times.sort().reverse()[0]]
      return null
    },
    tryFindLocalSaveNotes: (
      noteId: string
    ): { [ind: string]: LocalSaveNoteType } => {
      const keyName = `note-${AV.User.current().id}-${noteId}`
      const localStr = localStorage.getItem(keyName)
      try {
        if (localStr) {
          const noteLocal = JSON.parse(localStr)
          return noteLocal
        } else {
          return {}
        }
      } catch (e) {
        console.error(e)
        return {}
      }
    },
    detectLocalSave: () => {
      const localSave = this.actions.tryFindLocalSaveNewestNote(
        this.state.currentNoteId
      )
      if (localSave) {
        if (localSave.content !== this.state.currentEditContentValue) {
          this.message.warning("内容本地暂存和内存不一致，请知悉！")
        } else if (localSave.title !== this.state.currentEditTitleValue) {
          this.message.warning("标题本地暂存和内存不一致，请知悉！")
        } else {
          console.log("延时检查无误！")
        }
      } else {
        console.log("本地无存储！")
      }
    },
  }
}
