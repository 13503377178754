import React, { useState, useCallback, useRef } from "react"
import { css } from "@emotion/core"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { BookMarkCatType } from "src/lib/LCTypes"
import AddIcon from "@material-ui/icons/Add"
import { ConfirmInput } from "src/components/ConfirmInput"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { bookMarkModel } from "src/models"

export const CatCards = React.memo(() => {
  const message = useMessage()

  const [cats, currentCatId] = useNovus(
    models => {
      return [
        models.bookMarkModel.state.cats,
        models.bookMarkModel.state.currentCatId,
      ]
    },
    ["bookMarkModel"]
  )

  const currentEditRef = useRef<BookMarkCatType>()
  const [editVisible, setEditShow] = useState(false)
  const showEditingDialog = useCallback(entry => {
    currentEditRef.current = entry
    setEditShow(true)
  }, [currentEditRef])
  const handleEdit = useCallback(async newVal => {
    if (currentEditRef.current) {
      await bookMarkModel.actions.updateBookMarkCat(currentEditRef.current, {
        name: newVal,
      })
      message.success("更新成功")
    } else {
      await bookMarkModel.actions.createBookMarkCat({ name: newVal, nums: 0 })
      message.success("创建成功")
    }
    currentEditRef.current = null
  }, [currentEditRef])
  const deleteCat = useCallback(async () => {
    await bookMarkModel.actions.deleteBookMarkCat(currentEditRef.current)
  }, [currentEditRef])

  return (
    <div>
      <List
        css={css`
          width: 100%;
          li {
            margin: 0;
          }
          .MuiListItemSecondaryAction-root {
            opacity: 0;
          }
          li:hover .MuiListItemSecondaryAction-root {
            opacity: 1;
          }
        `}
      >
        {cats.map((cat, i) => {
          return (
            <ListItem
              key={cat.objectId}
              onClick={() =>
                bookMarkModel.actions.updateCurrentCat(cat.objectId)
              }
              button
              selected={currentCatId === cat.objectId}
            >
              <ListItemText
                primary={
                  <span>
                    {cat.name}
                    <span
                      css={css`
                        color: #9c9c9c;
                      `}
                    >
                      &nbsp;[{cat.nums || 0}]
                    </span>
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => showEditingDialog(cat)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <ConfirmInput
        visible={editVisible}
        setVisible={setEditShow}
        onConfirm={handleEdit}
        title={currentEditRef.current ? "修改书签分类名称" : "创建新的书签分类"}
        defaultValue={currentEditRef.current ? currentEditRef.current.name : ""}
        withDelete={currentEditRef.current ? deleteCat : null}
      />

      <Button onClick={() => showEditingDialog(null)} fullWidth>
        <AddIcon />
      </Button>
    </div>
  )
})
