import React, {
  useState,
  useCallback,
  memo,
  useMemo,
  useRef,
  useEffect,
} from "react"
import { css } from "@emotion/core"
import IconButton from "@material-ui/core/IconButton"
import SyncIcon from "@material-ui/icons/Sync"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

import BookmarkCard from "./bookmarks/bookmark-card"
import { CatCards } from "./bookmarks/cat-card"
import MainContent from "../components/Main"
import { BookMarkListType } from "src/lib/LCTypes"
import Empty from "src/components/empty"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import { NewForm, NewFormProps, FieldTypes } from "src/components/new-form"
import useMessage from "src/utils/message"
import Loading from "src/components/loading"
import { useNovus } from "src/lib/novus"
import { bookMarkModel } from "src/models"

const NewBookMark = NewForm as React.ComponentType<
  NewFormProps<BookMarkListType>
>

const BookMarkPart = () => {
  const message = useMessage()
  const [createVisible, setCreateShow] = useState(false)

  const [cats, list, catLoading, listLoading, currentCatId] = useNovus(
    models => {
      return [
        models.bookMarkModel.state.cats,
        models.bookMarkModel.state.list,
        models.bookMarkModel.state.catLoading,
        models.bookMarkModel.state.listLoading,
        models.bookMarkModel.state.currentCatId,
      ]
    },
    ["bookMarkModel"]
  )

  useEffect(() => {
    bookMarkModel.actions.refresh()
  }, [])

  const currentEditRef = useRef<BookMarkListType>()
  const editBookMark = useCallback((bookmark: BookMarkListType) => {
    currentEditRef.current = bookmark
    setCreateShow(true)
  }, [currentEditRef])
  const newBookMark = useCallback(() => {
    currentEditRef.current = null
    setCreateShow(true)
  }, [currentEditRef])
  const onNewOrEdit = useCallback(
    async json => {
      json.cat = {
        __type: "Pointer",
        className: "bookmarks_cat",
        objectId: json.cat,
      }
      if (currentEditRef.current == null) {
        await bookMarkModel.actions.createBookMark(json)
        message.success("创建成功")
        const targetCat = cats.find(c => c.objectId == json.cat.objectId)

        console.log(json.cat.objectId)
        console.log(cats)
        console.log(targetCat)

        console.log(targetCat.nums)
        await bookMarkModel.actions.updateBookMarkCat(targetCat, {
          nums: targetCat.nums + 1,
        })
      } else {
        await bookMarkModel.actions.updateBookMark(currentEditRef.current, json)
        message.success("更新成功")
      }
    },
    [cats, currentEditRef]
  )

  const breadcrumb = useMemo(() => {
    return (
      <h4
        css={css`
          height: 40px;
          margin: 0 21px;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-weight: bold !important;
            margin-bottom: 0;
          }
        `}
      >
        <Breadcrumbs>
          <Link
            component="button"
            onClick={() => bookMarkModel.actions.updateCurrentCat("")}
          >
            全部内容
          </Link>
          {currentCatId && (
            <Link component="span">
              {cats.find(c => c.objectId === currentCatId).name}
            </Link>
          )}
        </Breadcrumbs>
      </h4>
    )
  }, [currentCatId])

  return (
    <MainContent
      title="书签"
      titleRights={[
        <IconButton key="new" onClick={newBookMark}>
          <AddCircleOutlineIcon />
        </IconButton>,
        <IconButton
          key="refresh"
          onClick={() => {
            bookMarkModel.actions.refresh()
          }}
          disabled={catLoading || listLoading}
        >
          <SyncIcon />
        </IconButton>,
      ]}
      sideLoading={catLoading}
      mainLoading={false}
      sideWidth={180}
      side={<CatCards />}
      onRefresh={bookMarkModel.actions.refresh}
    >
      <NewBookMark
        visible={createVisible}
        setVisible={setCreateShow}
        onConfirm={onNewOrEdit}
        title={currentEditRef.current ? "编辑内容" : "新的内容"}
        fields={[
          {
            type: FieldTypes.TEXT,
            name: "link",
            label: "链接",
            autofocus: true,
          },
          {
            type: FieldTypes.TEXT,
            name: "title",
            label: "标题",
          },
          {
            type: FieldTypes.TEXT,
            name: "desc",
            label: "描述",
          },
          {
            type: FieldTypes.TEXT,
            name: "icon",
            label: "图标",
          },
          {
            type: FieldTypes.SELECT,
            label: "分类",
            name: "cat",
            options: cats.reduce<{ [ind: string]: string }>((ret, val) => {
              ret[val.objectId] = val.name
              return ret
            }, {}),
            defaultValue: currentEditRef.current
              ? currentEditRef.current.cat.objectId
              : cats.length > 0
                ? cats[0].objectId
                : "",
          },
        ]}
        editing={currentEditRef.current}
        setEditing={(target: BookMarkListType) => {
          currentEditRef.current = target
        }}
        autoDecodeUrl={{
          from: "link",
          to: ["title", "desc", "icon"],
        }}
      />

      <div
        css={css`
          margin: 0 10px 10px;
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        {breadcrumb}
        {list.length === 0 && !listLoading && (
          <Empty title="当前分类下没有内容~" />
        )}
        {listLoading && <Loading />}
        <BookmarkCard editBookMark={editBookMark} />
      </div>
    </MainContent>
  )
}

export default memo(BookMarkPart)
