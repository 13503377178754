import React, { useCallback } from "react"
import { css } from "@emotion/core"
import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useMessage from "src/utils/message"
import Button from "@material-ui/core/Button"
import { useNovus, novus } from "src/lib/novus"
import AV from "leancloud-storage"
import InfoIcon from "@material-ui/icons/Info"
import FeedbackIcon from "@material-ui/icons/Feedback"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import ListSubheader from "@material-ui/core/ListSubheader"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import IconButton from "@material-ui/core/IconButton"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import ForumIcon from "@material-ui/icons/Forum"

export const AboutAndFeedbackSetting = React.memo(() => {
  const message = useMessage()

  const [aboutOpen, setAboutOpen] = React.useState(false)
  const [feedbackOpen, setFeedbackOpen] = React.useState(false)
  const feedbackRef = React.useRef(null)

  const aboutClose = () => setAboutOpen(false)
  const feedbackClose = () => setFeedbackOpen(false)

  const submitFeedback = useCallback(async () => {
    console.log(feedbackRef.current)

    const post = new AV.Object("feedback")
    post.set("status", "open")
    post.set("content", feedbackRef.current)
    post.set("contact", novus.models.authModel.state.currentUser.getEmail())

    var acl = new AV.ACL()
    acl.setReadAccess(novus.models.authModel.state.currentUser, true)
    acl.setWriteAccess(novus.models.authModel.state.currentUser, true)
    await post.save()

    setFeedbackOpen(false)
    message.info("多谢您的反馈！")
  }, [feedbackRef])

  return (
    <>
      <Paper
        elevation={2}
        css={css`
          margin: 10px;
          li {
            margin: 0;
          }
        `}
      >
        <List>
          <ListItem button onClick={() => setAboutOpen(true)}>
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText
              primary="关于"
              secondary="了解我们开发和设计这个应用的初衷和目的"
            />
          </ListItem>
          <ListItem button onClick={() => setFeedbackOpen(true)}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="反馈" secondary="帮助把这个工具完善的更好" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://support.qq.com/product/300161"
          >
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="吐个槽" secondary="互动起来" />
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </ListItem>
        </List>
      </Paper>

      <Dialog open={aboutOpen} onClose={aboutClose}>
        <DialogTitle>UFlow / 工作流</DialogTitle>
        <DialogContent>
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText primary="这是一个工作套件，还没有完成的一个工作流，目的是帮你梳理想法、管理进度、沉淀思想，让你能够在半夜点着台灯把你的思考变成美丽的文字的东西。" />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="比如待办列表、收藏夹、笔记、订阅和阅读等，都是让你更专注于你要做的，把你的价值沉淀出来。" />
            </ListItem>
            <ListSubheader>/* ⛔️ 注意 ⛔️ */</ListSubheader>
            <ListItem disableGutters>
              <ListItemText
                primary="
              目前应用处于自用阶段，但是开放了注册功能，无法承诺任何功能的可用性和正常使用；
              即使在设计的过程中采用了很多的备份和防丢失措施，但是仍然不能保证您的数据不会丢失，不过自己在用，会尽可能的避免这种情况发生；
              应用现阶段的设计并不是最终设计，以后大概率会改动；
              应用现阶段是免费的，但是以后不排除收费或者会员；
              应用的设计、灵感和程序都是微不足道的，但是请不要抄袭、剽窃和破解。"
              />
            </ListItem>
            <ListSubheader>/* 💡 联系我 💡 */</ListSubheader>
            <ListItem disableGutters>
              <ListItemText primary="如果您有任何好的建议和想法可以直接反馈，或者发送邮件：findme&lt;at&gt;ubug.io" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={aboutClose} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={feedbackOpen} onClose={feedbackClose}>
        <DialogTitle>反馈和建议</DialogTitle>
        <DialogContent>
          <DialogContentText>
            一个好的应用应该是善于聆听和快速迭代的，所以您的建议非常重要：
          </DialogContentText>
          <TextareaAutosize
            autoFocus
            rowsMin={3}
            placeholder="请输入您的反馈建议"
            css={css`
              width: 100%;
              background: #313131;
              color: #fff;
            `}
            onChange={e => {
              feedbackRef.current = e.target.value
            }}
          />
          <DialogContentText>
            您也可以直接前往讨论站，一起讨论：
            <a href="https://support.qq.com/product/300161">吐个槽</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={feedbackClose} color="primary">
            关闭
          </Button>
          <Button onClick={submitFeedback} color="primary" variant="contained">
            提交
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})
