import React, { useCallback, useState, useRef } from "react"
import { css } from "@emotion/core"
import { oxHexToRgb } from "../../utils/colors"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import Switch from "@material-ui/core/Switch"
import { TodoCatType } from "src/lib/LCTypes"
import Link from "@material-ui/core/Link"
import { NewForm, NewFormProps, FieldTypes } from "src/components/new-form"
import { todoModel } from "src/models"
import useMessage from "src/utils/message"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"

type CatCardsProps = {
  cats: TodoCatType[]
  checkeds: string[]
  checkAll: boolean
  handleToggle: (id: string, checked: boolean) => void
  toggleAll: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

const NewTodoCat = NewForm as React.ComponentType<NewFormProps<TodoCatType>>

export const CatCards = React.memo(
  ({ cats, checkeds, checkAll, handleToggle, toggleAll }: CatCardsProps) => {
    const message = useMessage()

    const [createVisible, setCreateShow] = useState(false)
    const currentEditRef = useRef<TodoCatType>()
    const editBookMark = useCallback((bookmark: TodoCatType) => {
      currentEditRef.current = bookmark
      setCreateShow(true)
    }, [currentEditRef])
    const newBookMark = useCallback(() => {
      currentEditRef.current = null
      setCreateShow(true)
    }, [currentEditRef])
    const onNewOrEdit = useCallback(
      async json => {
        json.cat = {
          __type: "Pointer",
          className: "bookmarks_cat",
          objectId: json.cat,
        }
        if (currentEditRef.current == null) {
          await todoModel.actions.createTodoCat(json)
          message.success("创建成功")
          const targetCat = cats.find(c => c.objectId == json.cat.objectId)
          console.log(json.cat.objectId)
          console.log(cats)
          console.log(targetCat)
        } else {
          await todoModel.actions.updateTodoCat(currentEditRef.current, json)
          message.success("更新成功")
        }
      },
      [cats, currentEditRef]
    )

    return (
      <List
        css={css`
          width: 100%;
          li {
            margin: 0;
          }
        `}
        subheader={
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 16px;
            `}
          >
            <ListSubheader>分类</ListSubheader>
            <Switch
              edge="end"
              onChange={toggleAll}
              checked={checkAll}
              size="small"
              color="primary"
              css={css`
                padding-right: 16px;
              `}
            />
          </div>
        }
      >
        {cats.map((cat, i) => {
          return (
            <ListItem key={cat.objectId}>
              <ListItemIcon
                css={css`
                  margin-right: 3px;
                  min-width: 0;
                `}
              >
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={checkAll || checkeds.indexOf(cat.objectId) !== -1}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    handleToggle(cat.objectId, checked)
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={cat.name} />
              <ListItemSecondaryAction>
                <span
                  css={css`
                    width: 1em;
                    height: 1em;
                    display: block;
                  `}
                  style={{
                    background: oxHexToRgb(cat.color),
                  }}
                ></span>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
        <div
          css={css`
            text-align: center;
            margin: 20px 0;
          `}
        >
          <Button size="small" variant="text" onClick={newBookMark}>
            + 添加新分类
          </Button>
        </div>
        <NewTodoCat
          visible={createVisible}
          setVisible={setCreateShow}
          onConfirm={onNewOrEdit}
          title={currentEditRef.current ? "编辑分类" : "新分类"}
          fields={[
            {
              type: FieldTypes.TEXT,
              name: "name",
              label: "名称",
            },
            {
              type: FieldTypes.TEXT,
              name: "color",
              label: "颜色（0xffaa33ff）",
            },
            // {
            //   type: FieldTypes.TEXT,
            //   name: "icon",
            //   label: "图标（57445）",
            // },
          ]}
          editing={currentEditRef.current}
          setEditing={(target: TodoCatType) => {
            currentEditRef.current = target
          }}
        />
      </List>
    )
  }
)
