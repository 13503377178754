import React from 'react';
import NavigationRefresh from '@material-ui/icons/Refresh';

type PullToRefreshProps = {
  children: React.ReactNode,
  dragMultiplier: number;
  indicatorSize: number;
  maxDrag: number;
  onRefresh?: (value?: void) => PromiseLike<void>;
  style: React.CSSProperties,
  topOffset: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type PullToRefreshState = {
  busy: boolean,
  touchStart: React.Touch,
  drag: number,
  dragging: boolean,
}

/**
 * A component that can be pulled in order to refresh content.
 */
export default class PullToRefresh extends React.Component<PullToRefreshProps, PullToRefreshState> {
  el: HTMLDivElement
  static defaultProps: PullToRefreshProps = {
    children: null,
    dragMultiplier: 0.75,
    indicatorSize: 40,
    maxDrag: 150,
    style: {},
    topOffset: 0,
  };
  constructor(p: PullToRefreshProps) {
    super(p)
    this.state = {
      busy: false,
      touchStart: null,
      drag: 0,
      dragging: false,
    };
  }

  onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (this.props.onRefresh && !this.state.busy && this.el.scrollTop <= this.props.topOffset) {
      this.setState({
        touchStart: event.touches[0],
      });
    }
  };

  onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!this.state.touchStart) {
      return;
    }
    const drag = Math.max(0, event.touches[0].clientY - this.state.touchStart.clientY);
    if (drag !== 0) {
      this.setState({
        drag: Math.min(drag, this.props.maxDrag),
        dragging: true,
      });
    } else if (!this.state.dragging) {
      this.reset();
    }
  };

  onTouchEnd = () => {
    const { maxDrag, onRefresh } = this.props;
    if (!this.state.busy) {
      if (this.state.drag >= maxDrag * 0.9) {
        this.setState({
          busy: true,
          drag: this.props.maxDrag / 2,
          touchStart: null,
        }, () => {
          if(onRefresh) onRefresh().then(this.reset, this.reset);
        });
      } else {
        this.reset();
      }
    }
  };

  reset = () => {
    this.setState({
      busy: false,
      touchStart: null,
      drag: 0,
      dragging: false,
    });
  };

  render() {
    const {
      children,
      dragMultiplier,
      indicatorSize,
      maxDrag,
      onRefresh,
      style,
      topOffset,
      ...props
    } = this.props;
    const drag = this.state.drag * dragMultiplier;
    return (
      <div
        {...props}
        style={{
          ...style,
          overflowY: this.state.dragging ? 'hidden' : 'scroll',
        }}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
        ref={ref => this.el = ref}
      >
        {!this.state.busy && <div
          style={{
            position: 'relative',
            margin: `0 auto ${-indicatorSize}px`,
            top: -indicatorSize,
            paddingTop: drag,
            width: indicatorSize,
            height: indicatorSize,
            boxSizing: 'content-box',
            opacity: drag > 0 ? 1 : 0,
          }}
        >
          <NavigationRefresh style={{
            width: '100%',
            height: '100%',
            transition: null,
            transform: `rotate(${drag}deg)`,
          }}
          />
        </div>}

        {children}
      </div>
    );
  }
}