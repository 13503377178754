import React, { useCallback, useState, useEffect } from "react"
import { css } from "@emotion/core"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import Chip from "@material-ui/core/Chip"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"

type ConfirmInputProps = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: (value: string) => Promise<void>
  title: string
  defaultValue: string
  withDelete?: () => void
}

export const ConfirmInput = React.memo(
  ({
    visible,
    setVisible,
    onConfirm,
    title,
    defaultValue = "",
    withDelete,
  }: ConfirmInputProps) => {
    const [value, setValue] = useState("")
    const confirmOK = useCallback(async () => {
      await onConfirm(value)
      setVisible(false)
    }, [value])

    useEffect(() => {
      setValue(defaultValue)
    }, [defaultValue])

    const confirmDelete = useCallback(() => {
      withDelete()
      setVisible(false)
    }, [withDelete])

    return (
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogTitle>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <span>{title || "输入目标值"}</span>
            {withDelete && (
              <Chip
                size="small"
                label="直接删除"
                onClick={confirmDelete}
                icon={<DeleteForeverIcon />}
                variant="outlined"
              />
            )}
          </div>
        </DialogTitle>
        <DialogContent
          css={css`
            width: 360px;
          `}
        >
          <TextField
            defaultValue={defaultValue}
            type="text"
            onChange={e => {
              setValue(e.target.value)
            }}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            取消
          </Button>
          <Button onClick={() => confirmOK()} color="secondary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
