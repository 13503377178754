import React, { useState, useCallback, useRef, useMemo, useEffect } from "react"
import { css } from "@emotion/core"

import IconButton from "@material-ui/core/IconButton"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import SyncIcon from "@material-ui/icons/Sync"

import { FeedCard } from "./rss/feed-card"
import { Channels } from "./rss/cat-card"

import MainContent from "../components/Main"
import { RSSChannelType } from "src/lib/LCTypes"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import { NewForm, NewFormProps, FieldTypes } from "src/components/new-form"
import useMessage from "src/utils/message"
import Loading from "src/components/loading"
import { useNovus } from "src/lib/novus"
import { rssModel } from "src/models"
import { NewRssDialog } from "./rss/new-rss"
import Empty from "src/components/empty"

const NewRSS = NewForm as React.ComponentType<NewFormProps<RSSChannelType>>

export const RssReader = React.memo(() => {
  const message = useMessage()

  let [
    channels,
    filterChannel,
    filterKeyword,
    channelsLoading,
    feedsLoading,
    feedsRefreshing,
    feedsLoadedAll,
    feeds,
  ] = useNovus(
    models => {
      return [
        models.rssModel.state.channels,
        models.rssModel.state.filterChannel,
        models.rssModel.state.filterKeyword,
        models.rssModel.state.channelsLoading,
        models.rssModel.state.feedsLoading,
        models.rssModel.state.feedsRefreshing,
        models.rssModel.state.feedsLoadedAll,
        models.rssModel.state.feeds,
      ]
    },
    ["rssModel"]
  )

  useEffect(() => {
    rssModel.actions.refresh()
  }, [])

  const mainContentRef = useRef<HTMLDivElement>()

  const [addOpen, setAddOpen] = useState(false)
  const newChannel = useCallback(() => {
    setAddOpen(true)
  }, [])

  const [createVisible, setCreateShow] = useState(false)

  const currentEditRef = useRef<RSSChannelType>()
  const editChannel = useCallback((channel: RSSChannelType) => {
    currentEditRef.current = channel
    setCreateShow(true)
  }, [currentEditRef])

  const [manualTab, setManualTab] = useState("")
  const [manualLink, setManualLink] = useState("")
  const [manualTitle, setManualTitle] = useState("")
  const [manualDesc, setManualDesc] = useState("")
  const [manualLinkHtmlUsePreset, setManualLinkHtmlUsePreset] = useState("")

  const manualChannel = useCallback(
    (
      link: string = "",
      title: string = "",
      desc: string = "",
      tab: string = "RSS",
      htmlUsePreset: string = "-"
    ) => {
      currentEditRef.current = null

      setManualLink(link)
      setManualTitle(title)
      setManualDesc(desc)

      setAddOpen(false)
      setCreateShow(true)

      setManualTab(tab)
      setManualLinkHtmlUsePreset(htmlUsePreset)

      console.log(manualLink, manualTitle, manualDesc)
    },
    [currentEditRef]
  )

  const handleEdit = useCallback(async json => {
    json.cat = {
      __type: "Pointer",
      className: "rss_cats",
      objectId: json.cat,
    }
    if (currentEditRef.current == null) {
      await rssModel.actions.createChannel(json)
      message.success("创建成功")
    } else {
      await rssModel.actions.updateChannel(currentEditRef.current, json)
      message.success("更新成功")
    }
  }, [currentEditRef])

  const [deleteVisible, setDeleteShow] = useState(false)
  const currentDelRef = useRef<RSSChannelType>()

  const handleDel = useCallback(async () => {
    await rssModel.actions.deleteChannel(currentEditRef.current)
    currentDelRef.current = null
    message.success("删除成功")
  }, [currentEditRef, currentDelRef])

  const feedsComponent = useMemo(() => {
    return feeds.map((feed, i) => {
      return <FeedCard key={feed.objectId} feed={feed} />
    })
  }, [feeds])

  const breadcrumb = useMemo(() => {
    return (
      <h4
        css={css`
          height: 40px;
          margin: 0 21px;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-weight: bold !important;
            margin-bottom: 0;
          }
        `}
      >
        <Breadcrumbs>
          <Link
            component="span"
            onClick={() => rssModel.actions.updateFilter("", "")}
          >
            全部内容
          </Link>
          {filterKeyword && (
            <Link
              onClick={() =>
                rssModel.actions.updateFilter("keyword", filterKeyword)
              }
              component="span"
            >
              {filterKeyword}
            </Link>
          )}
          {filterChannel && <Link component="span">{filterChannel.title}</Link>}
        </Breadcrumbs>
      </h4>
    )
  }, [filterKeyword, filterChannel])

  const loadingMore = useRef(false)
  const onReachBottom = useCallback(async () => {
    if (!loadingMore.current) {
      loadingMore.current = true
      console.log("onReachBottom")

      await rssModel.actions.nextFeedPage()

      console.log("onReachBottom fetch ok")

      loadingMore.current = false
    }
  }, [loadingMore])

  return (
    <MainContent
      title="RSS 订阅"
      titleRights={[
        <IconButton key="new" onClick={newChannel}>
          <AddCircleOutlineIcon />
        </IconButton>,
        <IconButton
          key="refresh"
          onClick={() => {
            rssModel.actions.refresh()
          }}
          disabled={channelsLoading || feedsLoading}
        >
          <SyncIcon />
        </IconButton>,
      ]}
      sideLoading={channelsLoading}
      mainLoading={false}
      side={
        <Channels
          updateChannelEditing={editChannel}
          onDeleteChannel={channel => {
            currentDelRef.current = channel
            setDeleteShow(true)
          }}
        />
      }
      onReachBottom={onReachBottom}
      onRefresh={rssModel.actions.refreshFeeds}
    >
      {feedsRefreshing && <Loading size={30} title="加载中..." />}

      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentDelRef.current ? currentDelRef.current.title : ""}
        onConfirm={handleDel}
      />

      <NewRssDialog
        addOpen={addOpen}
        setAddOpen={setAddOpen}
        manualChannel={manualChannel}
      />

      <NewRSS
        visible={createVisible}
        setVisible={setCreateShow}
        onConfirm={handleEdit}
        title={currentEditRef.current ? "编辑订阅内容" : "手动订阅新的内容"}
        tabs={{
          RSS: "标准 RSS 协议",
          HTML: "网页抓取解析",
        }}
        defaultTab={
          currentEditRef.current
            ? currentEditRef.current.type.toUpperCase()
            : manualTab
              ? manualTab
              : "RSS"
        }
        fields={[
          {
            type: FieldTypes.TEXT_SELECT_MULTIPLE,
            name: "keywords",
            options: channels.reduce<{ [ind: string]: string }>((ret, val) => {
              val.keywords.forEach(v => (ret[v] = v))
              return ret
            }, {}),
            getOptionLabel: (option: string) => {
              return option
            },
            defaultValue: currentEditRef.current
              ? currentEditRef.current.keywords
              : [],
            label: "关键词",
          },
          {
            type: FieldTypes.SELECT,
            name: "htmlUsePreset",
            whenTab: "HTML",
            options: {
              "-": "不使用预设解析器",
              segmentdefault_channel: "SegmentDefault 专栏解析器",
              segmentdefault_blog: "SegmentDefault 博客解析器",
              ershicimi: "二十次幂解析器（公众号）",
            },
            defaultValue: manualLinkHtmlUsePreset,
            label: "html 预设解析器(网页抓取解析有效)",
          },
          {
            type: FieldTypes.TEXT,
            name: "rssLink",
            label: "链接",
            defaultValue: manualLink,
            autofocus: true,
          },
          {
            type: FieldTypes.TEXT,
            name: "title",
            label: "标题",
            defaultValue: manualTitle,
          },
          {
            type: FieldTypes.TEXT,
            name: "color",
            label: "主题色",
          },
          {
            type: FieldTypes.TEXT,
            name: "description",
            label: "描述",
            defaultValue: manualDesc,
          },
        ]}
        editing={currentEditRef.current}
        setEditing={(target: RSSChannelType) => {
          currentEditRef.current = target
        }}
      />

      {feeds.length > 0 && breadcrumb}

      <div ref={mainContentRef}>
        {feedsComponent}

        {!feedsRefreshing && feedsLoading && (
          <Loading size={30} title="加载中..." />
        )}

        {feedsLoadedAll &&
          (feeds.length === 0 ? (
            <Empty title="没有订阅内容~" />
          ) : (
              <div
                css={css`
                text-align: center;
                padding: 20px 0;
              `}
              >
                全部加载成功
              </div>
            ))}
      </div>
    </MainContent>
  )
})
