import React, { useState, useCallback, useEffect, useMemo } from "react"
import { css } from "@emotion/core"
import Paper from "@material-ui/core/Paper"
import { FieldTypes, NewForm, NewFormProps } from "src/components/new-form"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import DeleteIcon from "@material-ui/icons/Delete"
import useMessage from "src/utils/message"
import { UserProfileType } from "src/lib/LCTypes"
import Button from "@material-ui/core/Button"
import { useNovus, novus } from "src/lib/novus"
import AV from "leancloud-storage"
import Avatar from "@material-ui/core/Avatar"
import { authModel } from "../../models/"
import { Pro } from "src/components/pro"
import { colorFromString, invertColor } from "src/utils/utils"
import Hidden from "@material-ui/core/Hidden"

const NewProfileSetting = NewForm as React.ComponentType<
  NewFormProps<UserProfileType>
>

export const ProfileSetting = React.memo(() => {
  const message = useMessage()

  const [userProfile, setUserProfile] = useState<UserProfileType>({
    username: "",
    email: "",
    mobilePhoneNumber: "",
  })

  let [currentUser, username, email, mobilePhoneNumber] = useNovus<
    [AV.User, string, string, string]
  >(
    models => {
      const currentUser = models.authModel.state.currentUser
      return [
        currentUser,
        currentUser ? currentUser.getUsername() : "",
        currentUser ? currentUser.getEmail() : "",
        currentUser ? currentUser.getMobilePhoneNumber() : "",
      ]
    },
    ["authModel"]
  )

  useEffect(() => {
    setUserProfile({
      username: currentUser.getUsername(),
      email: currentUser.getEmail(),
      mobilePhoneNumber: currentUser.getMobilePhoneNumber(),
    })
  }, [username, email, mobilePhoneNumber])

  const [profileOpen, setProfileOpen] = React.useState(false)
  const handleProfileOpen = useCallback(() => {
    setProfileOpen(true)
  }, [])

  const onProfileKeys = useCallback(
    async (attrs: UserProfileType) => {
      currentUser.setEmail(attrs.email)
      currentUser.setUsername(attrs.username)
      currentUser.setMobilePhoneNumber(attrs.mobilePhoneNumber)
      const disposer = message.info("正在更新")
      await currentUser.save()
      await authModel.actions.update()
      disposer()
      message.info("更新成功")
    },
    [currentUser, userProfile]
  )

  const quitAuth = useCallback(() => {
    authModel.actions.quit()
    novus.models.authModel.actions.setMode("LOGIN")
  }, [])

  const avatarCss = useMemo(() => {
    return css`
      background: ${colorFromString(username)};
      color: ${invertColor(colorFromString(username))};
    `
  }, [username])
  const avatarL = useMemo(() => {
    return username.slice(0, 1).toUpperCase()
  }, [username])

  return (
    <>
      <Hidden smUp>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
          `}
        >
          <Avatar
            css={avatarCss}
            style={{ fontSize: 40, width: 80, height: 80 }}
          >
            {avatarL}
          </Avatar>
        </div>
      </Hidden>

      <Paper
        elevation={2}
        css={css`
          margin: 10px;
          li {
            margin: 0;
          }
        `}
      >
        <List
          css={css`
            width: 100%;
            maxwidth: 360px;
          `}
        >
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <Avatar css={avatarCss}>{avatarL}</Avatar>
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary={
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <div>{username}</div>
                  <Pro isPro={false} />
                </div>
              }
              secondary={[
                currentUser?.getEmail(),
                currentUser?.getMobilePhoneNumber(),
              ]
                .filter(v => v !== "")
                .join(", ")}
            />
            <ListItemSecondaryAction>
              <Button
                color="primary"
                onClick={handleProfileOpen}
                size="small"
                css={css`
                  margin-right: 10px;
                `}
              >
                编辑
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={quitAuth}
                size="small"
              >
                退出登录
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>

      <NewProfileSetting
        visible={profileOpen}
        setVisible={setProfileOpen}
        onConfirm={onProfileKeys}
        title="修改个人信息"
        desc="当前仅开放邮箱登录，用户名/邮箱/手机号唯一"
        fields={[
          {
            type: FieldTypes.TEXT,
            name: "username",
            label: "用户名",
          },
          {
            type: FieldTypes.TEXT,
            name: "email",
            label: "邮箱",
          },
          {
            type: FieldTypes.TEXT,
            name: "mobilePhoneNumber",
            label: "手机号",
          },
        ]}
        editing={userProfile}
        setEditing={(target: UserProfileType) => {
          // currentCosRef.current = target
        }}
      />
    </>
  )
})
