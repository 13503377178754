import React, {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useContext,
} from "react"
import { css } from "@emotion/core"

import useMessage from "src/utils/message"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "@material-ui/core"
import { parseRss, parsePage } from "src/utils/urlParse"

import howEscm from "../../images/how-escm.png"
import openPage from "src/utils/openPage"

type NewRssDialog = {
  addOpen: boolean
  setAddOpen: Dispatch<SetStateAction<boolean>>
  manualChannel: (
    link?: string,
    title?: string,
    desc?: string,
    tab?: string,
    htmlUsePreset?: string
  ) => void
}

export const NewRssDialog = React.memo(
  ({ addOpen, setAddOpen, manualChannel }: NewRssDialog) => {
    const message = useMessage()

    const [entry, setEntry] = useState("") // rss, escm, sf

    const choose = useCallback(_entry => {
      setAddOpen(false)
      setEntry(_entry)
    }, [])
    const cancelEntry = useCallback(() => {
      setAddOpen(true)
      setEntry("")
    }, [])

    const [rssAddress, setRssAddress] = useState("")
    const decodeRss = useCallback(
      async _ => {
        try {
          const result = await parseRss(rssAddress)
          manualChannel(rssAddress, result.title, result.desc)
          setEntry("")
          setRssAddress("")
          setAddOpen(false)
        } catch (error) {
          console.log(error)
          message.error("抱歉，解析错误~~")
        }
      },
      [rssAddress]
    )

    const [escmAddress, setEscmAddress] = useState("")
    const confirmEscm = useCallback(
      async _ => {
        try {
          const result = await parsePage(escmAddress)
          manualChannel(
            escmAddress,
            result.title,
            result.desc,
            "HTML",
            "ershicimi"
          )
          setEntry("")
          setEscmAddress("")
          setAddOpen(false)
        } catch (error) {
          console.log(error)
          message.error("抱歉，解析错误~~")
        }
      },
      [escmAddress]
    )

    const [sfAddress, setSfAddress] = useState("")
    const confirmSf = useCallback(
      async _ => {
        try {
          const result = await parsePage(sfAddress)
          manualChannel(
            sfAddress,
            result.title,
            result.desc,
            "HTML",
            /\.(com|net)\/blog/.test(sfAddress)
              ? "segmentdefault_blog"
              : "segmentdefault_channel"
          )
          setEntry("")
          setSfAddress("")
          setAddOpen(false)
        } catch (error) {
          console.log(error)
          message.error("抱歉，解析错误~~")
        }
      },
      [sfAddress]
    )

    return (
      <div>
        <Dialog
          open={addOpen}
          onClose={() => setAddOpen(false)}
          css={css`
            .MuiButtonBase-root {
              margin: 3px 10px;
              flex: 1;
              padding: 5px 13px;
              border-radius: 6px;
            }
            .MuiButton-label {
              display: block;
            }
            h3 {
              margin: 5px 0;
            }
            p {
              margin: 5px 0;
            }
            .rss-dialog button {
              margin: 4px;
            }
          `}
        >
          <DialogTitle>添加新的订阅</DialogTitle>
          <DialogContent className="rss-dialog">
            <p>
              仅抓取订阅目标的标题和链接信息，没有全文抓取功能。订阅列表每天凌晨更新，而且公众号源内容存在延迟，所以内容列表存在延迟。
            </p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => choose("rss")}
            >
              <h3>RSS</h3>
              <p>标准 RSS 链接</p>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => choose("escm")}
            >
              <h3>公众号</h3>
              <p>“二十次幂”源</p>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => choose("sf")}
            >
              <h3>思否</h3>
              <p>个人文章 / 专栏</p>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => manualChannel()}
            >
              <h3>手动</h3>
              <p>手动录入信息</p>
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddOpen(false)} color="primary">
              取消
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={entry === "rss"} onClose={cancelEntry}>
          <DialogTitle>RSS 订阅源</DialogTitle>
          <DialogContent>
            <p
              css={css`
                a {
                  text-decoration: none;
                }
              `}
            >
              订阅源推荐{" "}
              <a href="https://github.com/tenpiece/wxrss" target="_blank">
                瓦斯阅读
              </a>{" "}
              /{" "}
              <a href="https://getpodcast.xyz/" target="_blank">
                播客 Feed 订阅
              </a>{" "}
              /{" "}
              <a
                href="https://github.com/timqian/chinese-independent-blogs"
                target="_blank"
              >
                中文独立博客列表
              </a>
            </p>

            <TextField
              placeholder="RSS 链接地址"
              css={css`
                flex: 1;
              `}
              variant="outlined"
              fullWidth
              value={rssAddress}
              onChange={e => setRssAddress(e.target.value)}
            />
            <DialogActions>
              <Button onClick={cancelEntry} color="primary">
                取消
              </Button>
              <Button onClick={decodeRss} color="primary" variant="contained">
                解析
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <Dialog open={entry === "escm"} onClose={cancelEntry}>
          <DialogTitle>公众号 - “二十次幂”源</DialogTitle>
          <DialogContent>
            <div
              css={css`
                a {
                  text-decoration: none;
                }
              `}
            >
              <p>
                “微信公众号”内容推送无法订阅，我们通过“二十次幂”的页面解析获取内容链接，仅收集链接，不作任何内容传播，不对内容负责，如有侵权将会删除，请知悉。
              </p>
              <p>
                订阅方式请到{" "}
                <a
                  href="https://www.ershicimi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    openPage("https://www.ershicimi.com/", e)
                  }}
                >
                  二十次幂
                </a>
                注册账号，然后搜索想要的账号，获得类似的连接:{" "}
                <code>https://www.ershicimi.com/a/xxxxxxxx</code>，
                拷贝到下面输入框
              </p>
              <p>
                <a href={howEscm} target="_blank">
                  <img
                    src={howEscm}
                    alt="二十次幂步骤"
                    css={css`
                      max-height: 100px;
                    `}
                  />
                </a>
              </p>
            </div>

            <TextField
              placeholder="订阅链接地址"
              css={css`
                flex: 1;
              `}
              variant="outlined"
              fullWidth
              value={escmAddress}
              onChange={e => setEscmAddress(e.target.value)}
            />
            <DialogActions>
              <Button onClick={cancelEntry} color="primary">
                取消
              </Button>
              <Button onClick={confirmEscm} color="primary" variant="contained">
                确定
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <Dialog open={entry === "sf"} onClose={cancelEntry}>
          <DialogTitle>思否 - 个人文章 / 专栏</DialogTitle>
          <DialogContent>
            <div
              css={css`
                a {
                  text-decoration: none;
                }
              `}
            >
              <p>
                “思否”平台目前无法订阅某个专栏或者用户的文章列表，这里使用抓取的方式获取内容。
              </p>
              <p>
                订阅方式请到{" "}
                <a
                  href="https://segmentfault.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    openPage("https://segmentfault.com/", e)
                  }}
                >
                  SegmentFault
                </a>
                注册账号，然后找到目标用户专栏列表或者频道列表，获得类似的连接:{" "}
                <code>https://segmentfault.com/blog/xxxxx</code>，
                <code>https://segmentfault.com/channel/xxxxx</code>，
                拷贝到下面输入框
              </p>
            </div>

            <TextField
              placeholder="订阅链接地址"
              css={css`
                flex: 1;
              `}
              variant="outlined"
              fullWidth
              value={sfAddress}
              onChange={e => setSfAddress(e.target.value)}
            />
            <DialogActions>
              <Button onClick={cancelEntry} color="primary">
                取消
              </Button>
              <Button onClick={confirmSf} color="primary" variant="contained">
                确定
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
)
