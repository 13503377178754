import { NovusBaseModel } from "../lib/novus"
import AV from "leancloud-storage"
import { TimeMachineType } from "src/lib/LCTypes"
import {
  updateAVinList,
  deleteAVinList,
  createAVinList,
} from "src/lib/av-models"

export interface ITimeMachineState {
  loading: boolean
  page: number
  pageSize: number
  records: TimeMachineType[]
  loadedAll: boolean
}

// 使用继承类的形式限制属性和方法
export class TimeMachineModel extends NovusBaseModel<ITimeMachineState> {
  namespace = "timeMachineModel"
  constructor() {
    super()
    this.state = {
      loading: false,
      page: 1,
      pageSize: 20,
      records: [],
      loadedAll: false,
    }
  }
  actions = {
    refresh: async () => {
      this.setState({ page: 1, loadedAll: false, records: [] })
      await this.actions.fetchRecords()
    },
    fetchRecords: async () => {
      this.setState({ loading: true })
      const query = new AV.Query<AV.Object>("time_machine")
      query.descending("createdAt")

      query.skip((this.state.page - 1) * this.state.pageSize)
      query.limit(this.state.pageSize)

      const result = await query.find()

      if (result.length === 0) {
        this.setState({
          loadedAll: true,
          loading: false,
        })
      } else {
        this.setState({
          records: this.state.records.concat([...result.map(r => r.toJSON())]),
          loading: false,
        })
      }
    },
    nextPage: async () => {
      if (!this.state.loadedAll) {
        this.setState({ page: this.state.page + 1 })
        this.actions.fetchRecords()
      }
    },
    deleteRecord: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList(
        "time_machine",
        plain,
        this.state.records
      )
      this.setState({ records: newList })
    },
    updateRecord: async (
      plain: TimeMachineType,
      attrs: Partial<TimeMachineType>
    ) => {
      const newList = await updateAVinList(
        "time_machine",
        plain,
        attrs,
        this.state.records
      )
      this.setState({ records: newList })
      return this.state.records.find(v => v.objectId === plain.objectId)
    },
    createRecord: async (
      attrs: Partial<TimeMachineType>
    ): Promise<TimeMachineType> => {
      const newList = await createAVinList(
        "time_machine",
        attrs,
        this.state.records
      )
      this.setState({ records: newList })
      return this.state.records[0]
    },
  }
}
