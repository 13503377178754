import React, { useState, useCallback, useRef, useEffect } from "react"
import { css } from "@emotion/core"

import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import { formData2json } from "src/utils/utils"
import { parsePage } from "src/utils/urlParse"
import DialogContentText from "@material-ui/core/DialogContentText"
import useMessage from "src/utils/message"
import Card from "@material-ui/core/Card"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { array } from "prop-types"

export enum FieldTypes {
  TEXT = "text",
  SELECT = "select",
  TEXT_SELECT = "text_select",
  TEXT_SELECT_MULTIPLE = "text_select_multiple",
  TEXT_AREA = "textarea",
}

type FieldTextAreaType<T> = {
  placeholder: string
  name: keyof T
  whenTab?: string
  autofocus?: boolean
} & {
  type: FieldTypes.TEXT_AREA
  defaultValue?: string
}
type FieldTextType<T> = {
  label: string
  name: keyof T
  whenTab?: string
  autofocus?: boolean
} & {
  type: FieldTypes.TEXT
  defaultValue?: string
}
type FieldSELECTType<T> = {
  label: string
  name: keyof T
  whenTab?: string
  autofocus?: boolean
} & {
  type: FieldTypes.SELECT
  options: { [val: string]: string }
  defaultValue?: string
}
type FieldTextSelectMultipleType<T> = {
  label: string
  name: keyof T
  whenTab?: string
  autofocus?: boolean
} & {
  type: FieldTypes.TEXT_SELECT_MULTIPLE
  options: { [val: string]: string }
  getOptionLabel?: (option: string) => string
  defaultValue?: string[]
}

type Field<T> =
  | FieldTextAreaType<T>
  | FieldTextType<T>
  | FieldSELECTType<T>
  | FieldTextSelectMultipleType<T>

export type NewFormProps<T> = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>

  tabs?: { [ind: string]: string }
  defaultTab?: string

  title: string
  desc?: string
  fields: Field<T>[]

  onConfirm: (attrs: { [ind: string]: any }) => Promise<void>

  autoDecodeUrl?: {
    from: keyof T
    to: [keyof T, keyof T] | [keyof T, keyof T, keyof T]
  }
  fillFromQueryParams?: {
    [ind: string]: keyof T
  }

  editing: T
  setEditing: React.Dispatch<T>
}

const NewFormComponent = function <T>({
  visible,
  setVisible,
  onConfirm,
  tabs,
  defaultTab,
  title,
  desc,
  fields,
  editing,
  setEditing,
  autoDecodeUrl,
  fillFromQueryParams,
}: NewFormProps<T>) {
  const message = useMessage()

  const [tabNow, setTabNow] = useState("")
  useEffect(() => {
    if (tabs && Object.keys(tabs).length > 0) {
      setTabNow(defaultTab || Object.keys(tabs)[0])
    }
  }, [tabs, defaultTab])

  // 从 url 中解析初始参数
  useEffect(() => {
    var params = new URLSearchParams(location.search)
    if (params.has("url")) {
      setTimeout(() => {
        for (const key in fillFromQueryParams) {
          if (Object.prototype.hasOwnProperty.call(fillFromQueryParams, key)) {
            formKey(
              fillFromQueryParams[key],
              decodeURIComponent(params.get(key)) || ""
            )
            console.log(
              fillFromQueryParams[key],
              decodeURIComponent(params.get(key)) || ""
            )
          }
        }
      }, 10)
    }
  }, [fillFromQueryParams])

  const checkNew = useCallback(
    async event => {
      event.preventDefault()
      const form = event.target
      const data = new FormData(form)
      const json = formData2json(data)
      await onConfirm(json)
      setVisible(false)
      setEditing(null)
    },
    [editing]
  )

  const formRef = useRef<HTMLFormElement>(null)
  const [decoding, setDecoding] = useState(false)
  const formKey = useCallback((key: keyof T, value?: any) => {
    const keyInd = fields.findIndex(f => f.name === key)
    if (formRef.current && formRef.current.elements[keyInd]) {
      const ele = formRef.current.elements[keyInd] as HTMLInputElement
      if (ele) {
        if (value !== undefined) {
          ele.value = value
        }
        return ele.value
      }
    }
    return null
  }, [formRef])
  const decodeUrl = useCallback(async () => {
    setDecoding(true)
    const url = formKey(autoDecodeUrl.from)
    if (url !== "") {
      const result = await parsePage(url)

      formKey(autoDecodeUrl.to[0], result.title)
      formKey(autoDecodeUrl.to[1], result.desc)
      if (autoDecodeUrl.to.length > 2) formKey(autoDecodeUrl.to[2], result.icon)

      message.success("解析成功")
    } else {
      message.warning("不能为空")
    }
    setDecoding(false)
  }, [autoDecodeUrl])

  return (
    <Dialog open={visible} onClose={() => setVisible(false)}>
      <DialogTitle>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>{title}</div>
          {autoDecodeUrl && (
            <Button onClick={decodeUrl} color="primary" disabled={decoding}>
              自动解析
            </Button>
          )}
        </div>
      </DialogTitle>
      <form noValidate autoComplete="off" onSubmit={checkNew} ref={formRef}>
        <DialogContent>
          {desc && <DialogContentText>{desc}</DialogContentText>}
          <div
            css={css`
              margin: 0 -10px 10px;
              display: flex;
              .MuiButton-label {
                display: block;
              }
              h3 {
                margin: 5px 0;
              }
              p {
                margin: 5px 0;
              }
            `}
          >
            {tabs &&
              Object.keys(tabs).map(tab => (
                <Button
                  key={tab}
                  css={css`
                    margin: 0 10px;
                    flex: 1;
                    padding: 5px 13px;
                    border-radius: 6px;
                  `}
                  variant={tab === tabNow ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => setTabNow(tab)}
                >
                  <h3>{tab}</h3>
                  <p>{tabs[tab]}</p>
                </Button>
              ))}
          </div>
          {fields.map(field => {
            const formName = field.name as string

            if (field.whenTab && field.whenTab !== tabNow) {
              return null
            }

            if (field.type == FieldTypes.TEXT) {
              return (
                <TextField
                  defaultValue={
                    editing ? editing[field.name] : field.defaultValue || null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                  name={formName}
                  label={field.label}
                  type={field.type}
                  fullWidth
                  autoFocus={field.autofocus}
                  key={formName}
                />
              )
            }

            if (field.type == FieldTypes.TEXT_AREA) {
              return (
                <TextareaAutosize
                  // @ts-ignore
                  defaultValue={
                    editing ? editing[field.name] : field.defaultValue || null
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rowsMax={7}
                  rowsMin={3}
                  margin="dense"
                  name={formName}
                  placeholder={field.placeholder}
                  type={field.type}
                  autoFocus={field.autofocus}
                  css={css`
                    width: 100%;
                  `}
                  key={formName}
                />
              )
            }

            if (field.type == FieldTypes.SELECT) {
              return (
                <Selecter
                  key={formName}
                  autoFocus={field.autofocus}
                  field={field}
                  // @ts-ignore
                  defaultValue={
                    editing &&
                      editing[field.name] &&
                      typeof editing[field.name] === "string"
                      ? editing[field.name]
                      : field.defaultValue || null
                  }
                />
              )
            }

            if (field.type == FieldTypes.TEXT_SELECT_MULTIPLE) {
              return (
                <Autocomplete
                  multiple={true}
                  key={formName}
                  autoFocus={field.autofocus}
                  // @ts-ignore
                  defaultValue={
                    editing &&
                      editing[field.name] &&
                      Array.isArray(editing[field.name])
                      ? editing[field.name]
                      : field.defaultValue || null
                  }
                  options={Object.values(field.options)}
                  getOptionLabel={field.getOptionLabel}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      label={field.label}
                    />
                  )}
                />
              )
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            取消
          </Button>
          <Button type="submit" color="primary">
            {editing ? "更新" : "创建"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type SelecterProps<T> = {
  field: FieldSELECTType<T>
  defaultValue: string
}

function Selecter<T>({ field, defaultValue }: SelecterProps<T>) {
  const [newCat, setNewCat] = useState(field.defaultValue)
  useEffect(() => {
    setNewCat(defaultValue || field.defaultValue)
  }, [field, defaultValue])

  return (
    field.type == "select" && (
      <FormControl margin="dense" fullWidth>
        <InputLabel>{field.label}</InputLabel>
        <Select
          value={newCat}
          displayEmpty
          name={field.name as string}
          onChange={e => {
            // @ts-ignore
            setNewCat(e.target.value)
          }}
        >
          {Object.keys(field.options).map(opt => (
            <MenuItem value={opt} key={opt}>
              {field.options[opt]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  )
}

export const NewForm = React.memo(NewFormComponent)
