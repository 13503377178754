import React from "react"
import { css } from "@emotion/core"
import CircularProgress from "@material-ui/core/CircularProgress"

type LoadingProps = {
  title?: string
  size?: number
}

const Loading = React.memo(({ title = "Loading...", size }: LoadingProps) => (
  <div
    css={css`
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
  >
    <CircularProgress size={size} />
    {title && title !== "" && (
      <p
        css={css`
          margin: 10px 0 0 0;
        `}
      >
        {title}
      </p>
    )}
  </div>
))

export default Loading
