import { NovusBaseModel } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"
import { RSSChannelType, RSSFeedType } from "src/lib/LCTypes"
import {
  createAVinList,
  updateAVinList,
  deleteAVinList,
} from "src/lib/av-models"

export type TFilterType = "channel" | "keyword" | ""

export interface IRssState {
  filterType: TFilterType
  filter: string
  filterChannel: RSSChannelType
  filterKeyword: string

  feedPage: number
  feedPageSize: number

  channelsLoading: boolean
  feedsLoading: boolean
  feedsRefreshing: boolean
  feedsLoadedAll: boolean
  channels: RSSChannelType[]
  feeds: RSSFeedType[]
}

// 使用继承类的形式限制属性和方法
export class RssModel extends NovusBaseModel<IRssState> {
  namespace = "rssModel"
  constructor() {
    super()
    this.state = {
      filterType: "",
      filter: "",
      filterChannel: undefined,
      filterKeyword: undefined,

      feedPage: 1,
      feedPageSize: 20,
      channelsLoading: false,
      feedsLoading: false,
      feedsRefreshing: false,
      feedsLoadedAll: false,
      channels: [],
      feeds: [],
    }
  }
  message: MessageType
  actions = {
    refresh: async () => {
      this.setState({
        feeds: [],
        feedPage: 1,
        feedsLoadedAll: false,
        channelsLoading: true,
        feedsRefreshing: true,
        feedsLoading: true,
      })

      await this.actions.fetchChannels()
      await this.actions.fetchFeeds()
    },
    refreshFeeds: async () => {
      this.setState({
        feedPage: 1,
        feedsRefreshing: true,
        feedsLoadedAll: false,
        feedsLoading: true,
      })
      await this.actions.fetchFeeds()
    },
    fetchChannels: async () => {
      this.setState({ channelsLoading: true })

      const query = new AV.Query<AV.Object>("rss_subs")
      query.equalTo("user", AV.User.current())
      query.include(["channel"])
      // query.addDescending("lastBuildDate")

      const result = await query.find()

      this.setState({
        channels: [...result.map(r => r.get("channel")).map(r => r.toJSON())],
        channelsLoading: false,
      })
    },
    fetchFeeds: async () => {
      this.setState({ feedsLoading: true })

      const query = new AV.Query<AV.Object>("rss_feed")
      query.addDescending("pubDate")
      query.include(["channel"])

      // 只获取用户 sub 表关联的 channel 的 feed
      query.containedIn(
        "channel",
        this.state.channels.map(c =>
          AV.Object.createWithoutData("rss_channels", c.objectId)
        )
      )

      const { feeds, filterType, filter } = this.state

      if (filterType === "channel" && filter !== "") {
        const channel = AV.Object.createWithoutData("rss_channels", filter)
        query.equalTo("channel", channel)
      }
      if (filterType === "keyword" && filter !== "") {
        const cat = AV.Object.createWithoutData("rss_cats", filter)
        query.equalTo("cat", cat)
      }

      query.skip((this.state.feedPage - 1) * this.state.feedPageSize)
      query.limit(this.state.feedPageSize)

      const result = await query.find()
      if (result.length === 0) {
        this.setState({
          feedsLoadedAll: true,
          feedsLoading: false,
          feedsRefreshing: false,
        })
      } else {
        const old = this.state.feedPage == 1 ? [] : feeds
        this.setState({
          feeds: old.concat([...result.map(r => r.toJSON())]),
          feedsLoading: false,
          feedsRefreshing: false,
        })
      }
    },
    nextFeedPage: async () => {
      if (!this.state.feedsLoadedAll) {
        this.setState({
          feedPage: this.state.feedPage + 1,
        })
        await this.actions.fetchFeeds()
      }
    },
    updateFilter: async (type: TFilterType, id: string) => {
      const { channels } = this.state

      let filterKeyword: string = undefined
      let filterChannel: RSSChannelType = undefined

      if (id !== "") {
        if (type === "keyword") {
          // filterKeyword = cats.find(c => c.objectId === id)
        }
        if (type === "channel") {
          filterChannel = channels.find(c => c.objectId === id)
          // filterKeyword = cats.find(c => c.objectId === filterChannel.cat.objectId)
        }
      }

      this.setState({
        filterType: type,
        filter: id,
        filterKeyword,
        filterChannel,
        feeds: [],
        feedsLoadedAll: false,
        feedPage: 1,
      })
      this.actions.fetchFeeds()
    },
    createChannel: async (attrs: Partial<RSSChannelType>) => {
      const newList = await createAVinList(
        "rss_channels",
        attrs,
        this.state.channels
      )
      this.setState({ channels: newList })
      return this.state.channels[0]
    },
    updateChannel: async (
      plain: RSSChannelType,
      attrs: Partial<RSSChannelType>
    ) => {
      const newList = await updateAVinList(
        "rss_channels",
        plain,
        attrs,
        this.state.channels
      )
      this.setState({ channels: newList })
      return this.state.channels.find(v => v.objectId === plain.objectId)
    },
    deleteChannel: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList(
        "rss_channels",
        plain,
        this.state.channels
      )
      this.setState({ channels: newList })
    },
    updateFeed: async (plain: RSSFeedType, attrs: Partial<RSSFeedType>) => {
      const newList = await updateAVinList(
        "rss_feed",
        plain,
        attrs,
        this.state.feeds
      )
      this.setState({ feeds: newList })
      return this.state.feeds.find(v => v.objectId === plain.objectId)
    },
  }
}
