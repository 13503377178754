import React, { useState, useEffect } from "react"

type TabPanelProps = {
  children: React.ReactNode
  id: string
  isActive: boolean
}

export const TabPanel = React.memo((props: TabPanelProps) => {
  const { children, isActive, id, ...other } = props
  const [inited, setInted] = useState(false)

  useEffect(() => {
    if (isActive) setInted(true)
  }, [isActive])

  return (
    <div
      style={{
        height: "100%",
        display: isActive ? "block" : "none",
      }}
      {...other}
    >
      {inited && children}
    </div>
  )
})
