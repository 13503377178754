// @flow
import * as React from "react";
import Frame from "./components/Frame";

const URL_REGEX = new RegExp("^https://www.bilibili.com/video/([^?]*)");

type Props = {
  attrs: {
    href: string,
    matches: string[],
  },
};

export default class Prezi extends React.Component<Props> {
  static ENABLED = [URL_REGEX];

  render() {
    const { matches } = this.props.attrs;
    const shareId = matches[1];

    return (
      <Frame {...this.props} src={`https://player.bilibili.com/player.html?bvid=${shareId}`} title="Bilibili Embed" border />
    );
  }
}
