import React from "react"
import { css } from "@emotion/core"
import timeAgoFormat, { timeStr } from "../../utils/timeago"

import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import { RSSChannelType } from "src/lib/LCTypes"
import { useNovus } from "src/lib/novus"
import { rssModel } from "src/models"
import Chip from "@material-ui/core/Chip"

type ChannelCardProps = {
  entry: RSSChannelType
  onChoose: (id: string) => void
  onEdit: (channel: RSSChannelType) => void
  onDeleteChannel: (channel: RSSChannelType) => void
}

export const ChannelCard = React.memo(
  ({ entry, onChoose, onEdit, onDeleteChannel }: ChannelCardProps) => {
    let [filterChannel] = useNovus<[RSSChannelType]>(
      models => {
        return [models.rssModel.state.filterChannel]
      },
      ["rssModel"]
    )

    const [anchorEl, setAnchorEl] = React.useState(null)

    return (
      <Card
        data-key={entry.objectId}
        elevation={0}
        css={css`
          margin: 0 5px 10px;
          cursor: pointer;
          border: 2px solid transparent;
          &.active {
            border: 2px solid #384fdc;
          }
          :hover {
            background: #5d5d5d;
          }
        `}
        className={
          filterChannel && filterChannel.objectId === entry.objectId
            ? "active"
            : ""
        }
        style={entry.disable ? { opacity: 0.5 } : {}}
        onClick={() => {
          if (anchorEl == null) onChoose(entry.objectId)
        }}
      >
        <CardHeader
          action={
            <IconButton
              size="small"
              onClick={event => {
                setAnchorEl(event.currentTarget)
                event.stopPropagation()
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          }
          title={
            <span
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 1.7rem;
                margin-bottom: 5px;
              `}
              title={entry.rssLink}
            >
              <span
                css={css`
                  width: 0.8em;
                  height: 0.8em;
                  margin-right: 3px;
                `}
                style={{
                  background: entry.color,
                }}
              ></span>
              <span
                css={css`
                  max-width: 400px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                `}
              >
                {entry.title}
              </span>
            </span>
          }
          subheader={
            <span
              css={css`
                font-size: 1rem;
              `}
            >
              {entry.lastBuildDate ? (
                <span title={timeStr(new Date(entry.lastBuildDate))}>
                  {timeAgoFormat(new Date(entry.lastBuildDate))} 更新
                </span>
              ) : (
                  <span>没有更新</span>
                )}
              <span css={css``}>
                (本周{entry.lastWeekUpdates} / 共{entry.feedCount})
              </span>
            </span>
          }
          css={css`
            padding: 12px 12px 6px;
          `}
        />

        {entry.description && entry.description !== "" && (
          <CardContent
            css={css`
            padding: 0 12px 10px;
            `}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              css={css`
              `}
            >
              {entry.description}
            </Typography>
          </CardContent>
        )}
        {entry.keywords && entry.keywords.length > 0 && (
          <CardContent
            css={css`
              padding: 0 12px 10px !important;
            `}
          >
            {entry.keywords.map(k => (
              <Chip
                key={k}
                variant="outlined"
                size="small"
                label={k}
                onClick={() => {
                  //
                }}
              />
            ))}
          </CardContent>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={event => {
            setAnchorEl(null)
            // @ts-ignore
            event.stopPropagation()
          }}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              rssModel.actions.updateChannel(entry, { disable: !entry.disable })
            }}
          >
            {entry.disable ? "开启订阅" : "暂时不订阅"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onEdit(entry)
            }}
          >
            编辑订阅
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onDeleteChannel(entry)
            }}
          >
            删除
          </MenuItem>
        </Menu>
      </Card>
    )
  }
)
