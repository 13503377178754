import React, { memo, useState, useCallback } from "react"
import { css } from "@emotion/core"

import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"

import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Button from "@material-ui/core/Button"
import { formData2json } from "src/utils/utils"
import useMessage from "src/utils/message"
import AV from "leancloud-storage"
import { novus } from "src/lib/novus"

export const SignUpPanel = memo(() => {
  const [showPass, setShowPass] = useState(false)
  const [checkAuthing, setCheckAuthing] = useState(false)
  const message = useMessage()

  const [emailError, setEmailError] = useState("")

  const submitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      // @ts-ignore
      const oData = new FormData(event.target)
      const json = formData2json(oData)
      const email = json["email"]

      if (
        // 中英文点下划线@英文.
        !/^[A-Za-z0-9\u4e00-\u9fa5.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          email
        )
      ) {
        setEmailError("邮箱地址格式不正确")
        return
      }

      const user = new AV.User()

      user.setUsername(email)
      user.setEmail(email)
      user.setPassword(json["password"])

      setCheckAuthing(true)
      user.signUp().then(
        user => {
          setCheckAuthing(false)
          // 注册成功
          console.log(`注册成功。objectId：${user.id}`)
          message.success("注册成功")
        },
        error => {
          setCheckAuthing(false)
          // 注册失败（通常是因为用户名已被使用）
          console.error(error)
          message.error(error.rawMessage)
        }
      )
    },
    []
  )

  return (
    <Paper
      elevation={3}
      css={css`
        padding: 20px 20px;
      `}
    >
      <form autoComplete="current-password" onSubmit={submitHandler}>
        <div>
          <div
            css={css`
              margin: 10px 0;
              .MuiFormControl-root {
                margin: 10px 0;
              }
            `}
          >
            <TextField
              required
              name="email"
              label="邮箱"
              fullWidth
              variant="outlined"
              error={emailError !== ""}
              helperText={emailError}
              onChange={() => setEmailError("")}
            />
            <TextField
              required
              name="password"
              label="密码"
              fullWidth
              variant="outlined"
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPass(!showPass)
                      }}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault()
                      }}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          {checkAuthing ? (
            <IconButton color="primary">
              <CircularProgress size={20} />
            </IconButton>
          ) : (
              <Button
                color="primary"
                size="small"
                variant="contained"
                type="submit"
                disabled={checkAuthing}
              >
                注册
              </Button>
            )}
          <Button
            color="primary"
            size="small"
            variant="text"
            disabled={checkAuthing}
          >
            试用
          </Button>
        </div>
        <hr
          css={css`
            margin: 10px 0;
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>已经有账号了？</div>
          <div>
            <Button
              color="primary"
              size="small"
              variant="text"
              disabled={checkAuthing}
              onClick={(e) => {
                novus.models.authModel.actions.setMode('LOGIN')
              }}
            >
              登录
            </Button>
          </div>
        </div>
      </form>
    </Paper>
  )
})
