import AV from "leancloud-storage"
import { BasicModelType, NoteType } from "./LCTypes"
import { MessageType } from "src/utils/message"

export const LC_KEYS = {
  "X-LC-Id": "lAmGU3KrKL17OlruaSSK6iqN-gzGzoHsz",
  "X-LC-Key": "tYnf8wUGQBVnhergFmzvIvuC",
}

AV.init({
  appId: LC_KEYS["X-LC-Id"],
  appKey: LC_KEYS["X-LC-Key"],
  serverURL: "https://api-leancloud.ubug.io",
})

export const saveOptions = {
  fetchWhenSave: true,
}

type LCClassNames =
  | "bookmarks_cat"
  | "bookmarks"
  | "notes"
  | "recent_reading"
  | "rss_feed"
  | "rss_channels"
  | "time_machine"
  | "todo_list"
  | "todo_cat"

export const updateAVinList = async <T extends BasicModelType>(
  className: LCClassNames,
  plain: T,
  attrs: Partial<T>,
  list: T[]
): Promise<T[]> => {
  const ref = AV.Object.createWithoutData(className, plain.objectId)
  const item = await ref.save(attrs, saveOptions)
  const itemJson: T = item.toJSON()
  // console.log(`保存成功。objectId：${item.id}`);
  const ind = list.findIndex(v => v.objectId === item.id)
  if (ind > -1) {
    return [
      ...list.slice(0, ind),
      { ...list[ind], ...attrs, ...itemJson },
      ...list.slice(ind + 1),
    ]
  } else {
    return [...list]
  }
}

export const deleteAVinList = async <T extends BasicModelType>(
  className: LCClassNames,
  { objectId }: { objectId: string },
  list: T[]
): Promise<T[]> => {
  const ref = AV.Object.createWithoutData(className, objectId)
  await ref.destroy()
  const ind = list.findIndex(v => v.objectId === objectId)
  if (ind > -1) return [...list.slice(0, ind), ...list.slice(ind + 1)]
  return list
}

export const createAVinList = async <T extends BasicModelType>(
  className: LCClassNames,
  attrs: Partial<T>,
  list: T[]
): Promise<T[]> => {
  const ref = new AV.Object(className)

  var currentUser = AV.User.current()
  var acl = new AV.ACL()

  // 由于改成了多用户规则，所以将最近阅读收集的数据，并且是"我"的数据的时候，在 ACL 的时候添加 读取权限，以便在其他地方读取我的分享记录
  if (
    className === "recent_reading" &&
    currentUser.id === "5f01fd98c4d9660006adc8fc"
  ) {
    acl.setPublicReadAccess(true)
  }

  acl.setReadAccess(currentUser, true)
  acl.setWriteAccess(currentUser, true)
  ref.setACL(acl)

  const item = await ref.save(attrs)
  const itemJson: T = item.toJSON()
  return [itemJson, ...list.slice(0)]
}

//
// ==========================================================================
//                          独立的方法
// ==========================================================================
//

export const uplodaNoteFile = async (
  note: NoteType,
  fileRaw: File,
  message: MessageType
) => {
  console.log(fileRaw)
  // @ts-ignore
  const AV = await import("leancloud-storage")
  const file = new AV.File(fileRaw.name, fileRaw)
  file.metaData("note_id", note.objectId)

  const disposer = message.info("文件上传...")

  const fileResult = await file.save({
    keepFileName: true,
    ...saveOptions,
    onprogress: event => {
      console.log(event.loaded, event.total, event.percent)
    },
  })
  disposer()
  message.success("文件上传成功")

  const noteRef = AV.Object.createWithoutData("notes", note.objectId)
  const noteItem = noteRef.add("attachments", fileResult)
  await noteItem.save({}, saveOptions)

  return fileResult.url()
}
