import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react"
import { css } from "@emotion/core"
import timeAgoFormat, { timeStr } from "../utils/timeago"

import Pagination from "@material-ui/lab/Pagination"
import IconButton from "@material-ui/core/IconButton"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import SyncIcon from "@material-ui/icons/Sync"
import Empty from "src/components/empty"

import MainContent from "../components/Main"

import CommonCard from "../components/commonCrad"
import { RecentReadingType } from "src/lib/LCTypes"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import { NewForm, NewFormProps, FieldTypes } from "src/components/new-form"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { readingModel } from "src/models"
import openPage from "src/utils/openPage"
import Loading from "src/components/loading"

const NewReading = NewForm as React.ComponentType<
  NewFormProps<RecentReadingType>
>

export const RecentReading = React.memo(() => {
  const message = useMessage()

  let [readings, totalCount, loading, page, pageSize] = useNovus(
    models => {
      return [
        models.readingModel.state.readings,
        models.readingModel.state.totalCount,
        models.readingModel.state.loading,
        models.readingModel.state.page,
        models.readingModel.state.pageSize,
      ]
    },
    ["readingModel"]
  )

  useEffect(() => {
    readingModel.actions.refresh()
  }, [])

  useEffect(() => {
    var params = new URLSearchParams(location.search)
    var obj: { [i: string]: string } = {}
    params.forEach((v, k) => (obj[k] = v))
    if (obj["url"]) {
      console.log("setCreateShow")
      setCreateShow(true)
    }
  }, [])

  const [createVisible, setCreateShow] = useState(false)

  const mainContentRef = useRef<HTMLDivElement>()
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (mainContentRef.current) mainContentRef.current.scrollTop = 0
    readingModel.actions.setPage(page)
  }

  const currentDelRef = useRef<RecentReadingType>()
  const [deleteVisible, setDeleteShow] = useState(false)
  const handleDel = useCallback(async () => {
    await readingModel.actions.deleteReading(currentDelRef.current)
    currentDelRef.current = null
    message.success("删除成功")
  }, [currentDelRef])

  const cardList = useMemo(() => {
    return readings.length === 0 ? (
      <Empty title="没有最近阅读，右上角添加或者在订阅中添加~" />
    ) : (
        readings.map((entry, i) => {
          const time = entry.time

          return (
            <CommonCard
              key={entry.objectId}
              title={
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={entry.originUrl}
                  onClick={e => {
                    openPage(entry.originUrl, e)
                  }}
                  css={css`
                  text-decoration: none;
                `}
                >
                  {entry.title}
                </a>
              }
              titleBadge={entry.type}
              desc={
                <div>
                  <div>{entry.desc}</div>
                  {entry.comment && entry.comment !== "" && (
                    <blockquote
                      css={css`
                      margin: 10px 0;
                      &:before {
                        content: "“";
                      }
                      &:after {
                        content: "”";
                      }
                    `}
                    >
                      {entry.comment}
                    </blockquote>
                  )}
                </div>
              }
              subheader={
                <span title={timeStr(new Date(time))}>
                  {timeAgoFormat(new Date(time))}
                </span>
              }
              options={[
                {
                  id: "del",
                  title: "删除",
                },
                {
                  id: "edit",
                  title: "编辑",
                },
              ]}
              onOptionChoose={id => {
                if (id === "del") {
                  currentDelRef.current = entry
                  setDeleteShow(true)
                }
                if (id === "edit") {
                  editReading(entry)
                }
              }}
            />
          )
        })
      )
  }, [readings])

  const currentEditRef = useRef<RecentReadingType>()
  const editReading = useCallback((channel: RecentReadingType) => {
    currentEditRef.current = channel
    setCreateShow(true)
  }, [currentEditRef])
  const newReading = useCallback(() => {
    currentEditRef.current = null
    setCreateShow(true)
  }, [currentEditRef])

  const onNewOrEdit = useCallback(async attrs => {
    attrs.tag = ""
    attrs.time = {
      __type: "Date",
      iso: new Date().toISOString(),
    }
    if (currentEditRef.current) {
      await readingModel.actions.updateReading(currentEditRef.current, attrs)
      currentEditRef.current = null
      message.success("更新成功")
    } else {
      await readingModel.actions.createReading(attrs)
      message.success("创建成功")
    }
  }, [currentEditRef])

  return (
    <MainContent
      mainLoading={false}
      title="最近阅读"
      titleRights={[
        <IconButton key="new" onClick={newReading}>
          <AddCircleOutlineIcon />
        </IconButton>,
        <IconButton
          key="refresh"
          onClick={readingModel.actions.refresh}
          disabled={loading}
        >
          <SyncIcon />
        </IconButton>,
      ]}
      onRefresh={readingModel.actions.refresh}
    >
      <NewReading
        visible={createVisible}
        setVisible={setCreateShow}
        onConfirm={onNewOrEdit}
        title={currentEditRef.current ? "编辑内容" : "新的内容"}
        fields={[
          {
            type: FieldTypes.TEXT,
            name: "originUrl",
            label: "链接",
            autofocus: true,
          },
          {
            type: FieldTypes.TEXT,
            name: "title",
            label: "标题",
          },
          {
            type: FieldTypes.TEXT,
            name: "desc",
            label: "描述",
          },
          {
            type: FieldTypes.TEXT,
            name: "comment",
            label: "评论和见解",
          },
          {
            type: FieldTypes.TEXT,
            name: "type",
            label: "类型",
            defaultValue: "文章",
          },
        ]}
        editing={currentEditRef.current}
        setEditing={(target: RecentReadingType) => {
          currentEditRef.current = target
        }}
        autoDecodeUrl={{
          from: "originUrl",
          to: ["title", "desc"],
        }}
        fillFromQueryParams={{
          url: "originUrl",
          title: "title",
          desc: "desc",
        }}
      />

      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentDelRef.current ? currentDelRef.current.title : ""}
        onConfirm={handleDel}
      />

      {loading ? (
        <Loading size={30} title="加载中..." />
      ) : (
          <div
            ref={mainContentRef}
            css={css`
            flex: 1;
            overflow: auto;
            padding-bottom: 10px;
          `}
          >
            {cardList}
            {readings.length !== 0 && (
              <Pagination
                count={Math.ceil(totalCount / pageSize)}
                page={page}
                onChange={handlePageChange}
                css={css`
                display: flex;
                justify-content: center;
              `}
              />
            )}
          </div>
        )}
    </MainContent>
  )
})
