export default [
  "#ff2d51",
  "#8c4356",
  "#bce672",
  "#c93756",
  "#a4e2c6",
  "#f20c00",
  "#622a1d",
  "#9b4400",
  "#ed5736",
  "#ffc773",
  "#ffb61e",
  "#f05654",
  "#ff4e20",
  "#3d3b4f",
  "#ff8936",
  "#7c4b00",
  "#161823",
  "#00bc12",
  "#ff8c31",
  "#48c0a3",
  "#f36838",
  "#6b6882",
  "#afdd22",
  "#c83c23",
  "#c3272b",
  "#50616d",
  "#e29c45",
  "#75664d",
  "#21a675",
  "#b36d61",
  "#ca6924",
  "#be002f",
  "#896c39",
  "#ef7a82",
  "#ffc64b",
  "#41555d",
  "#845a33",
  "#ffa631",
  "#ff7500",
  "#ff461f",
  "#c32136",
  "#ff3300",
  "#eedeb0",
  "#0c8918",
  "#ff4777",
  "#fff143",
  "#d3b17d",
  "#808080",
  "#665757",
  "#d9b611",
  "#827100",
  "#424c50",
  "#392f41",
  "#057748",
  "#88ada6",
  "#60281e",
  "#f35336",
  "#9ed048",
  "#000000",
  "#6e511e",
  "#f2be45",
  "#5d513c",
  "#ff0097",
  "#db5a6b",
  "#16a951",
  "#dc3023",
  "#312520",
  "#c91f37",
  "#ff4c00",
  "#eaff56",
  "#7bcfa6",
  "#a88462",
  "#cb3a56",
  "#faff72",
  "#b25d25",
  "#a3d900",
  "#1bd1a5",
  "#75878a",
  "#fa8c35",
  "#f0c239",
  "#725e82",
  "#0aa344",
  "#eacd76",
  "#96ce54",
  "#9ed900",
  "#493131",
  "#0eb83a",
  "#9d2933",
  "#e9bb1d",
  "#955539",
  "#9c5333",
  "#bddd22",
  "#f47983",
  "#f9906f",
  "#a78e44",
  "#bf242a",
  "#ff2121",
  "#ffb3a7",
  "#ffa400",
  "#a98175",
  "#9d2933",
  "#f00056",
  "#7fecad",
  "#c89b40",
  "#549688",
  "#0eb83a",
  "#c9dd22",
  "#ae7000",
]

// 计算出经验灰度值，然后反色作为文字颜色
export const getTextColorFromHex = (bgColor: string) => {
  if (/^#([0-9a-f-A-F]{3}|[0-9a-f-A-F]{6})$/.test(bgColor))
    return bgColor
      .replace(/^#/, "")
      .replace(/^\w+$/, c =>
        c.length == 3
          ? c
            .split("")
            .map(c => c.repeat(2))
            .join("")
          : c
      )
      .match(/\w{2}/g)
      .map(hexV => parseInt(hexV, 16))
      .reduce((t, v, i) => t + [0.299, 0.587, 0.114][i] * v, 0) /
      255 >
      0.5
      ? "#000"
      : "#fff"
  else return "#fff"
}
export const oxHexToRgb = (color: string) => {
  return color
    .slice(2)
    .match(/.{2}/g)
    .map(f => parseInt(`0x${f}`))
    .reduce(
      (a, v, i, o) =>
        i === 0
          ? a
          : i === 3
            ? `${a}${v}, ${o[0] / 255})`
            : i === 0
              ? ""
              : `${a}${v}, `,
      "rgb("
    )
}
