import { NovusBaseModel, novus } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"


export interface IGlobalState {
  mobileOnlyPanel: boolean
}

// 使用继承类的形式限制属性和方法
export class GlobalModel extends NovusBaseModel<IGlobalState> {
  namespace = "globalModel"
  constructor() {
    super()
    this.state = {
      mobileOnlyPanel: false,
    }
  }
  message: MessageType
  // actions 集合，可以调用异步函数，约定为修改状态的逻辑，提供数据操作方法
  actions = {
    setMobileOnlyPanel: (mobileOnlyPanel: boolean) => {
      this.setState({ mobileOnlyPanel })
    },
  }
}
