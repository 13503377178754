import React, { useCallback, useState } from "react"
import { css } from "@emotion/core"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import PersonIcon from "@material-ui/icons/Person"
import AddIcon from "@material-ui/icons/Add"
import Typography from "@material-ui/core/Typography"
import { blue } from "@material-ui/core/colors"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import useMessage from "src/utils/message"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"

type ProProps = {
  isPro: boolean
}

export const Pro = React.memo(({ isPro = false }: ProProps) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        css={css`
          margin: 0 5px;
          padding: 0px 5px;
          height: 18px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          font-weight: bold;
          cursor: pointer;
          opacity: 0.8;
          :hover {
            opacity: 1;
          }
        `}
        style={
          isPro
            ? {
              color: "#f50056",
              background: "#ffc107",
            }
            : {
              color: "#fff",
              background: "#9a9a9a",
            }
        }
        onClick={() => setOpen(true)}
      >
        Pro
        {isPro ? "" : " 尚未激活"}
      </div>
      <ProPanel open={open} onClose={() => setOpen(false)} />
    </>
  )
})

export interface ProPanelProps {
  open: boolean
  onClose: () => void
}

function ProPanel(props: ProPanelProps) {
  const { onClose, open } = props
  const message = useMessage()

  const buyPro = useCallback((packet: number) => {
    message.info(`功能未准备好`)
  }, [])

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>解锁 Pro 体验</DialogTitle>
      <DialogContent
        css={css`
          width: 540px;
          max-width: 80vw;
        `}
      >
        <DialogContentText>
          产品维护成本上考虑，我们需要一点点收入来提供稳定的服务支持。同时您的支持是我们坚持开发下去的动力，也更能激励我们提供更优质、好用、良心的好产品。
        </DialogContentText>
        <List>
          <ListItem button onClick={() => buyPro(1)}>
            <ListItemText
              primary="单月套餐"
              secondary={
                <del>
                  ￥<strong>??</strong>
                </del>
              }
            />
            <ListItemSecondaryAction>
              <span>
                ￥<strong>??</strong>
              </span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => buyPro(2)}>
            <ListItemText
              primary="季度套餐"
              secondary={
                <del>
                  ￥<strong>??</strong>
                </del>
              }
            />
            <ListItemSecondaryAction>
              <span>
                ￥<strong>??</strong>
              </span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => buyPro(2)}>
            <ListItemText
              primary="年度套餐"
              secondary={
                <del>
                  ￥<strong>??</strong>
                </del>
              }
            />
            <ListItemSecondaryAction>
              <span>
                ￥<strong>??</strong>
              </span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => buyPro(2)}>
            <ListItemText
              primary="永久套餐"
              secondary={
                <del>
                  ￥<strong>??</strong>
                </del>
              }
            />
            <ListItemSecondaryAction>
              <span>
                ￥<strong>??</strong>
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  )
}
