// @flow
import * as React from "react";
import styled from "styled-components";
import Abstract from "./Abstract";
import Airtable from "./Airtable";
import Amap from "./Amap";
import Bilibili from "./Bilibili";
import ClickUp from "./ClickUp";
import Codepen from "./Codepen";
import Figma from "./Figma";
import Framer from "./Framer";
import Gist from "./Gist";
import GoogleDocs from "./GoogleDocs";
import GoogleSheets from "./GoogleSheets";
import GoogleSlides from "./GoogleSlides";
import InVision from "./InVision";
import Loom from "./Loom";
import Lucidchart from "./Lucidchart";
import Marvel from "./Marvel";
import Mindmeister from "./Mindmeister";
import Miro from "./Miro";
import ModeAnalytics from "./ModeAnalytics";
import Prezi from "./Prezi";
import Spotify from "./Spotify";
import Trello from "./Trello";
import Typeform from "./Typeform";
import Vimeo from "./Vimeo";
import YouTube from "./YouTube";

function matcher(Component: any) {
  return (url: string) => {
    const regexes = Component.ENABLED;
    for (const regex of regexes) {
      const result = url.match(regex);
      if (result) {
        return result;
      }
    }
  };
}

const Img = styled.img`
  margin: 4px;
  width: 18px;
  height: 18px;
`;

// TODO : codesandbox，优酷、哔哩哔哩、虾米、高德地图、墨刀、Figma、Canva、ProcessOn、CodePen、金数据、网易云
// 高德地图: https://lbs.amap.com/console/show/card

export default [
  {
    // 来自 地图名片 https://lbs.amap.com/console/show/card
    // key: 1ba5792d64b2dc19f28d66df798197cf
    title: "高德地图",
    keywords: "gaode ditu map amap",
    icon: () => <Img src="/images/embeds/amap.png" />,
    component: Amap,
    matcher: matcher(Amap),
  },
  {
    // 来自分享功能
    title: "哔哩哔哩",
    keywords: "bilibili",
    icon: () => <Img src="/images/embeds/bilibili.png" />,
    component: Bilibili,
    matcher: matcher(Bilibili),
  },
  {
    title: "Codepen | 演示",
    keywords: "code editor",
    icon: () => <Img src="/images/embeds/codepen.png" />,
    component: Codepen,
    matcher: matcher(Codepen),
  },
  {
    title: "Figma",
    keywords: "design svg vector",
    icon: () => <Img src="/images/embeds/figma.png" />,
    component: Figma,
    matcher: matcher(Figma),
  },
  {
    title: "Framer",
    keywords: "design prototyping",
    icon: () => <Img src="/images/embeds/framer.png" />,
    component: Framer,
    matcher: matcher(Framer),
  },
  {
    title: "GitHub Gist | 片段",
    keywords: "code",
    icon: () => <Img src="/images/embeds/github-gist.png" />,
    component: Gist,
    matcher: matcher(Gist),
  },
  {
    title: "Trello 看板",
    keywords: "kanban",
    icon: () => <Img src="/images/embeds/trello.png" />,
    component: Trello,
    matcher: matcher(Trello),
  },
  {
    title: "Google Docs | 文档",
    icon: () => <Img src="/images/embeds/google-docs.png" />,
    component: GoogleDocs,
    matcher: matcher(GoogleDocs),
  },
  {
    title: "Google Sheets | 工作表",
    keywords: "excel spreadsheet",
    icon: () => <Img src="/images/embeds/google-sheets.png" />,
    component: GoogleSheets,
    matcher: matcher(GoogleSheets),
  },
  {
    title: "Google Slides | 演示",
    keywords: "presentation slideshow",
    icon: () => <Img src="/images/embeds/google-slides.png" />,
    component: GoogleSlides,
    matcher: matcher(GoogleSlides),
  },
  {
    title: "YouTube",
    keywords: "google video",
    icon: () => <Img src="/images/embeds/youtube.png" />,
    component: YouTube,
    matcher: matcher(YouTube),
  },
  {
    title: "Abstract",
    keywords: "design",
    icon: () => <Img src="/images/embeds/abstract.png" />,
    component: Abstract,
    matcher: matcher(Abstract),
  },
  {
    title: "Airtable",
    keywords: "spreadsheet",
    icon: () => <Img src="/images/embeds/airtable.png" />,
    component: Airtable,
    matcher: matcher(Airtable),
  },
  {
    title: "ClickUp",
    keywords: "project",
    icon: () => <Img src="/images/embeds/clickup.png" />,
    component: ClickUp,
    matcher: matcher(ClickUp),
  },
  {
    title: "InVision",
    keywords: "design prototype",
    icon: () => <Img src="/images/embeds/invision.png" />,
    component: InVision,
    matcher: matcher(InVision),
  },
  {
    title: "Loom",
    keywords: "video screencast",
    icon: () => <Img src="/images/embeds/loom.png" />,
    component: Loom,
    matcher: matcher(Loom),
  },
  {
    title: "Lucidchart",
    keywords: "chart",
    icon: () => <Img src="/images/embeds/lucidchart.png" />,
    component: Lucidchart,
    matcher: matcher(Lucidchart),
  },
  {
    title: "Marvel",
    keywords: "design prototype",
    icon: () => <Img src="/images/embeds/marvel.png" />,
    component: Marvel,
    matcher: matcher(Marvel),
  },
  {
    title: "Mindmeister",
    keywords: "mindmap",
    icon: () => <Img src="/images/embeds/mindmeister.png" />,
    component: Mindmeister,
    matcher: matcher(Mindmeister),
  },
  {
    title: "Miro",
    keywords: "whiteboard",
    icon: () => <Img src="/images/embeds/miro.png" />,
    component: Miro,
    matcher: matcher(Miro),
  },
  {
    title: "Mode",
    keywords: "analytics",
    icon: () => <Img src="/images/embeds/mode-analytics.png" />,
    component: ModeAnalytics,
    matcher: matcher(ModeAnalytics),
  },
  {
    title: "Prezi",
    keywords: "presentation",
    icon: () => <Img src="/images/embeds/prezi.png" />,
    component: Prezi,
    matcher: matcher(Prezi),
  },
  {
    title: "Spotify",
    keywords: "music",
    icon: () => <Img src="/images/embeds/spotify.png" />,
    component: Spotify,
    matcher: matcher(Spotify),
  },
  {
    title: "Typeform",
    keywords: "form survey",
    icon: () => <Img src="/images/embeds/typeform.png" />,
    component: Typeform,
    matcher: matcher(Typeform),
  },
  {
    title: "Vimeo",
    keywords: "video",
    icon: () => <Img src="/images/embeds/vimeo.png" />,
    component: Vimeo,
    matcher: matcher(Vimeo),
  },
];
