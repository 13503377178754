import React, { useState, useCallback, useEffect, useMemo } from "react"
import { css } from "@emotion/core"

import SPALayout from "../components/pmb-layout"
import { RecentReading } from "../routes/Reading"
import { Notes } from "../routes/Notes"
import TodoPart from "../routes/Todo"
import BookMarkPart from "../routes/BookMark"
import { RssReader } from "../routes/RssReader"
import { TimeMachine } from "../routes/TimeMachine"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import ChromeReaderModeOutlined from "@material-ui/icons/ChromeReaderModeOutlined"
import ForumIcon from "@material-ui/icons/ForumOutlined"
import LibaryAddCheckIcon from "@material-ui/icons/LibraryAddCheckOutlined"
import LibaryBooksIcon from "@material-ui/icons/LibraryBooksOutlined"
import { AuthInputer } from "../components/workspace/auth"
import { TabPanel } from "../components/workspace/tabPanel"
import Drawers from "../components/workspace/drawer"
import Navs from "../components/workspace/navs"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"
import SettingsIcon from "@material-ui/icons/Settings"
import { Setting } from "../routes/Setting"
import AV from "leancloud-storage"

import { authModel } from "../models/"
import { useNovus } from "../lib/novus"
import useMessage from "../utils/message"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

export type NavMenus = {
  id: string
  title: string
  icon: JSX.Element
  panel: JSX.Element
}[]

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
    height: "100%",
    overflow: "auto",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))

const WorkBoard = React.memo(() => {
  const message = useMessage()

  let [checkAuthing, authenticated, mobileOnlyPanel] = useNovus<
    [boolean, boolean, boolean]
  >(
    models => {
      return [
        models.authModel.state.checkAuthing,
        models.authModel.state.authenticated,
        models.globalModel.state.mobileOnlyPanel,
      ]
    },
    ["authModel", "globalModel"]
  )

  useEffect(() => {
    authModel.actions.init(message)
  }, [])

  const logout = useCallback(() => {
    AV.User.logOut()
  }, [])

  let [activeId, setActiveId] = useState("")

  const classes = useStyles()

  useEffect(() => {
    const defaultId = window.location.hash.replace(/^#/, "") || "todo"
    setActiveId(defaultId)
  }, [])

  const setKey = useCallback(newId => {
    window.location.hash = newId
    setActiveId(newId)
  }, [])

  const menus = useMemo<NavMenus>(
    () => [
      {
        id: "rss_reader",
        title: "我的订阅",
        icon: <ChromeReaderModeOutlined />,
        panel: <RssReader />,
      },
      {
        id: "todo",
        title: "最近待办",
        icon: <DoneOutlineIcon />,
        panel: <TodoPart />,
      },
      {
        id: "notes",
        title: "我的笔记",
        icon: <LibaryBooksIcon />,
        panel: <Notes />,
      },
      {
        id: "reading",
        title: "最近阅读",
        icon: <LibaryAddCheckIcon />,
        panel: <RecentReading />,
      },
      {
        id: "bookmarks",
        title: "书签",
        icon: <BookmarkBorderIcon />,
        panel: <BookMarkPart />,
      },
      {
        id: "time_machine",
        title: "时光记",
        icon: <ForumIcon />,
        panel: <TimeMachine />,
      },
      {
        id: "setting",
        title: "设置",
        icon: <SettingsIcon />,
        panel: <Setting />,
      },
    ],
    []
  )

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={checkAuthing}
      >
        <Alert severity="info">检查登录ing</Alert>
      </Snackbar>
      {!authenticated && <AuthInputer />}

      <Hidden xsDown>
        <Drawers
          menus={menus}
          activeId={activeId}
          setKey={setKey}
          quitAuth={logout}
          setActiveId={setActiveId}
        />
      </Hidden>
      <Hidden smUp>
        <Navs
          hide={mobileOnlyPanel}
          menus={menus}
          activeId={activeId}
          setKey={setKey}
        />
      </Hidden>

      <div
        css={css`
            flex 1;
            height: 100%;
            overflow: auto;
          `}
      >
        {menus.map(menu => (
          <TabPanel isActive={activeId === menu.id} key={menu.id} id={menu.id}>
            {menu.panel}
          </TabPanel>
        ))}
      </div>
    </div>
  )
})

export default () => (
  <SPALayout>
    <WorkBoard />
  </SPALayout>
)
