import React, { useCallback } from "react"
import { css } from "@emotion/core"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

type ConfirmDeleteProps = {
  deleteVisible: boolean
  setDeleteShow: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: () => Promise<void>
  content: string
}

export const ConfirmDelete = React.memo(
  ({
    deleteVisible,
    setDeleteShow,
    onConfirm,
    content,
  }: ConfirmDeleteProps) => {
    const confirmDel = useCallback(async () => {
      await onConfirm()
      setDeleteShow(false)
    }, [onConfirm, setDeleteShow])

    return (
      <Dialog open={deleteVisible} onClose={() => setDeleteShow(false)}>
        <DialogTitle>删除这条记录</DialogTitle>
        <DialogContent>
          <DialogContentText
            css={css`
              width: 260px;
            `}
          >
            删除操作无法恢复！
            <br />
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteShow(false)} color="primary">
            取消
          </Button>
          <Button onClick={() => confirmDel()} color="secondary" autoFocus>
            确认删除
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
