import React from "react"
import { css } from "@emotion/core"
import { Button } from "@material-ui/core"

type EmptyProps = {
  title?: React.ReactNode
  refreshText?: string
  onRefresh?: () => void
}

const Empty = React.memo(
  ({ title = "", refreshText = "", onRefresh }: EmptyProps) => (
    <div
      css={css`
        text-align: center;
        color: #8e8e8e;
        padding: 30px 0;

        svg {
          width: 10em;
          height: 10em;
        }
      `}
    >
      <svg
        viewBox="0 0 1077 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
      >
        <path
          d="M0 933.456842a456.737684 85.342316 0 1 0 913.475368 0 456.737684 85.342316 0 1 0-913.475368 0Z"
          fill="#444"
          fillOpacity=".8"
        ></path>
        <path
          d="M822.130526 682.738526L660.944842 484.372211c-7.733895-9.337263-19.038316-14.989474-30.942316-14.989474h-346.543158c-11.897263 0-23.201684 5.652211-30.935579 14.989474L91.351579 682.738526v103.632842h730.778947V682.738526z"
          fill="#666666"
        ></path>
        <path
          d="M775.390316 794.165895L634.543158 624.990316c-6.743579-8.131368-16.889263-12.577684-27.270737-12.577684H305.071158c-10.374737 0-20.527158 4.446316-27.270737 12.577684L136.953263 794.165895v92.914526h638.437053V794.165895z"
          fill="#555"
        ></path>
        <path
          d="M227.907368 213.355789h457.653895a26.947368 26.947368 0 0 1 26.947369 26.947369v628.843789a26.947368 26.947368 0 0 1-26.947369 26.947369H227.907368a26.947368 26.947368 0 0 1-26.947368-26.947369V240.303158a26.947368 26.947368 0 0 1 26.947368-26.947369z"
          fill="#999"
        ></path>
        <path
          d="M287.514947 280.407579h338.438737a13.473684 13.473684 0 0 1 13.473684 13.473684V462.012632a13.473684 13.473684 0 0 1-13.473684 13.473684H287.514947a13.473684 13.473684 0 0 1-13.473684-13.473684V293.881263a13.473684 13.473684 0 0 1 13.473684-13.473684z m1.765053 268.220632h334.908632a15.238737 15.238737 0 0 1 0 30.477473H289.28a15.238737 15.238737 0 0 1 0-30.477473z m0 79.245473h334.908632a15.245474 15.245474 0 0 1 0 30.484211H289.28a15.245474 15.245474 0 0 1 0-30.484211z m531.354947 293.066105c-5.221053 20.688842-23.558737 36.109474-45.372631 36.109474H138.206316c-21.813895 0-40.151579-15.427368-45.365895-36.109474a49.300211 49.300211 0 0 1-1.495579-12.058947V682.745263h177.300211c19.584 0 35.368421 16.491789 35.368421 36.513684v0.269474c0 20.015158 15.966316 36.176842 35.550315 36.176842h234.341053c19.584 0 35.550316-16.309895 35.550316-36.331789V719.292632c0-20.021895 15.784421-36.554105 35.368421-36.554106h177.30021v226.149053a49.381053 49.381053 0 0 1-1.488842 12.05221z"
          fill="#666"
        ></path>
        <path
          d="M842.920421 224.282947l-46.012632 17.852632a6.736842 6.736842 0 0 1-8.872421-8.286316l13.049264-41.815579c-17.441684-19.833263-27.681684-44.018526-27.681685-70.117052C773.402947 54.581895 841.566316 0 925.655579 0 1009.724632 0 1077.894737 54.581895 1077.894737 121.916632c0 67.334737-68.163368 121.916632-152.245895 121.916631-30.504421 0-58.906947-7.181474-82.728421-19.550316z"
          fill="#999"
        ></path>
        <path
          d="M966.433684 124.968421a19.193263 18.964211 0 1 0 38.386527 0 19.193263 18.964211 0 1 0-38.386527 0Z"
          fill="#555"
        ></path>
        <path
          d="M884.870737 141.561263H846.484211L866.007579 108.375579l18.863158 33.185684zM908.860632 108.375579h33.583157V141.561263H908.860632V108.375579z"
          fill="#555"
        ></path>
      </svg>
      {title && title !== "" && <p>{title}</p>}
      {refreshText && (
        <Button
          component="button"
          variant="contained"
          color="primary"
          onClick={onRefresh}
        >
          {refreshText}
        </Button>
      )}
    </div>
  )
)

export default Empty
