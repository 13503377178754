import React, { useEffect } from "react"

import MainContent from "../components/Main"
import { NoteEditorArea } from "./notes/NoteEditorArea"
import { useNovus } from "src/lib/novus"
import { globalModel, noteModel } from "src/models"
import Loading from "src/components/loading"
import { NotesList } from "./notes/NotesList"
import { NoteToolbar } from "./notes/NoteToolbar"
import useMessage from "src/utils/message"
import Empty from "src/components/empty"
import Hidden from "@material-ui/core/Hidden"

export const Notes = React.memo(() => {
  const message = useMessage()

  let [
    mobileOnlyPanel,
    noteLoading,
    currentNoteId,
    isContentChanged,
    isTitleChanged,
  ] = useNovus(
    models => {
      return [
        models.globalModel.state.mobileOnlyPanel,
        models.noteModel.state.noteLoading,
        models.noteModel.state.currentNoteId,
        models.noteModel.state.isContentChanged,
        models.noteModel.state.isTitleChanged,
      ]
    },
    ["noteModel", "globalModel"]
  )

  useEffect(() => {
    noteModel.actions.refresh()
    noteModel.message = message
  }, [])

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isContentChanged || isTitleChanged) {
        event.preventDefault()
        event.returnValue = "当前有未保存的内容，确认放弃？"
      }
    }
    window.addEventListener("beforeunload", onBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  }, [isContentChanged, isTitleChanged])

  useEffect(() => {
    globalModel.actions.setMobileOnlyPanel(currentNoteId !== "")
  }, [currentNoteId])

  return (
    <>
      <Hidden smUp>
        {currentNoteId === "" ? (
          <MainContent
            mainLoading={false}
            title="最近笔记"
            noMaxWidth
            sideWidth={280}
            sideLoading={noteLoading}
            side={<NotesList />}
            titleRights={currentNoteId !== "" && <NoteToolbar />}
            onRefresh={noteModel.actions.refresh}
            hideToolbar={mobileOnlyPanel}
            showDrawSideAtMain={!noteLoading && currentNoteId == ""}
          >
            {noteLoading && <Loading size={30} title="加载中..." />}
          </MainContent>
        ) : (
          <NoteEditorArea />
        )}
      </Hidden>
      <Hidden xsDown>
        <MainContent
          mainLoading={false}
          title="最近笔记"
          noMaxWidth
          sideWidth={280}
          sideLoading={noteLoading}
          side={<NotesList />}
          titleRights={currentNoteId !== "" && <NoteToolbar />}
          onRefresh={noteModel.actions.refresh}
          hideToolbar={mobileOnlyPanel}
          showDrawSideAtMain={!noteLoading && currentNoteId == ""}
        >
          {noteLoading && <Loading size={30} title="加载中..." />}

          {currentNoteId === "" ? (
            <Empty title="请选择或创建笔记" />
          ) : (
            <NoteEditorArea />
          )}
        </MainContent>
      </Hidden>
    </>
  )
})
