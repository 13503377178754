import React, { useState, useMemo, useCallback, useContext } from "react"
import { css } from "@emotion/core"
import timeAgoFormat, { timeStr } from "../../utils/timeago"
import { getTextColorFromHex } from "../../utils/colors"

import StarIcon from "@material-ui/icons/Star"
import { RSSFeedType, RecentReadingType } from "src/lib/LCTypes"

import CommonCard from "../../components/commonCrad"
import { NewForm, NewFormProps, FieldTypes } from "src/components/new-form"
import useMessage from "src/utils/message"
import { rssModel, readingModel } from "src/models"
import openPage from "src/utils/openPage"

const NewReading = NewForm as React.ComponentType<
  NewFormProps<RecentReadingType>
>

const options = [
  { id: "collect", title: "收集到最近阅读" },
  { id: "mark", title: "添加星标" },
  { id: "read", title: "已读" },
]

type FeedCardProps = {
  feed: RSSFeedType
}

export const FeedCard = React.memo(({ feed }: FeedCardProps) => {
  const message = useMessage()

  const [createVisible, setCreateShow] = useState(false)

  const handleOption = useCallback(
    async (option: string) => {
      if (option === "read") {
        await rssModel.actions.updateFeed(feed, { readed: true })
        message.success("已设为已读")
      } else if (option === "mark") {
        await rssModel.actions.updateFeed(feed, { mark: true })
        message.success("星标成功")
      } else if (option === "collect") {
        setCreateShow(true)
      }
    },
    [feed]
  )

  let color = useMemo(() => getTextColorFromHex(feed.channel.color), [feed])

  const onNewOrEdit = useCallback(async attrs => {
    attrs.tag = ""
    attrs.time = {
      __type: "Date",
      iso: new Date().toISOString(),
    }
    await readingModel.actions.createReading(attrs)
    message.success("收集成功")
  }, [])

  return (
    <div data-key={feed.objectId}>
      <CommonCard
        title={
          <span>
            {feed.mark && <StarIcon color="secondary" />}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={feed.link}
              onClick={e => {
                openPage(feed.link, e)
                rssModel.actions.updateFeed(feed, { readed: true })
              }}
              css={css`
                text-decoration: none;
              `}
              style={
                feed.readed
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              {feed.title}
            </a>
          </span>
        }
        titleBadgeBg={feed.channel.color}
        titleBadgeColor={color}
        titleBadge={feed.channel.title}
        desc={
          feed.contentSnippet.length > 200
            ? `${feed.contentSnippet.substr(0, 200)}...`
            : feed.contentSnippet
        }
        subheader={
          <span title={timeStr(new Date(feed.pubDate))}>
            {timeAgoFormat(new Date(feed.pubDate))} 更新
          </span>
        }
        options={options}
        onOptionChoose={handleOption}
      />
      <NewReading
        visible={createVisible}
        setVisible={setCreateShow}
        onConfirm={onNewOrEdit}
        title="收集到阅读"
        fields={[
          {
            type: FieldTypes.TEXT,
            name: "originUrl",
            label: "链接",
            defaultValue: feed.link,
          },
          {
            type: FieldTypes.TEXT,
            name: "title",
            label: "标题",
            defaultValue: feed.title,
          },
          {
            type: FieldTypes.TEXT,
            name: "desc",
            label: "描述",
            defaultValue: feed.contentSnippet,
          },
          {
            type: FieldTypes.TEXT,
            name: "comment",
            label: "评论和见解",
          },
          {
            type: FieldTypes.TEXT,
            name: "type",
            label: "类型",
            defaultValue: "文章",
          },
        ]}
        editing={null}
        setEditing={() => {}}
        autoDecodeUrl={{
          from: "originUrl",
          to: ["title", "desc"],
        }}
      />
    </div>
  )
})
