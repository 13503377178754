let siteMapJsonTmp: string[] = null

export async function searchLink(title: string) {
  if (siteMapJsonTmp === null) {
    const response = await fetch("/sitemap.xml")
    const xmlStr = await response.text()
    const xml = parseXmlString(xmlStr)

    const items = xml.children[0]
    const feeds = items.children

    const allUrls = []

    for (let i = 0; i < feeds.length; i++) {
      const url = feeds[i];
      allUrls.push(url.children[0].textContent)
    }

    siteMapJsonTmp = allUrls
  }
  return siteMapJsonTmp
    .filter(url => url.replace(/https:\/\/ubug.io/, "").indexOf(title) > -1)
    .map(url => ({ title: url.replace(/https:\/\/ubug.io/, ""), url }))
}

const parseXmlString = function (xmlDocStr: string): Document {
  var isIEParser = window.ActiveXObject || "ActiveXObject" in window
  if (xmlDocStr === undefined) {
    return null
  }
  var xmlDoc
  if (window.DOMParser) {
    var parser = new window.DOMParser()
    var parsererrorNS = null
    // IE9+ now is here
    if (!isIEParser) {
      try {
        parsererrorNS = parser
          .parseFromString("INVALID", "text/xml")
          .getElementsByTagName("parsererror")[0].namespaceURI
      } catch (err) {
        parsererrorNS = null
      }
    }
    try {
      xmlDoc = parser.parseFromString(xmlDocStr, "text/xml")
      if (
        parsererrorNS !== null &&
        xmlDoc.getElementsByTagNameNS(parsererrorNS, "parsererror").length > 0
      ) {
        //throw new Error('Error parsing XML: '+xmlDocStr);
        xmlDoc = null
      }
    } catch (err) {
      xmlDoc = null
    }
  } else {
    // IE :(
    if (xmlDocStr.indexOf("<?") === 0) {
      xmlDocStr = xmlDocStr.substr(xmlDocStr.indexOf("?>") + 2)
    }
    // eslint-disable-next-line no-undef
    xmlDoc = new ActiveXObject("Microsoft.XMLDOM")
    xmlDoc.async = "false"
    xmlDoc.loadXML(xmlDocStr)
  }
  return xmlDoc
}
