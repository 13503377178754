function lastNonWhitespaceIndex(str, startIndex) {
  if (startIndex === void 0) { startIndex = str.length - 1; }
  for (var i = startIndex; i >= 0; i--) {
    var chCode = str.charCodeAt(i);
    if (chCode !== 32 /* Space */ && chCode !== 9 /* Tab */) {
      return i;
    }
  }
  return -1;
}
function firstNonWhitespaceIndex(str) {
  for (var i = 0, len = str.length; i < len; i++) {
    var chCode = str.charCodeAt(i);
    if (chCode !== 32 /* Space */ && chCode !== 9 /* Tab */) {
      return i;
    }
  }
  return -1;
}
let DiffChange = (function () {
  function DiffChange(originalStart, originalLength, modifiedStart, modifiedLength) {
    this.originalStart = originalStart;
    this.originalLength = originalLength;
    this.modifiedStart = modifiedStart;
    this.modifiedLength = modifiedLength;
  }
  DiffChange.prototype.getOriginalEnd = function () {
    return this.originalStart + this.originalLength;
  };
  DiffChange.prototype.getModifiedEnd = function () {
    return this.modifiedStart + this.modifiedLength;
  };
  return DiffChange;
}());

let Debug = (function () {
  function Debug() { }
  Debug.Assert = function (condition, message) {
    if (!condition) {
      throw new Error(message);
    }
  };
  return Debug;
}());
let MyArray = (function () {
  function MyArray() { }
  MyArray.Copy = function (sourceArray, sourceIndex, destinationArray, destinationIndex, length) {
    for (let i = 0; i < length; i++) {
      destinationArray[destinationIndex + i] = sourceArray[sourceIndex + i];
    }
  };
  return MyArray;
}());

let MaxDifferencesHistory = 1447;
let DiffChangeHelper = (function () {
  function DiffChangeHelper() {
    this.m_changes = [];
    this.m_originalStart = Number.MAX_VALUE;
    this.m_modifiedStart = Number.MAX_VALUE;
    this.m_originalCount = 0;
    this.m_modifiedCount = 0;
  }
  DiffChangeHelper.prototype.MarkNextChange = function () {
    if (this.m_originalCount > 0 || this.m_modifiedCount > 0) {
      this.m_changes.push(new DiffChange(this.m_originalStart, this.m_originalCount, this.m_modifiedStart, this.m_modifiedCount));
    }
    this.m_originalCount = 0;
    this.m_modifiedCount = 0;
    this.m_originalStart = Number.MAX_VALUE;
    this.m_modifiedStart = Number.MAX_VALUE;
  };
  DiffChangeHelper.prototype.AddOriginalElement = function (originalIndex, modifiedIndex) {
    this.m_originalStart = Math.min(this.m_originalStart, originalIndex);
    this.m_modifiedStart = Math.min(this.m_modifiedStart, modifiedIndex);
    this.m_originalCount++;
  };
  DiffChangeHelper.prototype.AddModifiedElement = function (originalIndex, modifiedIndex) {
    this.m_originalStart = Math.min(this.m_originalStart, originalIndex);
    this.m_modifiedStart = Math.min(this.m_modifiedStart, modifiedIndex);
    this.m_modifiedCount++;
  };
  DiffChangeHelper.prototype.getChanges = function () {
    if (this.m_originalCount > 0 || this.m_modifiedCount > 0) {
      this.MarkNextChange();
    }
    return this.m_changes;
  };
  DiffChangeHelper.prototype.getReverseChanges = function () {
    if (this.m_originalCount > 0 || this.m_modifiedCount > 0) {
      this.MarkNextChange();
    }
    this.m_changes.reverse();
    return this.m_changes;
  };
  return DiffChangeHelper;
}());
let hasOwnProperty = Object.prototype.hasOwnProperty;
let LcsDiff = (function () {
  function LcsDiff(originalSequence, newSequence, continueProcessingPredicate) {
    if (continueProcessingPredicate === void 0) {
      continueProcessingPredicate = null;
    }
    this.OriginalSequence = originalSequence;
    this.ModifiedSequence = newSequence;
    this.ContinueProcessingPredicate = continueProcessingPredicate;
    this.m_originalIds = [];
    this.m_modifiedIds = [];
    this.m_forwardHistory = [];
    this.m_reverseHistory = [];
    this.ComputeUniqueIdentifiers();
  }
  LcsDiff.prototype.ComputeUniqueIdentifiers = function () {
    let originalSequenceLength = this.OriginalSequence.getLength();
    let modifiedSequenceLength = this.ModifiedSequence.getLength();
    this.m_originalIds = new Array(originalSequenceLength);
    this.m_modifiedIds = new Array(modifiedSequenceLength);
    let hashTable = {};
    let currentUniqueId = 1;
    let i;
    for (i = 0; i < originalSequenceLength; i++) {
      let originalElementHash = this.OriginalSequence.getElementHash(i);
      if (!hasOwnProperty.call(hashTable, originalElementHash)) {
        this.m_originalIds[i] = currentUniqueId++;
        hashTable[originalElementHash] = this.m_originalIds[i];
      } else {
        this.m_originalIds[i] = hashTable[originalElementHash];
      }
    }
    for (i = 0; i < modifiedSequenceLength; i++) {
      let modifiedElementHash = this.ModifiedSequence.getElementHash(i);
      if (!hasOwnProperty.call(hashTable, modifiedElementHash)) {
        this.m_modifiedIds[i] = currentUniqueId++;
        hashTable[modifiedElementHash] = this.m_modifiedIds[i];
      } else {
        this.m_modifiedIds[i] = hashTable[modifiedElementHash];
      }
    }
  };
  LcsDiff.prototype.ElementsAreEqual = function (originalIndex, newIndex) {
    return this.m_originalIds[originalIndex] === this.m_modifiedIds[newIndex];
  };
  LcsDiff.prototype.OriginalElementsAreEqual = function (index1, index2) {
    return this.m_originalIds[index1] === this.m_originalIds[index2];
  };
  LcsDiff.prototype.ModifiedElementsAreEqual = function (index1, index2) {
    return this.m_modifiedIds[index1] === this.m_modifiedIds[index2];
  };
  LcsDiff.prototype.ComputeDiff = function (pretty) {
    return this._ComputeDiff(0, this.OriginalSequence.getLength() - 1, 0, this.ModifiedSequence.getLength() - 1, pretty);
  };
  LcsDiff.prototype._ComputeDiff = function (originalStart, originalEnd, modifiedStart, modifiedEnd, pretty) {
    let quitEarlyArr = [false];
    let changes = this.ComputeDiffRecursive(originalStart, originalEnd, modifiedStart, modifiedEnd, quitEarlyArr);
    if (pretty) {
      return this.ShiftChanges(changes);
    }
    return changes;
  };
  LcsDiff.prototype.ComputeDiffRecursive = function (originalStart, originalEnd, modifiedStart, modifiedEnd, quitEarlyArr) {
    quitEarlyArr[0] = false;
    while (originalStart <= originalEnd && modifiedStart <= modifiedEnd && this.ElementsAreEqual(originalStart, modifiedStart)) {
      originalStart++;
      modifiedStart++;
    }
    while (originalEnd >= originalStart && modifiedEnd >= modifiedStart && this.ElementsAreEqual(originalEnd, modifiedEnd)) {
      originalEnd--;
      modifiedEnd--;
    }
    if (originalStart > originalEnd || modifiedStart > modifiedEnd) {
      let changes = void 0;
      if (modifiedStart <= modifiedEnd) {
        Debug.Assert(originalStart === originalEnd + 1, 'originalStart should only be one more than originalEnd');
        changes = [
          new DiffChange(originalStart, 0, modifiedStart, modifiedEnd - modifiedStart + 1)
        ];
      } else if (originalStart <= originalEnd) {
        Debug.Assert(modifiedStart === modifiedEnd + 1, 'modifiedStart should only be one more than modifiedEnd');
        changes = [
          new DiffChange(originalStart, originalEnd - originalStart + 1, modifiedStart, 0)
        ];
      } else {
        Debug.Assert(originalStart === originalEnd + 1, 'originalStart should only be one more than originalEnd');
        Debug.Assert(modifiedStart === modifiedEnd + 1, 'modifiedStart should only be one more than modifiedEnd');
        changes = [];
      }
      return changes;
    }
    let midOriginalArr = [0],
      midModifiedArr = [0];
    let result = this.ComputeRecursionPoint(originalStart, originalEnd, modifiedStart, modifiedEnd, midOriginalArr, midModifiedArr, quitEarlyArr);
    let midOriginal = midOriginalArr[0];
    let midModified = midModifiedArr[0];
    if (result !== null) {
      return result;
    } else if (!quitEarlyArr[0]) {
      let leftChanges = this.ComputeDiffRecursive(originalStart, midOriginal, modifiedStart, midModified, quitEarlyArr);
      let rightChanges = [];
      if (!quitEarlyArr[0]) {
        rightChanges = this.ComputeDiffRecursive(midOriginal + 1, originalEnd, midModified + 1, modifiedEnd, quitEarlyArr);
      } else {
        rightChanges = [
          new DiffChange(midOriginal + 1, originalEnd - (midOriginal + 1) + 1, midModified + 1, modifiedEnd - (midModified + 1) + 1)
        ];
      }
      return this.ConcatenateChanges(leftChanges, rightChanges);
    }
    return [
      new DiffChange(originalStart, originalEnd - originalStart + 1, modifiedStart, modifiedEnd - modifiedStart + 1)
    ];
  };
  LcsDiff.prototype.WALKTRACE = function (diagonalForwardBase, diagonalForwardStart, diagonalForwardEnd, diagonalForwardOffset, diagonalReverseBase, diagonalReverseStart, diagonalReverseEnd, diagonalReverseOffset, forwardPoints, reversePoints, originalIndex, originalEnd, midOriginalArr, modifiedIndex, modifiedEnd, midModifiedArr, deltaIsEven, quitEarlyArr) {
    let forwardChanges = null,
      reverseChanges = null;
    let changeHelper = new DiffChangeHelper();
    let diagonalMin = diagonalForwardStart;
    let diagonalMax = diagonalForwardEnd;
    let diagonalRelative = (midOriginalArr[0] - midModifiedArr[0]) - diagonalForwardOffset;
    let lastOriginalIndex = Number.MIN_VALUE;
    let historyIndex = this.m_forwardHistory.length - 1;
    let diagonal;
    do {
      diagonal = diagonalRelative + diagonalForwardBase;
      if (diagonal === diagonalMin || (diagonal < diagonalMax && forwardPoints[diagonal - 1] < forwardPoints[diagonal + 1])) {
        originalIndex = forwardPoints[diagonal + 1];
        modifiedIndex = originalIndex - diagonalRelative - diagonalForwardOffset;
        if (originalIndex < lastOriginalIndex) {
          changeHelper.MarkNextChange();
        }
        lastOriginalIndex = originalIndex;
        changeHelper.AddModifiedElement(originalIndex + 1, modifiedIndex);
        diagonalRelative = (diagonal + 1) - diagonalForwardBase;
      } else {
        originalIndex = forwardPoints[diagonal - 1] + 1;
        modifiedIndex = originalIndex - diagonalRelative - diagonalForwardOffset;
        if (originalIndex < lastOriginalIndex) {
          changeHelper.MarkNextChange();
        }
        lastOriginalIndex = originalIndex - 1;
        changeHelper.AddOriginalElement(originalIndex, modifiedIndex + 1);
        diagonalRelative = (diagonal - 1) - diagonalForwardBase;
      }
      if (historyIndex >= 0) {
        forwardPoints = this.m_forwardHistory[historyIndex];
        diagonalForwardBase = forwardPoints[0];
        diagonalMin = 1;
        diagonalMax = forwardPoints.length - 1;
      }
    } while (--historyIndex >= -1);
    forwardChanges = changeHelper.getReverseChanges();
    if (quitEarlyArr[0]) {
      let originalStartPoint = midOriginalArr[0] + 1;
      let modifiedStartPoint = midModifiedArr[0] + 1;
      if (forwardChanges !== null && forwardChanges.length > 0) {
        let lastForwardChange = forwardChanges[forwardChanges.length - 1];
        originalStartPoint = Math.max(originalStartPoint, lastForwardChange.getOriginalEnd());
        modifiedStartPoint = Math.max(modifiedStartPoint, lastForwardChange.getModifiedEnd());
      }
      reverseChanges = [
        new DiffChange(originalStartPoint, originalEnd - originalStartPoint + 1, modifiedStartPoint, modifiedEnd - modifiedStartPoint + 1)
      ];
    } else {
      changeHelper = new DiffChangeHelper();
      diagonalMin = diagonalReverseStart;
      diagonalMax = diagonalReverseEnd;
      diagonalRelative = (midOriginalArr[0] - midModifiedArr[0]) - diagonalReverseOffset;
      lastOriginalIndex = Number.MAX_VALUE;
      historyIndex = (deltaIsEven) ? this.m_reverseHistory.length - 1 : this.m_reverseHistory.length - 2;
      do {
        diagonal = diagonalRelative + diagonalReverseBase;
        if (diagonal === diagonalMin || (diagonal < diagonalMax && reversePoints[diagonal - 1] >= reversePoints[diagonal + 1])) {
          originalIndex = reversePoints[diagonal + 1] - 1;
          modifiedIndex = originalIndex - diagonalRelative - diagonalReverseOffset;
          if (originalIndex > lastOriginalIndex) {
            changeHelper.MarkNextChange();
          }
          lastOriginalIndex = originalIndex + 1;
          changeHelper.AddOriginalElement(originalIndex + 1, modifiedIndex + 1);
          diagonalRelative = (diagonal + 1) - diagonalReverseBase;
        } else {
          originalIndex = reversePoints[diagonal - 1];
          modifiedIndex = originalIndex - diagonalRelative - diagonalReverseOffset;
          if (originalIndex > lastOriginalIndex) {
            changeHelper.MarkNextChange();
          }
          lastOriginalIndex = originalIndex;
          changeHelper.AddModifiedElement(originalIndex + 1, modifiedIndex + 1);
          diagonalRelative = (diagonal - 1) - diagonalReverseBase;
        }
        if (historyIndex >= 0) {
          reversePoints = this.m_reverseHistory[historyIndex];
          diagonalReverseBase = reversePoints[0];
          diagonalMin = 1;
          diagonalMax = reversePoints.length - 1;
        }
      } while (--historyIndex >= -1);
      reverseChanges = changeHelper.getChanges();
    }
    return this.ConcatenateChanges(forwardChanges, reverseChanges);
  };
  LcsDiff.prototype.ComputeRecursionPoint = function (originalStart, originalEnd, modifiedStart, modifiedEnd, midOriginalArr, midModifiedArr, quitEarlyArr) {
    let originalIndex, modifiedIndex;
    let diagonalForwardStart = 0,
      diagonalForwardEnd = 0;
    let diagonalReverseStart = 0,
      diagonalReverseEnd = 0;
    let numDifferences;
    originalStart--;
    modifiedStart--;
    midOriginalArr[0] = 0;
    midModifiedArr[0] = 0;
    this.m_forwardHistory = [];
    this.m_reverseHistory = [];
    let maxDifferences = (originalEnd - originalStart) + (modifiedEnd - modifiedStart);
    let numDiagonals = maxDifferences + 1;
    let forwardPoints = new Array(numDiagonals);
    let reversePoints = new Array(numDiagonals);
    let diagonalForwardBase = (modifiedEnd - modifiedStart);
    let diagonalReverseBase = (originalEnd - originalStart);
    let diagonalForwardOffset = (originalStart - modifiedStart);
    let diagonalReverseOffset = (originalEnd - modifiedEnd);
    let delta = diagonalReverseBase - diagonalForwardBase;
    let deltaIsEven = (delta % 2 === 0);
    forwardPoints[diagonalForwardBase] = originalStart;
    reversePoints[diagonalReverseBase] = originalEnd;
    quitEarlyArr[0] = false;
    let diagonal, tempOriginalIndex;
    for (numDifferences = 1; numDifferences <= (maxDifferences / 2) + 1; numDifferences++) {
      let furthestOriginalIndex = 0;
      let furthestModifiedIndex = 0;
      // Run the algorithm in the forward direction
      diagonalForwardStart = this.ClipDiagonalBound(diagonalForwardBase - numDifferences, numDifferences, diagonalForwardBase, numDiagonals);
      diagonalForwardEnd = this.ClipDiagonalBound(diagonalForwardBase + numDifferences, numDifferences, diagonalForwardBase, numDiagonals);
      for (diagonal = diagonalForwardStart; diagonal <= diagonalForwardEnd; diagonal += 2) {
        // STEP 1: We extend the furthest reaching point in the present diagonal
        // by looking at the diagonals above and below and picking the one whose point
        // is further away from the start point (originalStart, modifiedStart)
        if (diagonal === diagonalForwardStart || (diagonal < diagonalForwardEnd && forwardPoints[diagonal - 1] < forwardPoints[diagonal + 1])) {
          originalIndex = forwardPoints[diagonal + 1];
        } else {
          originalIndex = forwardPoints[diagonal - 1] + 1;
        }
        modifiedIndex = originalIndex - (diagonal - diagonalForwardBase) - diagonalForwardOffset;
        // Save the current originalIndex so we can test for false overlap in step 3
        tempOriginalIndex = originalIndex;
        // STEP 2: We can continue to extend the furthest reaching point in the present diagonal
        // so long as the elements are equal.
        while (originalIndex < originalEnd && modifiedIndex < modifiedEnd && this.ElementsAreEqual(originalIndex + 1, modifiedIndex + 1)) {
          originalIndex++;
          modifiedIndex++;
        }
        forwardPoints[diagonal] = originalIndex;
        if (originalIndex + modifiedIndex > furthestOriginalIndex + furthestModifiedIndex) {
          furthestOriginalIndex = originalIndex;
          furthestModifiedIndex = modifiedIndex;
        }
        // STEP 3: If delta is odd (overlap first happens on forward when delta is odd)
        // and diagonal is in the range of reverse diagonals computed for numDifferences-1
        // (the previous iteration; we haven't computed reverse diagonals for numDifferences yet)
        // then check for overlap.
        if (!deltaIsEven && Math.abs(diagonal - diagonalReverseBase) <= (numDifferences - 1)) {
          if (originalIndex >= reversePoints[diagonal]) {
            midOriginalArr[0] = originalIndex;
            midModifiedArr[0] = modifiedIndex;
            if (tempOriginalIndex <= reversePoints[diagonal] && MaxDifferencesHistory > 0 && numDifferences <= (MaxDifferencesHistory + 1)) {
              // BINGO! We overlapped, and we have the full trace in memory!
              return this.WALKTRACE(diagonalForwardBase, diagonalForwardStart, diagonalForwardEnd, diagonalForwardOffset, diagonalReverseBase, diagonalReverseStart, diagonalReverseEnd, diagonalReverseOffset, forwardPoints, reversePoints, originalIndex, originalEnd, midOriginalArr, modifiedIndex, modifiedEnd, midModifiedArr, deltaIsEven, quitEarlyArr);
            } else {
              // Either false overlap, or we didn't have enough memory for the full trace
              // Just return the recursion point
              return null;
            }
          }
        }
      }
      // Check to see if we should be quitting early, before moving on to the next iteration.
      let matchLengthOfLongest = ((furthestOriginalIndex - originalStart) + (furthestModifiedIndex - modifiedStart) - numDifferences) / 2;
      if (this.ContinueProcessingPredicate !== null && !this.ContinueProcessingPredicate(furthestOriginalIndex, this.OriginalSequence, matchLengthOfLongest)) {
        quitEarlyArr[0] = true;
        midOriginalArr[0] = furthestOriginalIndex;
        midModifiedArr[0] = furthestModifiedIndex;
        if (matchLengthOfLongest > 0 && MaxDifferencesHistory > 0 && numDifferences <= (MaxDifferencesHistory + 1)) {
          return this.WALKTRACE(diagonalForwardBase, diagonalForwardStart, diagonalForwardEnd, diagonalForwardOffset, diagonalReverseBase, diagonalReverseStart, diagonalReverseEnd, diagonalReverseOffset, forwardPoints, reversePoints, originalIndex, originalEnd, midOriginalArr, modifiedIndex, modifiedEnd, midModifiedArr, deltaIsEven, quitEarlyArr);
        } else {
          originalStart++;
          modifiedStart++;
          return [
            new DiffChange(originalStart, originalEnd - originalStart + 1, modifiedStart, modifiedEnd - modifiedStart + 1)
          ];
        }
      }
      diagonalReverseStart = this.ClipDiagonalBound(diagonalReverseBase - numDifferences, numDifferences, diagonalReverseBase, numDiagonals);
      diagonalReverseEnd = this.ClipDiagonalBound(diagonalReverseBase + numDifferences, numDifferences, diagonalReverseBase, numDiagonals);
      for (diagonal = diagonalReverseStart; diagonal <= diagonalReverseEnd; diagonal += 2) {
        if (diagonal === diagonalReverseStart || (diagonal < diagonalReverseEnd && reversePoints[diagonal - 1] >= reversePoints[diagonal + 1])) {
          originalIndex = reversePoints[diagonal + 1] - 1;
        } else {
          originalIndex = reversePoints[diagonal - 1];
        }
        modifiedIndex = originalIndex - (diagonal - diagonalReverseBase) - diagonalReverseOffset;
        tempOriginalIndex = originalIndex;
        while (originalIndex > originalStart && modifiedIndex > modifiedStart && this.ElementsAreEqual(originalIndex, modifiedIndex)) {
          originalIndex--;
          modifiedIndex--;
        }
        reversePoints[diagonal] = originalIndex;
        if (deltaIsEven && Math.abs(diagonal - diagonalForwardBase) <= numDifferences) {
          if (originalIndex <= forwardPoints[diagonal]) {
            midOriginalArr[0] = originalIndex;
            midModifiedArr[0] = modifiedIndex;
            if (tempOriginalIndex >= forwardPoints[diagonal] && MaxDifferencesHistory > 0 && numDifferences <= (MaxDifferencesHistory + 1)) {
              return this.WALKTRACE(diagonalForwardBase, diagonalForwardStart, diagonalForwardEnd, diagonalForwardOffset, diagonalReverseBase, diagonalReverseStart, diagonalReverseEnd, diagonalReverseOffset, forwardPoints, reversePoints, originalIndex, originalEnd, midOriginalArr, modifiedIndex, modifiedEnd, midModifiedArr, deltaIsEven, quitEarlyArr);
            } else {
              return null;
            }
          }
        }
      }
      if (numDifferences <= MaxDifferencesHistory) {
        let temp = new Array(diagonalForwardEnd - diagonalForwardStart + 2);
        temp[0] = diagonalForwardBase - diagonalForwardStart + 1;
        MyArray.Copy(forwardPoints, diagonalForwardStart, temp, 1, diagonalForwardEnd - diagonalForwardStart + 1);
        this.m_forwardHistory.push(temp);
        temp = new Array(diagonalReverseEnd - diagonalReverseStart + 2);
        temp[0] = diagonalReverseBase - diagonalReverseStart + 1;
        MyArray.Copy(reversePoints, diagonalReverseStart, temp, 1, diagonalReverseEnd - diagonalReverseStart + 1);
        this.m_reverseHistory.push(temp);
      }
    }
    return this.WALKTRACE(diagonalForwardBase, diagonalForwardStart, diagonalForwardEnd, diagonalForwardOffset, diagonalReverseBase, diagonalReverseStart, diagonalReverseEnd, diagonalReverseOffset, forwardPoints, reversePoints, originalIndex, originalEnd, midOriginalArr, modifiedIndex, modifiedEnd, midModifiedArr, deltaIsEven, quitEarlyArr);
  };
  LcsDiff.prototype.ShiftChanges = function (changes) {
    let mergedDiffs;
    do {
      mergedDiffs = false;
      for (let i = 0; i < changes.length; i++) {
        let change = changes[i];
        let originalStop = (i < changes.length - 1) ? changes[i + 1].originalStart : this.OriginalSequence.getLength();
        let modifiedStop = (i < changes.length - 1) ? changes[i + 1].modifiedStart : this.ModifiedSequence.getLength();
        let checkOriginal = change.originalLength > 0;
        let checkModified = change.modifiedLength > 0;
        while (change.originalStart + change.originalLength < originalStop &&
          change.modifiedStart + change.modifiedLength < modifiedStop &&
          (!checkOriginal || this.OriginalElementsAreEqual(change.originalStart, change.originalStart + change.originalLength)) &&
          (!checkModified || this.ModifiedElementsAreEqual(change.modifiedStart, change.modifiedStart + change.modifiedLength))) {
          change.originalStart++;
          change.modifiedStart++;
        }
      }
      let result = new Array();
      let mergedChangeArr = [null];
      for (let i = 0; i < changes.length; i++) {
        if (i < changes.length - 1 && this.ChangesOverlap(changes[i], changes[i + 1], mergedChangeArr)) {
          mergedDiffs = true;
          result.push(mergedChangeArr[0]);
          i++;
        } else {
          result.push(changes[i]);
        }
      }
      changes = result;
    } while (mergedDiffs);
    for (let i = changes.length - 1; i >= 0; i--) {
      let change = changes[i];
      let originalStop = 0;
      let modifiedStop = 0;
      if (i > 0) {
        let prevChange = changes[i - 1];
        if (prevChange.originalLength > 0) {
          originalStop = prevChange.originalStart + prevChange.originalLength;
        }
        if (prevChange.modifiedLength > 0) {
          modifiedStop = prevChange.modifiedStart + prevChange.modifiedLength;
        }
      }
      let checkOriginal = change.originalLength > 0;
      let checkModified = change.modifiedLength > 0;
      let bestDelta = 0;
      let bestScore = this._boundaryScore(change.originalStart, change.originalLength, change.modifiedStart, change.modifiedLength);
      for (let delta = 1; ; delta++) {
        let originalStart = change.originalStart - delta;
        let modifiedStart = change.modifiedStart - delta;
        if (originalStart < originalStop || modifiedStart < modifiedStop) {
          break;
        }
        if (checkOriginal && !this.OriginalElementsAreEqual(originalStart, originalStart + change.originalLength)) {
          break;
        }
        if (checkModified && !this.ModifiedElementsAreEqual(modifiedStart, modifiedStart + change.modifiedLength)) {
          break;
        }
        let score = this._boundaryScore(originalStart, change.originalLength, modifiedStart, change.modifiedLength);
        if (score > bestScore) {
          bestScore = score;
          bestDelta = delta;
        }
      }
      change.originalStart -= bestDelta;
      change.modifiedStart -= bestDelta;
    }
    return changes;
  };
  LcsDiff.prototype._OriginalIsBoundary = function (index) {
    if (index <= 0 || index >= this.OriginalSequence.getLength() - 1) {
      return true;
    }
    return /^\s*$/.test(this.OriginalSequence.getElementHash(index));
  };
  LcsDiff.prototype._OriginalRegionIsBoundary = function (originalStart, originalLength) {
    if (this._OriginalIsBoundary(originalStart) || this._OriginalIsBoundary(originalStart - 1)) {
      return true;
    }
    if (originalLength > 0) {
      let originalEnd = originalStart + originalLength;
      if (this._OriginalIsBoundary(originalEnd - 1) || this._OriginalIsBoundary(originalEnd)) {
        return true;
      }
    }
    return false;
  };
  LcsDiff.prototype._ModifiedIsBoundary = function (index) {
    if (index <= 0 || index >= this.ModifiedSequence.getLength() - 1) {
      return true;
    }
    return /^\s*$/.test(this.ModifiedSequence.getElementHash(index));
  };
  LcsDiff.prototype._ModifiedRegionIsBoundary = function (modifiedStart, modifiedLength) {
    if (this._ModifiedIsBoundary(modifiedStart) || this._ModifiedIsBoundary(modifiedStart - 1)) {
      return true;
    }
    if (modifiedLength > 0) {
      let modifiedEnd = modifiedStart + modifiedLength;
      if (this._ModifiedIsBoundary(modifiedEnd - 1) || this._ModifiedIsBoundary(modifiedEnd)) {
        return true;
      }
    }
    return false;
  };
  LcsDiff.prototype._boundaryScore = function (originalStart, originalLength, modifiedStart, modifiedLength) {
    let originalScore = (this._OriginalRegionIsBoundary(originalStart, originalLength) ? 1 : 0);
    let modifiedScore = (this._ModifiedRegionIsBoundary(modifiedStart, modifiedLength) ? 1 : 0);
    return (originalScore + modifiedScore);
  };
  LcsDiff.prototype.ConcatenateChanges = function (left, right) {
    let mergedChangeArr = [];
    let result = null;
    if (left.length === 0 || right.length === 0) {
      return (right.length > 0) ? right : left;
    } else if (this.ChangesOverlap(left[left.length - 1], right[0], mergedChangeArr)) {
      result = new Array(left.length + right.length - 1);
      MyArray.Copy(left, 0, result, 0, left.length - 1);
      result[left.length - 1] = mergedChangeArr[0];
      MyArray.Copy(right, 1, result, left.length, right.length - 1);
      return result;
    } else {
      result = new Array(left.length + right.length);
      MyArray.Copy(left, 0, result, 0, left.length);
      MyArray.Copy(right, 0, result, left.length, right.length);
      return result;
    }
  };
  LcsDiff.prototype.ChangesOverlap = function (left, right, mergedChangeArr) {
    Debug.Assert(left.originalStart <= right.originalStart, 'Left change is not less than or equal to right change');
    Debug.Assert(left.modifiedStart <= right.modifiedStart, 'Left change is not less than or equal to right change');
    if (left.originalStart + left.originalLength >= right.originalStart || left.modifiedStart + left.modifiedLength >= right.modifiedStart) {
      let originalStart = left.originalStart;
      let originalLength = left.originalLength;
      let modifiedStart = left.modifiedStart;
      let modifiedLength = left.modifiedLength;
      if (left.originalStart + left.originalLength >= right.originalStart) {
        originalLength = right.originalStart + right.originalLength - left.originalStart;
      }
      if (left.modifiedStart + left.modifiedLength >= right.modifiedStart) {
        modifiedLength = right.modifiedStart + right.modifiedLength - left.modifiedStart;
      }
      mergedChangeArr[0] = new DiffChange(originalStart, originalLength, modifiedStart, modifiedLength);
      return true;
    } else {
      mergedChangeArr[0] = null;
      return false;
    }
  };
  LcsDiff.prototype.ClipDiagonalBound = function (diagonal, numDifferences, diagonalBaseIndex, numDiagonals) {
    if (diagonal >= 0 && diagonal < numDiagonals) {
      return diagonal;
    }
    let diagonalsBelow = diagonalBaseIndex;
    let diagonalsAbove = numDiagonals - diagonalBaseIndex - 1;
    let diffEven = (numDifferences % 2 === 0);
    if (diagonal < 0) {
      let lowerBoundEven = (diagonalsBelow % 2 === 0);
      return (diffEven === lowerBoundEven) ? 0 : 1;
    } else {
      let upperBoundEven = (diagonalsAbove % 2 === 0);
      return (diffEven === upperBoundEven) ? numDiagonals - 1 : numDiagonals - 2;
    }
  };
  return LcsDiff;
}());
var __extends = (undefined && undefined.__extends) || (function () {
  var extendStatics = Object.setPrototypeOf ||
    ({
      __proto__: []
    }
      instanceof Array && function (d, b) {
        d.__proto__ = b;
      }) ||
    function (d, b) {
      for (var p in b)
        if (b.hasOwnProperty(p)) d[p] = b[p];
    };
  return function (d, b) {
    extendStatics(d, b);

    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();


var MAXIMUM_RUN_TIME = 5000;
var MINIMUM_MATCHING_CHARACTER_LENGTH = 3;

function computeDiff(originalSequence, modifiedSequence, continueProcessingPredicate, pretty) {
  var diffAlgo = new LcsDiff(originalSequence, modifiedSequence, continueProcessingPredicate);
  return diffAlgo.ComputeDiff(pretty);
}
var MarkerSequence = (function () {
  function MarkerSequence(buffer, startMarkers, endMarkers) {
    this.buffer = buffer;
    this.startMarkers = startMarkers;
    this.endMarkers = endMarkers;
  }
  MarkerSequence.prototype.getLength = function () {
    return this.startMarkers.length;
  };
  MarkerSequence.prototype.getElementHash = function (i) {
    return this.buffer.substring(this.startMarkers[i].offset, this.endMarkers[i].offset);
  };
  MarkerSequence.prototype.getStartLineNumber = function (i) {
    if (i === this.startMarkers.length) {
      return this.startMarkers[i - 1].lineNumber + 1;
    }
    return this.startMarkers[i].lineNumber;
  };
  MarkerSequence.prototype.getStartColumn = function (i) {
    return this.startMarkers[i].column;
  };
  MarkerSequence.prototype.getEndLineNumber = function (i) {
    return this.endMarkers[i].lineNumber;
  };
  MarkerSequence.prototype.getEndColumn = function (i) {
    return this.endMarkers[i].column;
  };
  return MarkerSequence;
}());
var LineMarkerSequence = (function (_super) {
  __extends(LineMarkerSequence, _super);

  function LineMarkerSequence(lines) {
    var _this = this;
    var buffer = '';
    var startMarkers = [];
    var endMarkers = [];
    for (var pos = 0, i = 0, length_1 = lines.length; i < length_1; i++) {
      buffer += lines[i];
      var startColumn = LineMarkerSequence._getFirstNonBlankColumn(lines[i], 1);
      var endColumn = LineMarkerSequence._getLastNonBlankColumn(lines[i], 1);
      startMarkers.push({
        offset: pos + startColumn - 1,
        lineNumber: i + 1,
        column: startColumn
      });
      endMarkers.push({
        offset: pos + endColumn - 1,
        lineNumber: i + 1,
        column: endColumn
      });
      pos += lines[i].length;
    }
    _this = _super.call(this, buffer, startMarkers, endMarkers) || this;
    return _this;
  }
  LineMarkerSequence._getFirstNonBlankColumn = function (txt, defaultValue) {
    var r = firstNonWhitespaceIndex(txt);
    if (r === -1) {
      return defaultValue;
    }
    return r + 1;
  };
  LineMarkerSequence._getLastNonBlankColumn = function (txt, defaultValue) {
    var r = lastNonWhitespaceIndex(txt);
    if (r === -1) {
      return defaultValue;
    }
    return r + 2;
  };
  LineMarkerSequence.prototype.getCharSequence = function (startIndex, endIndex) {
    var startMarkers = [];
    var endMarkers = [];
    for (var index = startIndex; index <= endIndex; index++) {
      var startMarker = this.startMarkers[index];
      var endMarker = this.endMarkers[index];
      for (var i = startMarker.offset; i < endMarker.offset; i++) {
        startMarkers.push({
          offset: i,
          lineNumber: startMarker.lineNumber,
          column: startMarker.column + (i - startMarker.offset)
        });
        endMarkers.push({
          offset: i + 1,
          lineNumber: startMarker.lineNumber,
          column: startMarker.column + (i - startMarker.offset) + 1
        });
      }
    }
    return new MarkerSequence(this.buffer, startMarkers, endMarkers);
  };
  return LineMarkerSequence;
}(MarkerSequence));
var CharChange = (function () {
  function CharChange(originalStartLineNumber, originalStartColumn, originalEndLineNumber, originalEndColumn, modifiedStartLineNumber, modifiedStartColumn, modifiedEndLineNumber, modifiedEndColumn) {
    this.originalStartLineNumber = originalStartLineNumber;
    this.originalStartColumn = originalStartColumn;
    this.originalEndLineNumber = originalEndLineNumber;
    this.originalEndColumn = originalEndColumn;
    this.modifiedStartLineNumber = modifiedStartLineNumber;
    this.modifiedStartColumn = modifiedStartColumn;
    this.modifiedEndLineNumber = modifiedEndLineNumber;
    this.modifiedEndColumn = modifiedEndColumn;
  }
  CharChange.createFromDiffChange = function (diffChange, originalCharSequence, modifiedCharSequence) {
    var originalStartLineNumber;
    var originalStartColumn;
    var originalEndLineNumber;
    var originalEndColumn;
    var modifiedStartLineNumber;
    var modifiedStartColumn;
    var modifiedEndLineNumber;
    var modifiedEndColumn;
    if (diffChange.originalLength === 0) {
      originalStartLineNumber = 0;
      originalStartColumn = 0;
      originalEndLineNumber = 0;
      originalEndColumn = 0;
    } else {
      originalStartLineNumber = originalCharSequence.getStartLineNumber(diffChange.originalStart);
      originalStartColumn = originalCharSequence.getStartColumn(diffChange.originalStart);
      originalEndLineNumber = originalCharSequence.getEndLineNumber(diffChange.originalStart + diffChange.originalLength - 1);
      originalEndColumn = originalCharSequence.getEndColumn(diffChange.originalStart + diffChange.originalLength - 1);
    }
    if (diffChange.modifiedLength === 0) {
      modifiedStartLineNumber = 0;
      modifiedStartColumn = 0;
      modifiedEndLineNumber = 0;
      modifiedEndColumn = 0;
    } else {
      modifiedStartLineNumber = modifiedCharSequence.getStartLineNumber(diffChange.modifiedStart);
      modifiedStartColumn = modifiedCharSequence.getStartColumn(diffChange.modifiedStart);
      modifiedEndLineNumber = modifiedCharSequence.getEndLineNumber(diffChange.modifiedStart + diffChange.modifiedLength - 1);
      modifiedEndColumn = modifiedCharSequence.getEndColumn(diffChange.modifiedStart + diffChange.modifiedLength - 1);
    }
    return new CharChange(originalStartLineNumber, originalStartColumn, originalEndLineNumber, originalEndColumn, modifiedStartLineNumber, modifiedStartColumn, modifiedEndLineNumber, modifiedEndColumn);
  };
  return CharChange;
}());

function postProcessCharChanges(rawChanges) {
  if (rawChanges.length <= 1) {
    return rawChanges;
  }
  var result = [rawChanges[0]];
  var prevChange = result[0];
  for (var i = 1, len = rawChanges.length; i < len; i++) {
    var currChange = rawChanges[i];
    var originalMatchingLength = currChange.originalStart - (prevChange.originalStart + prevChange.originalLength);
    var modifiedMatchingLength = currChange.modifiedStart - (prevChange.modifiedStart + prevChange.modifiedLength);
    var matchingLength = Math.min(originalMatchingLength, modifiedMatchingLength);
    if (matchingLength < MINIMUM_MATCHING_CHARACTER_LENGTH) {
      prevChange.originalLength = (currChange.originalStart + currChange.originalLength) - prevChange.originalStart;
      prevChange.modifiedLength = (currChange.modifiedStart + currChange.modifiedLength) - prevChange.modifiedStart;
    } else {
      result.push(currChange);
      prevChange = currChange;
    }
  }
  return result;
}
var LineChange = (function () {
  function LineChange(originalStartLineNumber, originalEndLineNumber, modifiedStartLineNumber, modifiedEndLineNumber, charChanges) {
    this.originalStartLineNumber = originalStartLineNumber;
    this.originalEndLineNumber = originalEndLineNumber;
    this.modifiedStartLineNumber = modifiedStartLineNumber;
    this.modifiedEndLineNumber = modifiedEndLineNumber;
    this.charChanges = charChanges;
  }
  LineChange.createFromDiffResult = function (diffChange, originalLineSequence, modifiedLineSequence, continueProcessingPredicate, shouldPostProcessCharChanges) {
    var originalStartLineNumber;
    var originalEndLineNumber;
    var modifiedStartLineNumber;
    var modifiedEndLineNumber;
    var charChanges;
    if (diffChange.originalLength === 0) {
      originalStartLineNumber = originalLineSequence.getStartLineNumber(diffChange.originalStart) - 1;
      originalEndLineNumber = 0;
    } else {
      originalStartLineNumber = originalLineSequence.getStartLineNumber(diffChange.originalStart);
      originalEndLineNumber = originalLineSequence.getEndLineNumber(diffChange.originalStart + diffChange.originalLength - 1);
    }
    if (diffChange.modifiedLength === 0) {
      modifiedStartLineNumber = modifiedLineSequence.getStartLineNumber(diffChange.modifiedStart) - 1;
      modifiedEndLineNumber = 0;
    } else {
      modifiedStartLineNumber = modifiedLineSequence.getStartLineNumber(diffChange.modifiedStart);
      modifiedEndLineNumber = modifiedLineSequence.getEndLineNumber(diffChange.modifiedStart + diffChange.modifiedLength - 1);
    }
    if (diffChange.originalLength !== 0 && diffChange.modifiedLength !== 0 && continueProcessingPredicate()) {
      var originalCharSequence = originalLineSequence.getCharSequence(diffChange.originalStart, diffChange.originalStart + diffChange.originalLength - 1);
      var modifiedCharSequence = modifiedLineSequence.getCharSequence(diffChange.modifiedStart, diffChange.modifiedStart + diffChange.modifiedLength - 1);
      var rawChanges = computeDiff(originalCharSequence, modifiedCharSequence, continueProcessingPredicate, true);
      if (shouldPostProcessCharChanges) {
        rawChanges = postProcessCharChanges(rawChanges);
      }
      charChanges = [];
      for (var i = 0, length_2 = rawChanges.length; i < length_2; i++) {
        charChanges.push(CharChange.createFromDiffChange(rawChanges[i], originalCharSequence, modifiedCharSequence));
      }
    }
    return new LineChange(originalStartLineNumber, originalEndLineNumber, modifiedStartLineNumber, modifiedEndLineNumber, charChanges);
  };
  return LineChange;
}());
var DiffComputer = (function () {
  function DiffComputer(originalLines, modifiedLines, opts) {
    this.shouldPostProcessCharChanges = opts.shouldPostProcessCharChanges;
    this.shouldIgnoreTrimWhitespace = opts.shouldIgnoreTrimWhitespace;
    this.shouldMakePrettyDiff = opts.shouldMakePrettyDiff;
    this.maximumRunTimeMs = MAXIMUM_RUN_TIME;
    this.originalLines = originalLines;
    this.modifiedLines = modifiedLines;
    this.original = new LineMarkerSequence(originalLines);
    this.modified = new LineMarkerSequence(modifiedLines);
  }
  DiffComputer.prototype.computeDiff = function () {
    if (this.original.getLength() === 1 && this.original.getElementHash(0).length === 0) {
      return [{
        originalStartLineNumber: 1,
        originalEndLineNumber: 1,
        modifiedStartLineNumber: 1,
        modifiedEndLineNumber: this.modified.getLength(),
        charChanges: [{
          modifiedEndColumn: 0,
          modifiedEndLineNumber: 0,
          modifiedStartColumn: 0,
          modifiedStartLineNumber: 0,
          originalEndColumn: 0,
          originalEndLineNumber: 0,
          originalStartColumn: 0,
          originalStartLineNumber: 0
        }]
      }];
    }
    if (this.modified.getLength() === 1 && this.modified.getElementHash(0).length === 0) {
      return [{
        originalStartLineNumber: 1,
        originalEndLineNumber: this.original.getLength(),
        modifiedStartLineNumber: 1,
        modifiedEndLineNumber: 1,
        charChanges: [{
          modifiedEndColumn: 0,
          modifiedEndLineNumber: 0,
          modifiedStartColumn: 0,
          modifiedStartLineNumber: 0,
          originalEndColumn: 0,
          originalEndLineNumber: 0,
          originalStartColumn: 0,
          originalStartLineNumber: 0
        }]
      }];
    }
    this.computationStartTime = (new Date()).getTime();
    var rawChanges = computeDiff(this.original, this.modified, this._continueProcessingPredicate.bind(this), this.shouldMakePrettyDiff);
    if (this.shouldIgnoreTrimWhitespace) {
      var lineChanges = [];
      for (var i = 0, length_3 = rawChanges.length; i < length_3; i++) {
        lineChanges.push(LineChange.createFromDiffResult(rawChanges[i], this.original, this.modified, this._continueProcessingPredicate.bind(this), this.shouldPostProcessCharChanges));
      }
      return lineChanges;
    }
    var result = [];
    var originalLineIndex = 0;
    var modifiedLineIndex = 0;
    for (var i = -1, len = rawChanges.length; i < len; i++) {
      var nextChange = (i + 1 < len ? rawChanges[i + 1] : null);
      var originalStop = (nextChange ? nextChange.originalStart : this.originalLines.length);
      var modifiedStop = (nextChange ? nextChange.modifiedStart : this.modifiedLines.length);
      while (originalLineIndex < originalStop && modifiedLineIndex < modifiedStop) {
        var originalLine = this.originalLines[originalLineIndex];
        var modifiedLine = this.modifiedLines[modifiedLineIndex];
        if (originalLine !== modifiedLine) {
          {
            var originalStartColumn = LineMarkerSequence._getFirstNonBlankColumn(originalLine, 1);
            var modifiedStartColumn = LineMarkerSequence._getFirstNonBlankColumn(modifiedLine, 1);
            while (originalStartColumn > 1 && modifiedStartColumn > 1) {
              var originalChar = originalLine.charCodeAt(originalStartColumn - 2);
              var modifiedChar = modifiedLine.charCodeAt(modifiedStartColumn - 2);
              if (originalChar !== modifiedChar) {
                break;
              }
              originalStartColumn--;
              modifiedStartColumn--;
            }
            if (originalStartColumn > 1 || modifiedStartColumn > 1) {
              this._pushTrimWhitespaceCharChange(result, originalLineIndex + 1, 1, originalStartColumn, modifiedLineIndex + 1, 1, modifiedStartColumn);
            }
          } {
            var originalEndColumn = LineMarkerSequence._getLastNonBlankColumn(originalLine, 1);
            var modifiedEndColumn = LineMarkerSequence._getLastNonBlankColumn(modifiedLine, 1);
            var originalMaxColumn = originalLine.length + 1;
            var modifiedMaxColumn = modifiedLine.length + 1;
            while (originalEndColumn < originalMaxColumn && modifiedEndColumn < modifiedMaxColumn) {
              var originalChar = originalLine.charCodeAt(originalEndColumn - 1);
              var modifiedChar = originalLine.charCodeAt(modifiedEndColumn - 1);
              if (originalChar !== modifiedChar) {
                break;
              }
              originalEndColumn++;
              modifiedEndColumn++;
            }
            if (originalEndColumn < originalMaxColumn || modifiedEndColumn < modifiedMaxColumn) {
              this._pushTrimWhitespaceCharChange(result, originalLineIndex + 1, originalEndColumn, originalMaxColumn, modifiedLineIndex + 1, modifiedEndColumn, modifiedMaxColumn);
            }
          }
        }
        originalLineIndex++;
        modifiedLineIndex++;
      }
      if (nextChange) {
        result.push(LineChange.createFromDiffResult(nextChange, this.original, this.modified, this._continueProcessingPredicate.bind(this), this.shouldPostProcessCharChanges));
        originalLineIndex += nextChange.originalLength;
        modifiedLineIndex += nextChange.modifiedLength;
      }
    }
    return result;
  };
  DiffComputer.prototype._pushTrimWhitespaceCharChange = function (result, originalLineNumber, originalStartColumn, originalEndColumn, modifiedLineNumber, modifiedStartColumn, modifiedEndColumn) {
    if (this._mergeTrimWhitespaceCharChange(result, originalLineNumber, originalStartColumn, originalEndColumn, modifiedLineNumber, modifiedStartColumn, modifiedEndColumn)) {
      return;
    }
    result.push(new LineChange(originalLineNumber, originalLineNumber, modifiedLineNumber, modifiedLineNumber, [
      new CharChange(originalLineNumber, originalStartColumn, originalLineNumber, originalEndColumn, modifiedLineNumber, modifiedStartColumn, modifiedLineNumber, modifiedEndColumn)
    ]));
  };
  DiffComputer.prototype._mergeTrimWhitespaceCharChange = function (result, originalLineNumber, originalStartColumn, originalEndColumn, modifiedLineNumber, modifiedStartColumn, modifiedEndColumn) {
    var len = result.length;
    if (len === 0) {
      return false;
    }
    var prevChange = result[len - 1];
    if (prevChange.originalEndLineNumber === 0 || prevChange.modifiedEndLineNumber === 0) {
      return false;
    }
    if (prevChange.originalEndLineNumber + 1 === originalLineNumber && prevChange.modifiedEndLineNumber + 1 === modifiedLineNumber) {
      prevChange.originalEndLineNumber = originalLineNumber;
      prevChange.modifiedEndLineNumber = modifiedLineNumber;
      prevChange.charChanges.push(new CharChange(originalLineNumber, originalStartColumn, originalLineNumber, originalEndColumn, modifiedLineNumber, modifiedStartColumn, modifiedLineNumber, modifiedEndColumn));
      return true;
    }
    return false;
  };
  DiffComputer.prototype._continueProcessingPredicate = function () {
    if (this.maximumRunTimeMs === 0) {
      return true;
    }
    var now = (new Date()).getTime();
    return now - this.computationStartTime < this.maximumRunTimeMs;
  };
  return DiffComputer;
}());

const computeDirtyDiff = (originalLines, modifiedLines) => {
  let diffComputer = new DiffComputer(originalLines, modifiedLines, {
    shouldComputeCharChanges: false,
    shouldPostProcessCharChanges: false,
    shouldIgnoreTrimWhitespace: false,
    shouldMakePrettyDiff: true
  });
  return diffComputer.computeDiff();
};

// 定义修改类型
const ChangeType = { Modify: 0, Add: 1, Delete: 2 };
// 根据不同的数据，能解析为不同的修改类型
function getChangeType(change) {
  if (change.originalEndLineNumber === 0) {           // 新增行
    return ChangeType.Add;
  } else if (change.modifiedEndLineNumber === 0) {    // 删除行
    return ChangeType.Delete;
  } else {                                            // 其他的都是修改
    return ChangeType.Modify;
  }
}

// 不同的 decorations 有不同的 class name，以便 css 定义样式
const baseStyle = `border-left: 3px solid;padding-left: 5px;margin-left: -8px;`
const modifiedOptions = {
  getStyles: (selector) => `${selector}{${baseStyle}border-color: #37b4d7;}`,
};
const addedOptions = {
  getStyles: (selector) => `${selector}{${baseStyle}border-color: #8dc020;}`,
};
const deletedOptions = {
  getStyles: (selector) => `${selector}:after{
    content: '';
    border: 4px solid;
    border-color: transparent transparent transparent #c9171f;
    position: absolute;
    bottom: -10px;
    left: -5px;}`,
};

// 根据上面的 diffChanges 进行转换
const generateDecorations = (changes) => {
  const decorations = changes.map((change) => {
    const changeType = getChangeType(change);
    const startLineNumber = change.modifiedStartLineNumber;
    const endLineNumber = change.modifiedEndLineNumber || startLineNumber;
    // 根据不同的修改类型，定义影响的行范围
    switch (changeType) {
      case ChangeType.Add:
        return {
          range: {
            startLineNumber: startLineNumber, startColumn: 1,
            endLineNumber: endLineNumber, endColumn: 1
          },
          options: addedOptions
        };
      case ChangeType.Delete:
        return {
          range: {
            startLineNumber: startLineNumber, startColumn: 1,
            endLineNumber: startLineNumber, endColumn: 1
          },
          options: deletedOptions
        };
      case ChangeType.Modify:
        return {
          range: {
            startLineNumber: startLineNumber, startColumn: 1,
            endLineNumber: endLineNumber, endColumn: 1
          },
          options: modifiedOptions
        };
    }
  });
  return decorations;
}

export {
  computeDirtyDiff,
  generateDecorations,
};