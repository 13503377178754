import { NovusBaseModel, novus } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"
import { TodoCatType, TodoType } from "src/lib/LCTypes"
import {
  updateAVinList,
  deleteAVinList,
  createAVinList,
} from "src/lib/av-models"

export interface ITodoState {
  catLoading: boolean
  listLoading: boolean
  todoCats: TodoCatType[]
  todoList: TodoType[]
}

// 使用继承类的形式限制属性和方法
export class TodoModel extends NovusBaseModel<ITodoState> {
  namespace = "todoModel"
  constructor() {
    super()
    this.state = {
      catLoading: false,
      listLoading: false,
      todoCats: [],
      todoList: [],
    }
  }
  message: MessageType
  actions = {
    refresh: async () => {
      this.setState({ catLoading: true, listLoading: true })
      this.actions.fetchCats()
      await this.actions.fetchTodos()
    },
    fetchCats: async () => {
      const query = new AV.Query<AV.Object>("todo_cat")
      this.setState({ catLoading: true })
      const result = await query.find()
      this.setState({
        todoCats: [
          // {
          //   objectId: "default",
          //   color: "0xffaa33ff",
          //   icon: 57445,
          //   name: "默认",
          // },
          ...result.map(r => r.toJSON()),
        ],
        catLoading: false,
      })
    },
    createTodoCat: async (
      attrs: Partial<TodoCatType>
    ): Promise<TodoCatType> => {
      const newList = await createAVinList(
        "todo_cat",
        attrs,
        this.state.todoCats
      )
      this.setState({ todoCats: newList })
      return this.state.todoCats[0]
    },
    updateTodoCat: async (plain: TodoCatType, attrs: Partial<TodoCatType>) => {
      const newList = await updateAVinList(
        "todo_cat",
        plain,
        attrs,
        this.state.todoCats
      )
      this.setState({ todoCats: newList })
      return this.state.todoCats.find(v => v.objectId === plain.objectId)
    },
    fetchTodos: async () => {
      const query = new AV.Query<AV.Object>("todo_list")
      // query.descending("createdAt")
      query.equalTo("status", 0)
      this.setState({ listLoading: true })
      const result = await query.find()
      this.setState({
        todoList: [...result.map(r => r.toJSON())],
        listLoading: false,
      })
    },
    deleteTodo: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList(
        "todo_list",
        plain,
        this.state.todoList
      )
      this.setState({ todoList: newList })
    },
    updateTodo: async (plain: TodoType, attrs: Partial<TodoType>) => {
      const newList = await updateAVinList(
        "todo_list",
        plain,
        attrs,
        this.state.todoList
      )
      this.setState({ todoList: newList })
      return this.state.todoList.find(v => v.objectId === plain.objectId)
    },
    createTodo: async (attrs: Partial<TodoType>): Promise<TodoType> => {
      const newList = await createAVinList(
        "todo_list",
        attrs,
        this.state.todoList
      )
      this.setState({ todoList: newList })
      return this.state.todoList[0]
    },
  }
}
