import { NovusBaseModel } from "../lib/novus"
import AV from "leancloud-storage"
import { MessageType } from "src/utils/message"
import { BookMarkListType, BookMarkCatType } from "src/lib/LCTypes"
import {
  updateAVinList,
  deleteAVinList,
  createAVinList,
} from "src/lib/av-models"

export interface IBookMarkState {
  catLoading: boolean
  listLoading: boolean
  currentCatId: string
  cats: BookMarkCatType[]
  list: BookMarkListType[]
}

// 使用继承类的形式限制属性和方法
export class BookMarkModel extends NovusBaseModel<IBookMarkState> {
  namespace = "bookMarkModel"
  constructor() {
    super()
    this.state = {
      catLoading: false,
      listLoading: false,
      currentCatId: "",
      cats: [],
      list: [],
    }
  }
  message: MessageType
  actions = {
    refresh: async () => {
      this.setState({ catLoading: true, listLoading: true })
      await this.actions.fetchCats()
      await this.actions.fetchList()
    },
    fetchCats: async () => {
      const query = new AV.Query<AV.Object>("bookmarks_cat")
      this.setState({ catLoading: true })
      const result = await query.find()
      this.setState({
        cats: [...result.map(r => r.toJSON())],
        catLoading: false,
      })
    },
    fetchList: async () => {
      this.setState({ listLoading: true })

      const query = new AV.Query<AV.Object>("bookmarks")
      query.descending("clicks")

      if (this.state.currentCatId) {
        const cat = AV.Object.createWithoutData(
          "bookmarks_cat",
          this.state.currentCatId
        )
        query.equalTo("cat", cat)
      }

      const result = await query.find()
      this.setState({
        list: [...result.map(r => r.toJSON())],
        listLoading: false,
      })
    },
    updateCurrentCat: async (id: string) => {
      this.setState({ currentCatId: id })
      this.actions.fetchList()
    },
    updateBookMarkCat: async (
      plain: BookMarkCatType,
      attrs: Partial<BookMarkCatType>
    ) => {
      const newList = await updateAVinList(
        "bookmarks_cat",
        plain,
        attrs,
        this.state.cats
      )
      this.setState({ cats: newList })
      return this.state.cats.find(v => v.objectId === plain.objectId)
    },
    deleteBookMarkCat: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList(
        "bookmarks_cat",
        plain,
        this.state.cats
      )
      this.setState({ cats: newList })
    },
    createBookMarkCat: async (
      attrs: Partial<BookMarkCatType>
    ): Promise<BookMarkCatType> => {
      const newList = await createAVinList(
        "bookmarks_cat",
        attrs,
        this.state.cats
      )
      this.setState({ cats: newList })
      return this.state.cats[0]
    },
    deleteBookMark: async (plain: { objectId: string }) => {
      const newList = await deleteAVinList("bookmarks", plain, this.state.list)
      this.setState({ list: newList })
    },
    updateBookMark: async (
      plain: BookMarkListType,
      attrs: Partial<BookMarkListType>
    ) => {
      const newList = await updateAVinList(
        "bookmarks",
        plain,
        attrs,
        this.state.list
      )
      this.setState({ list: newList })
      return this.state.list.find(v => v.objectId === plain.objectId)
    },
    createBookMark: async (
      attrs: Partial<BookMarkListType>
    ): Promise<BookMarkListType> => {
      const newList = await createAVinList("bookmarks", attrs, this.state.list)
      this.setState({ list: newList })
      return this.state.list[0]
    },
  }
}
