import React, { useEffect } from "react"
import { css } from "@emotion/core"

import { makeStyles, Theme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import IconButton from "@material-ui/core/IconButton"
import PersonIcon from "@material-ui/icons/Person"
import SettingsIcon from "@material-ui/icons/Settings"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import AV from "leancloud-storage"

import Logo from "./logo"
import { NavMenus } from "src/pages/workboard"
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { useNovus } from "src/lib/novus"

const drawerWidth = 200

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#f5f5f5",
    padding: theme.spacing(0, 1),
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
}))

type DrawersProps = {
  menus: NavMenus
  activeId: string
  setKey: (id: string) => void
  quitAuth: () => void
  setActiveId: React.Dispatch<React.SetStateAction<string>>
}

function Drawers({ activeId, setKey, menus, setActiveId }: DrawersProps) {
  const classes = useStyles()

  let [currentUser, username, email, mobilePhoneNumber] = useNovus<
    [AV.User, string, string, string]
  >(
    models => {
      const currentUser = models.authModel.state.currentUser
      return [
        currentUser,
        currentUser ? currentUser.getUsername() : "",
        currentUser ? currentUser.getEmail() : "",
        currentUser ? currentUser.getMobilePhoneNumber() : "",
      ]
    },
    ["authModel"]
  )

  return (
    <Drawer
      variant="permanent"
      className={classes.drawerOpen}
      classes={{ paper: classes.drawerOpen }}
      css={css`
        flex-shrink: 0;
        white-space: nowrap;
        position: relative;
        height: 100%;
        overflow: auto;

        & > .MuiPaper-root {
          top: auto;
        }
        .MuiListItem-gutters {
          padding-left: 25px;
        }
        .MuiListItemIcon-root {
          min-width: 46px;
        }
        .MuiTypography-root {
        }
        .MuiDrawer-paperAnchorDockedLeft {
          border-right: 1px solid rgba(0, 0, 0, 0);
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 25px 0 40px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Logo size={50} />
          <div
            css={css`
              margin-left: 10px;
            `}
          >
            <h1
              css={css`
                margin: 5px 0;
              `}
            >
              个人工作助手
            </h1>
            <div
              css={css`
                svg {
                }
              `}
            >
              {username}
              <IconButton
                size="small"
                onClick={() => {
                  setActiveId("setting")
                }}
              >
                <SettingsIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <List>
        {menus.map(menu => (
          <ListChoice
            key={menu.id}
            id={menu.id}
            isActive={activeId == menu.id}
            title={menu.title}
            icon={menu.icon}
            setKey={setKey}
          />
        ))}
      </List>
    </Drawer>
  )
}

export default React.memo(Drawers)

type ListChoiceProps = {
  title: React.ReactNode
  icon: React.ReactNode
  id: string
  isActive: boolean
  setKey: (id: string) => void
}

const ListChoice = React.memo(
  ({ title, icon, id, isActive, setKey }: ListChoiceProps) => {
    return (
      <ListItem selected={isActive} button onClick={() => setKey(id)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    )
  }
)
