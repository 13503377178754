import React, { useRef, useState, useCallback } from "react"
import { css } from "@emotion/core"
import List from "@material-ui/core/List"
import Paper from "@material-ui/core/Paper"
import { TodoType, TodoCatType } from "src/lib/LCTypes"

import TodoItem from "./todo-item"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import { NewForm, FieldTypes, NewFormProps } from "src/components/new-form"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { todoModel } from "src/models"

const NewTodo = NewForm as React.ComponentType<NewFormProps<TodoType>>

type TodoCardProps = {
  checkeds: string[]
}

export default React.memo(({ checkeds }: TodoCardProps) => {
  let [todoCats, todoList] = useNovus<[TodoCatType[], TodoType[]]>(
    models => {
      return [models.todoModel.state.todoCats, models.todoModel.state.todoList]
    },
    ["todoModel"]
  )

  const sortedTodos = todoList.sort((a, b) => {
    return a.status == 0 && b.status == 1
      ? -1
      : a.status == 1 && b.status == 0
        ? 1
        : new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime()
          ? -1
          : 1
  })

  const message = useMessage()

  const currentDelRef = useRef<TodoType>()
  const [deleteVisible, setDeleteShow] = useState(false)
  const showDeleteDialog = useCallback(entry => {
    currentDelRef.current = entry
    setDeleteShow(true)
  }, [currentDelRef])
  const handleDel = useCallback(async () => {
    await todoModel.actions.deleteTodo(currentDelRef.current)
    currentDelRef.current = null
    message.success("删除成功")
  }, [currentDelRef])

  const [editVisible, setEditShow] = useState(false)
  const showEditingDialog = useCallback(entry => {
    currentDelRef.current = entry
    setEditShow(true)
  }, [currentDelRef])
  const handleEdit = useCallback(async json => {
    json.category = {
      __type: "Pointer",
      className: "todo_cat",
      objectId: json.category,
    }
    if (currentDelRef.current == null) {
      //
    } else {
      await todoModel.actions.updateTodo(currentDelRef.current, json)
      message.success("更新成功")
    }
  }, [currentDelRef])

  return (
    <Paper
      css={css`
        flex: 1;
        overflow: auto;
        min-height: 200px;
      `}
    >
      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentDelRef.current ? currentDelRef.current.name : ""}
        onConfirm={handleDel}
      />

      <NewTodo
        visible={editVisible}
        setVisible={setEditShow}
        onConfirm={handleEdit}
        title="编辑待办内容"
        fields={[
          {
            type: FieldTypes.TEXT,
            label: "名称",
            name: "name",
          },
          {
            type: FieldTypes.TEXT,
            label: "内容",
            name: "content",
          },
          {
            type: FieldTypes.SELECT,
            label: "分类",
            name: "category",
            options: todoCats.reduce<{ [ind: string]: string }>((ret, val) => {
              ret[val.objectId] = val.name
              return ret
            }, {}),
            defaultValue: currentDelRef.current
              ? currentDelRef.current.category.objectId
              : todoCats.length > 0
                ? todoCats[0].objectId
                : "",
          },
        ]}
        editing={currentDelRef.current}
        setEditing={(target: TodoType) => {
          currentDelRef.current = target
        }}
      />

      {todoList.length === 0 ? (
        <div
          css={css`
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #7078d4;
          `}
        >
          <span>没有项目，新建一个吧~</span>
        </div>
      ) : (
          <List
            css={css`
            background: #fff;
            padding: 0;
            li {
              margin: 0;
            }
            .MuiListItemIcon-root {
              min-width: 28px;
            }
          `}
          >
            {sortedTodos.map(todo => {
              const cat = todoCats.find(
                c => c.objectId === todo.category.objectId
              )
              return (
                <div
                  key={todo.objectId}
                  style={{
                    display:
                      checkeds.indexOf(todo.category.objectId) > -1
                        ? "block"
                        : "none",
                  }}
                >
                  <TodoItem
                    cat={cat}
                    todo={todo}
                    showEditingDialog={showEditingDialog}
                    showDeleteDialog={showDeleteDialog}
                  />
                </div>
              )
            })}
          </List>
        )}

      {todoList.length !== 0 &&
        todoList.filter(todo => todo.status === 1).length ===
        todoList.length && (
          <div
            css={css`
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #7078d4;
            `}
          >
            您已经全部完成了！
          </div>
        )}
    </Paper>
  )
})
