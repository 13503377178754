import React from "react"
import { css } from "@emotion/core"

import { ChannelCard } from "./channel-card"
import { RSSChannelType } from "src/lib/LCTypes"
import { useNovus } from "src/lib/novus"
import { rssModel } from "src/models"
import { TFilterType } from "src/models/rssModel"
import Empty from "src/components/empty"

type ChannelsProps = {
  updateChannelEditing: (channel: RSSChannelType) => void
  onDeleteChannel: (channel: RSSChannelType) => void
}

export const Channels = React.memo(
  ({ updateChannelEditing, onDeleteChannel }: ChannelsProps) => {
    let [filterType, channels] = useNovus<[TFilterType, RSSChannelType[]]>(
      models => {
        return [
          models.rssModel.state.filterType,
          models.rssModel.state.channels,
        ]
      },
      ["rssModel"]
    )
    if (channels.length === 0) {
      return <Empty title="没有订阅，右上角添加~" />
    }

    return (
      <div
        css={css`
          min-width: 0;
          flex: 1;
          text-overflow: ellipsis;
          margin: 0 5px;
          position: relative;
          padding: 2px 3px 8px;
        `}
      >
        <div
          css={css`
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 6px;
            border: 2px solid transparent;
          `}
        ></div>
        <div
          css={css`
            position: relative;
            z-index: 1;
          `}
        >
          {channels.map((entry, i) => {
            return (
              <ChannelCard
                key={entry.objectId}
                entry={entry}
                onChoose={() => {
                  rssModel.actions.updateFilter("channel", entry.objectId)
                }}
                onEdit={updateChannelEditing}
                onDeleteChannel={onDeleteChannel}
              />
            )
          })}
        </div>
      </div>
    )
  }
)
