// 腾讯云函数 https://console.cloud.tencent.com/scf/list-detail?rid=8&ns=default&id=parsePage
const URL_PARSE =
  "https://service-j3t2jea7-1252337231.bj.apigw.tencentcs.com/release/parsePage"

// 获取任意网页的 title 和 description
export const parsePage = async (url: string) => {
  return await fetch(`${URL_PARSE}?url=${encodeURIComponent(url)}`).then(r =>
    r.json()
  )
}

// 获取 RSS 协议地址的 title 和 description
const RSS_PARSE =
  "https://service-62r8xjk7-1252337231.bj.apigw.tencentcs.com/release/parseRss"

export const parseRss = async (
  url: string
): Promise<{ title: string; desc: string }> => {
  return await fetch(`${RSS_PARSE}?url=${encodeURIComponent(url)}`).then(r =>
    r.json()
  )
}

export const parseErshicimi = async (searchKey: string) => {
  const url =
    window.location.protocol +
    "//cors-anywhere.herokuapp.com/" +
    `https://www.ershicimi.com/search?q=${encodeURIComponent(searchKey)}`
  // https://www.ershicimi.com/search?q=%E9%98%BF%E9%87%8C
  const text = await fetch(url).then(r => r.text())
  console.log(text)
  return {
    title: "",
    desc: "",
  }
}
