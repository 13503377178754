import React, { useState, useCallback } from "react"
import { css } from "@emotion/core"

import IconButton from "@material-ui/core/IconButton"

import useMessage from "src/utils/message"
import { novus, useNovus } from "src/lib/novus"
import { noteModel } from "src/models"
import { faSave, faFont } from "@fortawesome/free-solid-svg-icons"
import ShareIcon from '@material-ui/icons/Share';
import { faMarkdown } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome"
import TrashIcon from "@material-ui/icons/Delete"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InputAdornment from "@material-ui/core/InputAdornment"

export const NoteToolbar = React.memo(() => {
  const message = useMessage()

  let [isTitleChanged, isContentChanged, currentNote, currentNoteId, mdMode, sharing] = useNovus(
    models => {
      return [
        models.noteModel.state.isTitleChanged,
        models.noteModel.state.isContentChanged,
        models.noteModel.state.currentNote,
        models.noteModel.state.currentNoteId,
        models.noteModel.state.mdMode,
        models.noteModel.state.sharing,
      ]
    },
    ["noteModel"]
  )

  // 处理 md 格式的粘贴和展示
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const [deleteVisible, setDeleteShow] = useState(false)
  const handleDel = useCallback(async () => {
    const disposer = message.success("内容删除中...")
    console.log(currentNoteId)
    await noteModel.actions.deleteNote({ objectId: currentNoteId })
    noteModel.actions.updateCurrentNodeId("")
    disposer()
    message.success("删除成功")
  }, [currentNoteId])

  const shareStatus = currentNote && currentNote.shared

  return (
    <>
      <div>
        {(isTitleChanged || isContentChanged) && (
          <IconButton title="保存" onClick={noteModel.actions.save}>
            <FAIcon icon={faSave} />
          </IconButton>
        )}
        <IconButton
          title="Share"
          onClick={() => setDialogOpen(true)}
        >
          <ShareIcon />
          {shareStatus &&
            <div css={css`position: absolute;
              width: 7px;
              height: 15px;
              border: 3px solid #4CAF50;
              bottom: 5px;
              left: 28px;
              transform: rotate(45deg);
              border-width: 0 2px 2px 0;
          `} />}
        </IconButton>
        <IconButton
          title="MD 编辑模式"
          onClick={noteModel.actions.switchMdMode}
        >
          <FAIcon icon={mdMode ? faFont : faMarkdown} />
        </IconButton>
        <IconButton
          title="删除"
          onClick={() => {
            setDeleteShow(true)
          }}
        >
          <TrashIcon color="secondary" />
        </IconButton>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">分享文档</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={shareStatus}
                onChange={(e, checked) => novus.models.noteModel.actions.shareCurrentNode(checked)}
                name="checkedB"
                color="primary"
                disabled={sharing}
              />
            }
            label="公开分享"
          />
          <DialogContentText>
            公开分享的是当前文档的只读版本，拿到链接的任何人都可以访问它，甚至被搜索引擎收录，请注意数据安全。
          </DialogContentText>
          {
            shareStatus && <div>
              <TextField
                autoFocus
                margin="dense"
                type="text"
                variant="outlined"
                value={`${window.location.origin}/share?${currentNoteId}`}
                fullWidth
              />
              <DialogContentText>
                <a
                  href={`${window.location.origin}/share?${currentNoteId}`}
                  target="_blank"
                  css={css`
    display: inline-flex;
    align-items: center;
    margin: 10px 0;
`}
                >
                  预览地址
                <OpenInNewIcon />
                </a>
              </DialogContentText>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            取消
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentNote ? currentNote.title : ""}
        onConfirm={handleDel}
      />
    </>
  )
})
