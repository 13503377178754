import React, { useState, useEffect, useCallback, useMemo } from "react"
import { css } from "@emotion/core"

import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import { NavMenus } from "src/pages/workboard"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

type DrawersProps = {
  hide: boolean
  menus: NavMenus
  activeId: string
  setKey: (id: string) => void
}

const outofBoxNum = 4

function Navs({ hide, activeId, setKey, menus }: DrawersProps) {
  const [value, setValue] = useState("")

  useEffect(() => {
    if (
      menus.length > outofBoxNum &&
      menus.findIndex(m => m.id === activeId) > outofBoxNum
    ) {
      setValue("__extra")
    } else {
      setValue(activeId)
    }
  }, [activeId, menus])

  const [drawerVisible, setDrawerVisible] = useState(false)
  const onChange = useCallback(
    (event, newValue) => {
      if (newValue === "__extra") {
        setDrawerVisible(true)
      } else {
        setKey(newValue)
      }
    },
    [activeId, menus]
  )
  const hideDrawer = useCallback(() => {
    setDrawerVisible(false)
  }, [])

  const outofBoxNavs = useMemo(() => {
    return menus
      .slice(0, outofBoxNum)
      .map(menu => (
        <BottomNavigationAction
          key={menu.id}
          label={menu.title}
          value={menu.id}
          icon={menu.icon}
        />
      ))
  }, [menus])

  const moreNav = useMemo(() => {
    return (
      menus.length > outofBoxNum && (
        <BottomNavigationAction
          key="__extra"
          label="更多"
          value="__extra"
          icon={<MoreHorizIcon />}
        />
      )
    )
  }, [menus])

  const lists = useMemo(() => {
    return menus.slice(outofBoxNum).map(menu => (
      <ListItem
        selected={activeId === menu.id}
        button
        key={menu.id}
        onClick={() => {
          setKey(menu.id)
          setDrawerVisible(false)
        }}
      >
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={menu.title} />
      </ListItem>
    ))
  }, [menus, activeId])

  const drawer = useMemo(() => {
    return (
      menus.length > outofBoxNum && (
        <React.Fragment>
          <Drawer
            anchor="bottom"
            open={drawerVisible}
            onClose={hideDrawer}
            css={css`
              & > .MuiDrawer-paper {
                // background-color: rgba(255, 255, 255, 0.75);
                backdrop-filter: saturate(180%) blur(20px);
              }
            `}
          >
            <List>{lists}</List>
          </Drawer>
        </React.Fragment>
      )
    )
  }, [lists, menus, drawerVisible])

  return (
    <div style={{ display: hide ? "none" : "block" }}>
      <BottomNavigation
        value={value}
        showLabels
        onChange={onChange}
        css={css`
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0);
          backdrop-filter: saturate(200%) blur(20px);
          .MuiBottomNavigationAction-root {
            padding: 6px 6px 8px;
          }
        `}
      >
        {outofBoxNavs}
        {moreNav}
      </BottomNavigation>
      {drawer}
    </div>
  )
}

export default React.memo(Navs)
