import React, { useRef, useState, useCallback, useEffect } from "react"
import { css } from "@emotion/core"
import timeAgoFormat, { timeStr } from "../utils/timeago"

import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import SyncIcon from "@material-ui/icons/Sync"
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft"

import MainContent from "../components/Main"
import { formData2json } from "src/utils/utils"
import { TimeMachineType } from "src/lib/LCTypes"
import { ConfirmDelete } from "src/components/ConfirmDelete"
import useMessage from "src/utils/message"
import { useNovus } from "src/lib/novus"
import { timeMachineModel } from "src/models"
import openPage from "src/utils/openPage"
import Loading from "src/components/loading"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export const TimeMachine = React.memo(() => {
  const classes = useStyles()
  const message = useMessage()

  let [records, loadedAll, loading] = useNovus(
    models => {
      return [
        models.timeMachineModel.state.records,
        models.timeMachineModel.state.loadedAll,
        models.timeMachineModel.state.loading,
      ]
    },
    ["timeMachineModel"]
  )

  useEffect(() => {
    timeMachineModel.actions.refresh()
  }, [])

  const currentDelRef = useRef<TimeMachineType>()
  const [deleteVisible, setDeleteShow] = useState(false)

  const checkNew = useCallback(async event => {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const json = formData2json(data)
    json.side = "request"
    json.type = "message"
    await timeMachineModel.actions.createRecord(json)
    form.reset()
    message.success("添加成功")
    setTimeout(() => {
      timeMachineModel.actions.refresh()
    }, 2000)
  }, [])

  const handleDel = useCallback(async () => {
    await timeMachineModel.actions.deleteRecord(currentDelRef.current)
    currentDelRef.current = null
    message.success("删除成功")
  }, [currentDelRef])

  return (
    <MainContent
      mainLoading={false}
      title="时光记"
      titleRights={[
        <IconButton
          key="refresh"
          onClick={timeMachineModel.actions.refresh}
          disabled={loading}
        >
          <SyncIcon />
        </IconButton>,
      ]}
      onRefresh={timeMachineModel.actions.refresh}
    >
      {loading && <Loading size={30} title="加载中..." />}
      <ConfirmDelete
        deleteVisible={deleteVisible}
        setDeleteShow={setDeleteShow}
        content={currentDelRef.current ? currentDelRef.current.content : ""}
        onConfirm={handleDel}
      />

      <div
        css={css`
          padding: 10px 10px 0;
          max-width: 520px;
          margin: 0 auto;
        `}
      >
        <Paper
          elevation={3}
          component="form"
          className={classes.root}
          onSubmit={checkNew}
          // @ts-ignore
          autoComplete="off"
        >
          <IconButton className={classes.iconButton}>
            <SwapVertIcon />
          </IconButton>
          <InputBase
            name="content"
            autoFocus
            className={classes.input}
            placeholder="记录你说的每一句话"
            inputProps={{}}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            type="submit"
            color="primary"
            className={classes.iconButton}
          >
            <SubdirectoryArrowLeftIcon />
          </IconButton>
        </Paper>
        <ul
          css={css`
            margin: 0;
            flex: 1;
            overflow: auto;
          `}
        >
          {records.map((entry, i) => (
            <li
              css={css`
                list-style: none;
                display: flex;
                margin: 20px 0;
                ${entry.side === "response"
                  ? `justify-content: flex-start;`
                  : `justify-content: flex-end;`}
              `}
              key={entry.objectId}
            >
              <div css={css``}>
                <div
                  css={css`
                    margin: 0 20px;
                    color: #9e9e9e;
                    display: flex;
                    ${entry.side === "response"
                      ? `flex-reverse: ;justify-content: flex-start;`
                      : `flex-direction: row-reverse;`}
                    :hover
                      span:last-child {
                      display: inline;
                    }
                  `}
                >
                  <span title={timeStr(new Date(entry.createdAt))}>
                    {timeAgoFormat(new Date(entry.createdAt))}
                  </span>
                  <span
                    onClick={() => {
                      currentDelRef.current = entry
                      setDeleteShow(true)
                    }}
                    css={css`
                      cursor: pointer;
                      margin: 0 5px;
                      color: #a10;
                      display: none;
                    `}
                  >
                    删除
                  </span>
                </div>
                <div
                  css={css`
                    min-width: 0;
                    flex: 1;
                    text-overflow: ellipsis;
                    margin-right: 15px;
                  `}
                >
                  <div
                    css={css`
                      background: #ddd;
                      padding: 10px 15px;
                      border-radius: 24px;
                      margin: 0 10px;
                      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                      position: relative;
                      word-break: break-all;
                      a {
                        color: #fff;
                      }

                      ${entry.side === "response"
                        ? `color: #333;
                        border-top-left-radius: 0;
                        background: #fff;
                        justify-content: flex-start;`
                        : `color: #fff;
                        border-top-right-radius: 0;
                        justify-content: flex-end;
                        background: linear-gradient(to left top,#5c32db,#6a85f1);
                        `}
                      ${entry.type === "message"
                        ? `max-width: 500px;
                        min-width: 100px;`
                        : ``}
                    `}
                  >
                    {/https?:\/\//.test(entry.content) ? (
                      <a
                        href={entry.content}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={e => {
                          openPage(entry.content, e)
                        }}
                      >
                        {entry.content}
                      </a>
                    ) : (
                        entry.content
                      )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {loadedAll && (
        <div
          css={css`
            text-align: center;
            padding: 20px 0;
          `}
        >
          全部加载成功
        </div>
      )}
    </MainContent>
  )
})
