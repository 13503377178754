export default function openPage(
  url: string,
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  if (window.UFLOW_APP_JS_CHANNEL && window.UFLOW_APP_JS_CHANNEL.openPop) {
    e.preventDefault()
    window.UFLOW_APP_JS_CHANNEL.openPop(url)
    return
  }
}
